<div class="container m-container">
	<div fxLayout="column">
		<div class="row">
			<div class="col-md-12">
				<div class="main-text hidden-xs bg-instructor text-light xs-imagen rounded" style="padding: 50px;">
					<div class="col-md-12 text-center">
						<div fxLayout="row" fxLayout.xs="column">
							<div class="col col-md-4 text-center" fxLayoutAlign="end start" fxLayoutAlign.xs="center space-around">
								<div style="display: flex; justify-content: center; align-items: center; overflow: hidden; border-radius: 50%;">
									<img (error)="onImgErrorProfile($event)" [src]="infoInstructor?.image_url" class="rounded" style="min-width: 100%;">
								</div>
							</div>
							<div class="col col-md-8 text-center">
								<h1 class="xs-textTitle">{{infoInstructor?.name}} {{infoInstructor?.last_name}}</h1>
								<p class="text-light" style="color: #000 !important;">Correo: {{infoInstructor?.email}}</p>
								<p class="alignText">{{infoInstructor?.description}}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<hr/>
		<div *ngIf="certifications.length > 0" class="container-fluid" [ngStyle.xs]="'text-align:center'">
			<h4 [ngStyle.xs]="'text-align:center title-course'" style="font-weight: bold;">Certificaciones</h4>
		</div>
		<div fxLayout="row wrap" fxLayoutAlign="start start" fxFlex>
			<div fxFlex="23" fxFlex.xs="100" style="height: 180px;" class="m-2 mat-elevation-z2 bg-instructor text-light rounded"
				*ngFor="let certification of certifications; let i = index;"
				[@animate]="{value:'*',params:{delay: (i*100)+'ms', y:'50px'}}">
				<div fxLayout="column" fxFlex="100" fxLayoutAlign="center center">
					<img style="margin-bottom: 25px;margin-top: 35px;" class="featured-img" (error)="onImgError($event)" height="50px" src="assets/images/certifications/{{certification.image_id}}.png" alt="">
					<div class="container">
						<p style="line-height: 1.5em; height: 3em; overflow: hidden; text-align: center;">{{certification?.certification}}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>