import { NgModule, ErrorHandler } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { GestureConfig } from '@angular/material/core';
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';


import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDataService } from './shared/inmemory-db/inmemory-db.service';

import { rootRouterConfig } from './app.routing';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ErrorHandlerService } from './shared/services/error-handler.service';
import { SigninComponent } from './views/sessions/signin/signin.component';

//Angular Material Modules
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTreeModule} from '@angular/material/tree';
import {OverlayModule} from '@angular/cdk/overlay';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDividerModule} from '@angular/material/divider';
import {MatNativeDateModule, MatRippleModule, MAT_DATE_LOCALE} from '@angular/material/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './views/sessions/forgot-password/forgot-password.component';

import { DatePipe } from '@angular/common';
import { FileUploadModule } from 'ng2-file-upload';
import {NgxPaginationModule} from 'ngx-pagination';
import { ColorPickerModule } from 'ngx-color-picker';

import { MaterialFileInputModule } from 'ngx-material-file-input';
import { NavigationBarComponent } from './views/dashboard/navigation-bar/navigation-bar.component';
import { CalendarComponent } from './views/dashboard/calendar/calendar.component';
import { CoursesComponent } from './views/dashboard/courses/courses.component';
import { InstructorComponent } from './views/dashboard/instructor/instructor.component';
import { BillingComponent } from './views/dashboard/billing/billing.component';
import { ProfileComponent } from './views/dashboard/users/profile/profile.component';
import { DialogConfirmComponent } from './views/dialogs/dialog-confirm/dialog-confirm.component';
import { CalendarFormDialogComponent } from './views/dialogs/calendar-form-dialog/calendar-form-dialog.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { WallComponent } from './views/dashboard/users/wall/wall.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { CourseDetailsComponent } from './views/dashboard/courses/course-details/course-details.component';
import { AdminCoursesComponent } from './views/dashboard/admin-courses/admin-courses.component';
import { ResetPasswordComponent } from './views/sessions/reset-password/reset-password.component';
import { DialogCourseComponent } from './shared/components/dialog-course/dialog-course.component';
import { DialogComponent } from './shared/components/dialog/dialog.component';

import { SignupComponent } from './views/sessions/signup/signup.component';
import { AdminModuleComponent } from './views/dashboard/admin-module/admin-module.component';
import { DialogModuleComponent } from './shared/components/dialog-module/dialog-module.component';
import { AdminActivityComponent } from './views/dashboard/admin-activity/admin-activity.component';
import { DialogActivityComponent } from './shared/components/dialog-activity/dialog-activity.component';
import { InstructorsComponent } from './views/dashboard/instructors/instructors.component';
import { AdminTopicsComponent } from './views/dashboard/admin-topics/admin-topics.component';
import { DialogTopicComponent } from './shared/components/dialog-topic/dialog-topic.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DialogCourseDetailsComponent } from './views/dialogs/dialog-course-details/dialog-course-details.component';
import { HomeComponent } from './views/dashboard/home/home.component';
import { AdminTeamComponent } from './views/dashboard/admin-team/admin-team.component';
import { BuyCourseComponent } from './views/dashboard/courses/buy-course/buy-course.component';
// { StudyPlanComponent } from './views/dashboard/courses/study-plan/study-plan.component';
import { MatDatetimepickerModule, MatNativeDatetimeModule } from "@mat-datetimepicker/core";
import { MatMomentDatetimeModule } from "@mat-datetimepicker/moment";
import { StudyPlanComponent } from './views/dashboard/courses/study-plan/study-plan.component';
import { ComunicationService } from './comunication.service';
import { CertificationDialogComponent } from './views/dialogs/certification-dialog/certification-dialog.component';
import { AdminPromotionsComponent } from './views/dashboard/admin-promotions/admin-promotions.component';
import { DialogPromotionComponent } from './shared/components/dialog-promotion/dialog-promotion.component';
import { ReportsComponent } from './views/dashboard/reports/reports.component';
import { DialogPromotionUserComponent } from './shared/components/dialog-promotion-user/dialog-promotion-user.component';
import { AdminCoursePupilComponent } from './views/dashboard/admin-course-pupil/admin-course-pupil.component';
import { ChatCourseComponent } from './views/dashboard/chat-course/chat-course.component';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { GameComponent } from './views/dashboard/game/game.component';
import { TestReglasComponent } from './views/test-reglas/test-reglas.component';
import { GameInstructorComponent } from './views/dashboard/game-instructor/game-instructor.component';
import { InitGameDialogComponent } from './views/dialogs/init-game-dialog/init-game-dialog.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DialogSessionempComponent } from './views/dialogs/dialog-sessionemp/dialog-sessionemp.component';
import { DialogCalendarinfoComponent } from './views/dialogs/dialog-calendarinfo/dialog-calendarinfo.component';
import { SamRouletteComponent } from './views/dialogs/sam-roulette/sam-roulette.component';
import { RouletteOfLifeComponent } from './views/dialogs/roulette-of-life/roulette-of-life.component';
import { FinishDialogComponent } from './shared/components/finish-dialog/finish-dialog.component';
import { DialogOrderTopicsComponent } from './views/dialogs/dialog-order-topics/dialog-order-topics.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { DialogOrderTopicResourcesComponent } from './views/dialogs/dialog-order-topic-resources/dialog-order-topic-resources.component';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { DialogStoreComponent } from './views/dialogs/dialog-store/dialog-store.component';
import { SimulatorModule2Component } from './views/dashboard/simulator-module2/simulator-module2.component';
import { SimulatorInstructorModule2Component } from './views/dashboard/simulator-instructor-module2/simulator-instructor-module2.component';
import { OfferDemandMoulo2Component } from './views/dialogs/offer-demand-moulo2/offer-demand-moulo2.component';
import { MarketAccessModule3Component } from './views/dialogs/market-access-module3/market-access-module3.component';
import { SimulatorInstructorModule3Component } from './views/dashboard/simulator-instructor-module3/simulator-instructor-module3.component';
import { SimulatorModule3Component } from './views/dashboard/simulator-module3/simulator-module3.component';
import { ContactUsComponent } from './views/dashboard/contact-us/contact-us.component';
import { AboutComponent } from './views/dashboard/about/about.component';
import { SimulatorModule4Component } from './views/dashboard/simulator-module4/simulator-module4.component';
import { SimulatorInstructorModule4Component } from './views/dashboard/simulator-instructor-module4/simulator-instructor-module4.component';
import { AccountingExcelComponent } from './views/dialogs/accounting-excel/accounting-excel.component';
import { TermsComponent } from './views/legal/terms/terms.component';
import { PrivacyComponent } from './views/legal/privacy/privacy.component';
import { DialogBuyCourseComponent } from './views/dialogs/dialog-buy-course/dialog-buy-course.component';
import { DialogTermsComponent } from './views/dialogs/dialog-terms/dialog-terms.component';
import { DialogPrivacyComponent } from './views/dialogs/dialog-privacy/dialog-privacy.component';
import { PaymentMethodsComponent } from './views/dashboard/admin-courses/payment-methods/payment-methods.component';
import { DialogAssignPaymentMethodComponent } from './views/dialogs/dialog-assign-payment-method/dialog-assign-payment-method.component';
import { DialogManageStripePricesComponent } from './views/dialogs/dialog-manage-stripe-prices/dialog-manage-stripe-prices.component';
import { CancelComponent } from './views/payments/cancel/cancel.component';
import { ManageStripePricesComponent } from './views/dashboard/admin-courses/manage-stripe-prices/manage-stripe-prices.component';
import { DialogShowVideoComponent } from './views/dialogs/dialog-show-video/dialog-show-video.component';
import { AdminUsersComponent } from './views/dashboard/admin-users/admin-users.component';
import { DialogMassUploadComponent } from './views/dialogs/dialog-mass-upload/dialog-mass-upload.component';
import { DialogResetPasswordComponent } from './views/dialogs/dialog-reset-password/dialog-reset-password.component';
import { DialogEditUserComponent } from './views/dialogs/dialog-edit-user/dialog-edit-user.component';
import { AdminAssignCoursesComponent } from './views/dashboard/admin-users/admin-assign-courses/admin-assign-courses.component';
import { DiscountCodesComponent } from './views/dashboard/discount-codes/discount-codes.component';
import { SalesCodesComponent } from './views/dashboard/sales-codes/sales-codes.component';
import { DialogCodeInscriptionComponent } from './views/dialogs/dialog-code-inscription/dialog-code-inscription.component';
import { DialogRegistrationCodeGroupsComponent } from './views/dialogs/dialog-registration-code-groups/dialog-registration-code-groups.component';
import { FacilitatorRegistrationCodesComponent } from './views/dashboard/facilitator-registration-codes/facilitator-registration-codes.component';
import { DialogConfirmRegistrationCodeComponent } from './views/dialogs/dialog-confirm-registration-code/dialog-confirm-registration-code.component';
import { NotFoundComponent } from './views/errors/not-found/not-found.component';
import { DialogCouponComponent } from './views/dialogs/dialog-coupon/dialog-coupon.component';
import { DialogPromotionCodesComponent } from './views/dialogs/dialog-promotion-codes/dialog-promotion-codes.component';
import { BuyComponent } from './views/payments/subscription/buy/buy.component';
import { DetailsComponent } from './views/dashboard/subscription/details/details.component';
import { AdminParticipantsComponent } from './views/dashboard/admin-users/admin-participants/admin-participants.component';
import { SuccessComponent } from './views/payments/success/success.component';
import { BlogComponent } from './views/dashboard/blog/blog.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient,'./assets/i18n/', '.json');
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    FlexLayoutModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    MaterialFileInputModule,
    FormsModule,
    PerfectScrollbarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatCarouselModule.forRoot(),
    InMemoryWebApiModule.forRoot(InMemoryDataService, { passThruUnknownUrl: true}),
    RouterModule.forRoot(rootRouterConfig, { 
      // Restore the last scroll position
      scrollPositionRestoration: "top",
      scrollOffset: [1, 1],
      // Enable scrolling to anchors
      anchorScrolling: "enabled",
    }),
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    BrowserAnimationsModule,
    MatTreeModule,
    OverlayModule,
    ScrollingModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDividerModule,
    MatNativeDateModule,
    MatRippleModule,
    ReactiveFormsModule,
    SharedModule,
    FileUploadModule,
    NgxPaginationModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    ColorPickerModule,
    NgxEchartsModule,
    InfiniteScrollModule,
    MatNativeDatetimeModule,
    MatMomentDatetimeModule,
    MatDatetimepickerModule,
    SocialLoginModule,
    NgbModule,DragDropModule
  ],
  declarations: [
    AppComponent, 
    SigninComponent, 
    SignupComponent,
    ForgotPasswordComponent,
    DashboardComponent, 
    DialogCourseComponent,
    DialogComponent,
    DialogModuleComponent,
    AdminModuleComponent,
    DialogActivityComponent,
    ForgotPasswordComponent, NavigationBarComponent, CalendarComponent, CoursesComponent, 
    InstructorComponent, BillingComponent, ProfileComponent, DialogConfirmComponent, CalendarFormDialogComponent, 
    WallComponent, CourseDetailsComponent, AdminCoursesComponent,ResetPasswordComponent, AdminActivityComponent, 
    InstructorsComponent, AdminTopicsComponent,DialogTopicComponent, DialogCourseDetailsComponent, AdminTeamComponent,HomeComponent,
    BuyCourseComponent,
    StudyPlanComponent,
    CertificationDialogComponent,
    AdminPromotionsComponent,
    DialogPromotionComponent,
    ReportsComponent,
    DialogPromotionUserComponent,
    AdminCoursePupilComponent,
    ChatCourseComponent,
    GameComponent,
    TestReglasComponent,
    GameInstructorComponent,
    InitGameDialogComponent,
    DialogSessionempComponent,
    DialogCalendarinfoComponent,
    SamRouletteComponent,
    RouletteOfLifeComponent,
    FinishDialogComponent,
    DialogOrderTopicsComponent,
    DialogOrderTopicResourcesComponent,
    DialogStoreComponent,
    SimulatorModule2Component,
    SimulatorInstructorModule2Component,
    OfferDemandMoulo2Component,
    MarketAccessModule3Component,
    SimulatorInstructorModule3Component,
    SimulatorModule3Component,
    ContactUsComponent,
    AboutComponent,
    SimulatorModule4Component,
    SimulatorInstructorModule4Component,
    AccountingExcelComponent,
    TermsComponent,
    PrivacyComponent,
    DialogBuyCourseComponent,
    DialogTermsComponent,
    DialogPrivacyComponent,
    PaymentMethodsComponent,
    DialogAssignPaymentMethodComponent,
    DialogManageStripePricesComponent,
    CancelComponent,
    ManageStripePricesComponent,
    DialogShowVideoComponent,
    AdminUsersComponent,
    DialogMassUploadComponent,
    DialogResetPasswordComponent,
    DialogEditUserComponent,
    AdminAssignCoursesComponent,
    DiscountCodesComponent,
    SalesCodesComponent,
    DialogCodeInscriptionComponent,
    DialogRegistrationCodeGroupsComponent,
    FacilitatorRegistrationCodesComponent,
    DialogConfirmRegistrationCodeComponent,
    NotFoundComponent,
    DialogCouponComponent,
    DialogPromotionCodesComponent,
    BuyComponent,
    DetailsComponent,
    AdminParticipantsComponent,
    SuccessComponent,
    BlogComponent
  ],
  providers: [
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    // tslint:disable-next-line: deprecation
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    {provide: MAT_DATE_LOCALE, useValue: 'es-MX'},
    DatePipe,
    ComunicationService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('668714183431-90m7s6kfc89unns45jrraaq0umm51137.apps.googleusercontent.com')
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('960206914519344')
          }
        ]
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
