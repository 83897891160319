<div fxLayout="row" fxLayoutAlign="space-between center" >
    <p mat-dialog-title class="text-purple text-bold" style="margin: 0px;">Configurar sesión</p>
    <button mat-icon-button aria-label="close icon" [mat-dialog-close]="true"  >
        <mat-icon>close</mat-icon>
    </button>
</div>

<div mat-dialog-content fxLayout="column">
    <mat-form-field appearance="outline" fxFlex>
        <mat-label>Seleccione un curso:</mat-label>
        <mat-select fxFlex matInput [(ngModel)]="course" (selectionChange)="getModules()">
            <mat-option *ngFor="let course of courses" [value]="course">{{course?.course}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="this.role.isInstructor()" appearance="outline" fxFlex>
        <mat-label>Seleccione modulo:</mat-label>
        <mat-select fxFlex matInput [(ngModel)]="module">
            <mat-option *ngFor="let module of modules" [value]="module">{{module?.topic}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Codigo de acceso:</mat-label>
        <input matInput [(ngModel)]="codigo" (keyup)="validateText($event)" placeholder="Codigo de sesión">
    </mat-form-field>
    <label class="color-red">{{message}}</label>
</div>
<div mat-dialog-actions fxLayoutAlign="center center">
    <button class="btn-purple" mat-button (click)="cerrarDialogo()">
        <mat-label class="m-1">Cancelar</mat-label>
        <mat-icon aria-hidden="false">cancel_outline</mat-icon>
    </button>
    <button class="btn-green" mat-button [disabled]="disabledButtonConnect" (click)="validarCodigo()">
        <mat-label class="m-1">Conectarse</mat-label>
        <mat-icon class="m-1">check_circle_outline</mat-icon>
    </button>
</div>