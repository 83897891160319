import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { BaseService, ICreateCodeInscripcion, IResponse } from 'app/views/services/base.service';

@Component({
  selector: 'app-dialog-registration-code-groups',
  templateUrl: './dialog-registration-code-groups.component.html',
  styleUrls: ['./dialog-registration-code-groups.component.css']
})
export class DialogRegistrationCodeGroupsComponent implements OnInit {
  baseService:BaseService;
  displayedColumns: string[] = ["id","groupName","codeSession","facilitatorName","course","module",/* "createdAt", */"actions"];
  dataSource = new MatTableDataSource<any>();
  totalRows:number = 0;
  pageSize:number = 10;
  @ViewChild('paginator') paginator: MatPaginator;
  paginatorIndex:number = 0;

  constructor(
    injector:Injector,
    public dialog: MatDialogRef<DialogRegistrationCodeGroupsComponent>,
    @Inject(MAT_DIALOG_DATA) public data:ICreateCodeInscripcion
  ) {
    this.baseService = new BaseService(injector);
  }

  ngOnInit(): void {
    this.getGroups();
  }

  getGroups(page:number=0,pageSize:number=10): void {
    this.baseService.loader.open();
    let data = {
      page:page,
      pageSize:pageSize,
      facilitatorId:this.data.facilitator?.id,
      courseId:this.data.course?.id,
    };
    this.dataSource.data = [];
    this.totalRows = 0;
    this.baseService.service.getRegistrationCodes(data).then(
      (response) => {
        this.baseService.loader.close();
        let body:IResponse = response.body;
        if (!body.success) {
          this.baseService.service.openDialog(body.message);
          return;
        }
        this.dataSource.data = body.data.data;
        this.totalRows = body.data.totalRows;
        this.paginatorIndex = page;
        this.dataSource.paginator = this.paginator;
      }
    ).catch(
      (error:HttpErrorResponse) => {
        this.baseService.loader.close();
        this.baseService.service.processHttpResponse(error);
      }
    );
  }

  onChangePaginator(event:any){
    this.getGroups(event.pageIndex,event.pageSize);
  }

  editCode(code:any){
    this.baseService.openConfirm(`¿Seguro(a) que desea editar este código de sesión '${code.codeSession}'?`).subscribe(
      (response)=>{
        if(response){
          let data:ICreateCodeInscripcion = {
            course: this.data.course,
            facilitator: this.data.facilitator,
            isEdit: true,
            dataCodeSession: code
          }
          this.baseService.openNewCodeInscription(data).then((response)=>{}).catch(()=>{});
          this.closeDialog();
        }
      }
    )
  }

  closeDialog(): void {
    this.dialog.close(false);
  }

  createCodeInscription(){
    this.baseService.openNewCodeInscription(this.data).then((response)=>{}).catch(()=>{});
    this.closeDialog();
  }

}
