import { HttpErrorResponse } from '@angular/common/http';
import { Component, Injector, OnInit } from '@angular/core';
import { BaseService, IResponse } from 'app/views/services/base.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent extends BaseService implements OnInit {
  subscription:any;
  userInfo:any;
  constructor(injector:Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.userInfo = this.storage.getUserInfo();
    this.loader.open();
    let data = {
      userId:this.userInfo.id
    };
    this.service.getSubscription(data).then(
      (response)=>{
        this.loader.close();
        let body:IResponse = response.body;
        if(body.success){
          this.subscription = body.data;
        }else{
          this.openDialog(body.message).subscribe(()=>{
            this.navigateTo(this.COURSES_AUTH_ROUTE);
          });
        }
      }
    )
    .catch(
      (err:HttpErrorResponse) => {
        this.loader.close();
        this.service.processHttpResponse(err);
      }
    );
  }

  canceledSubscription(){
    this.openConfirm(`¿Desea cancelar la suscripción? Perderá acceso a los cursos premium.`).subscribe(
      (result)=>{
        if(result){
          this.loader.open('Cancelando suscripción..');
          let data = {
            userId:this.userInfo.id
          };
          this.service.canceledSubscription(data)
          .then(
            (response)=>{
              this.loader.close();
              let body:IResponse = response.body;
              this.openDialog(body.message);
              if (body.success) {
                this.ngOnInit();
                return;
              }
            },
            (err:HttpErrorResponse)=>{
              this.loader.close();
              this.service.processHttpResponse(err);
            }
          )
        }
      }
    );
  }
}
