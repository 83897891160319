import { Component, OnInit, ViewChild, TemplateRef, LOCALE_ID, Injector } from '@angular/core';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent } from 'angular-calendar';
import { Subject } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  isSameDay,
  isSameMonth
} from 'date-fns';
import { EgretCalendarEvent } from 'app/shared/models/event.model';
import { AppConfirmService } from 'app/shared/services/app-confirm/app-confirm.service';
import { CalendarService } from 'app/views/services/calendar.service';
import { CalendarFormDialogComponent } from 'app/views/dialogs/calendar-form-dialog/calendar-form-dialog.component';
import { egretAnimations } from 'app/shared/animations/egret-animations';
import localeEsMx from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common'
import { BaseService, IResponse } from 'app/views/services/base.service';
registerLocaleData(localeEsMx, 'es-Mx');

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],
  animations: [egretAnimations],
  providers: [ { provide: LOCALE_ID, useValue: 'es-Mx' } ],
})
export class CalendarComponent extends BaseService implements OnInit {
  public acquiredCourses:Array<any>  = [];
  public course_id = 0;
  public view = 'month';
  public viewDate = new Date();
  private dialogRef: MatDialogRef<CalendarFormDialogComponent>;
  public activeDayIsOpen: boolean = true;
  public refresh: Subject<any> = new Subject();
  public events: EgretCalendarEvent[];
  private actions: CalendarEventAction[];

  constructor(
    injector:Injector,
    private calendarService: CalendarService,
    private confirmService: AppConfirmService,
    public datepipe: DatePipe
  ) {
    super(injector);
    //Si es instructor habilitar las acciones en cada actividad
    this.actions = [{
      label: '<i class="material-icons icon-sm">edit</i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('edit', event as EgretCalendarEvent);
      }
    }, {
      label: '<i class="material-icons icon-sm">close</i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.removeEvent(event);
      }
    }];
  }

  ngOnInit() {
    //this.loadEvents();
    this.initCalendar();
  }

  initCalendar(){
    this.activatedRoute.paramMap.subscribe(params => {
      const courseId = Number(params.get('id'));
      if (courseId) {
        this.course_id = courseId;
        this.getActivitiesByCourse(courseId);
      } else {
        this.getCourses();
      }
    });
  }

  getCourses(){
    this.loader.open('Obteniendo cursos');
    let user = this.storage.getUserInfo();
    let params = {
      userId:user.id,
      type:0,
      page:0,
      pageSize:10
    };
    this.service.getCoursesByUser(params).subscribe(
      (data) => {
        this.loader.close();
        if (data.status == 200){
          const acquiredCourses = data.body.data.acquiredCourses.data;
          this.acquiredCourses = acquiredCourses ? acquiredCourses : [];
          this.getAllActivities();
        }
      }, 
      (http: HttpErrorResponse) => {
        this.loader.close();
      }
    );
  }

  getAllActivities(){
    //Validamos si el usuario ha realizado compra de cursos
    if (this.acquiredCourses.length>0){
      let courses:any[] = [];
      //Obtenemos los cursos comprados
      this.acquiredCourses.forEach(course => {
        courses.push(course.id);
      });
      if (courses instanceof Array && courses.length > 0){
        //Obtenemos la info de las actividades de acuerdo a los cursos comprados
        this.getCourseActivities(courses);
      } else {
        this.service.openDialog('No has adquirido cursos para ver actividades', 'Mensaje del sistema:').subscribe(() => {this.service.navigateTo('/dashboard/courses')});
        //this.service.navigateTo('/dashboard/home');
      }
    }
  }

  /* //EVENT INTERFACE
    id?: string | number;
    start: Date;
    end?: Date;
    title: string;
    color?: EventColor;
    actions?: EventAction[];
    allDay?: boolean;
    cssClass?: string;
    resizable?: {
        beforeStart?: boolean;
        afterEnd?: boolean;
    };
    draggable?: boolean;
    meta?: MetaType;
  */
  getCourseActivities(courses:Array<any>){
    //Recibe los ids de los cursos
    const coursesIds:string = courses.toString();
    const user:any = this.service.getUserInfo();
    let data = {
      userId: user.id,
      coursesIds: coursesIds
    };
    this.loader.open('Cargando actividades');
    const loader = this.loader.getDialog();
    this.service.getAllActivities(data).subscribe(
      (data) => {
        loader.close();
        //console.log(data);
        if (data.status == 200){
          this.initActivities(data.body.content);
        }
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        //console.log(http);
        switch (http.status) {
          case 401:
            this.service.openDialog('Operación no permitida');
            this.service.clearUserInfo();
            this.service.navigateTo('/signin');
            break;
          case 500:
            this.service.openDialog(http.error.content);
            break;
          default:
            this.service.openDialog('Error con el servicio.');
            break;
        }
      }
    );
  }

  ngAfterViewInit(){
    let tutorialIsDisplayed = localStorage.getItem('tutorialCalendarIsDisplayed');
    if(this.role.isClient() && tutorialIsDisplayed!='1'){
      localStorage.setItem('tutorialCalendarIsDisplayed','1');
      this.service.showTutorialCalendarClient();
    }
  }

  getActivitiesByCourse(courseId:number){
    //Recibe el id del curso
    const user:any = this.service.getUserInfo();
    let data = {
      userId: user.id,
      courseId: courseId
    };
    this.loader.open('Cargando actividades');
    const loader = this.loader.getDialog();
    this.service.getActivitiesByCourse(data).subscribe(
      (data) => {
        loader.close();
        //console.log(data);
        if (data.status == 200){
          this.initActivities(data.body.content);
        }
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        //console.log(http);
        switch (http.status) {
          case 401:
            this.service.openDialog('Operación no permitida');
            this.service.clearUserInfo();
            this.service.navigateTo('/signin');
            break;
          case 500:
            this.service.openDialog(http.error.content);
            break;
          default:
            this.service.openDialog('Error con el servicio.');
            break;
        }
      }
    );
  }

  private initActivities(data:any){
    let topics = data.topics;
    //Obtenemos las actividades y las inicializamos como 'evento'
    const events:EgretCalendarEvent[] = [];
    let content:any[] = data.activities;
    //console.log(content);
    if (!content || content.length <= 0) {
      this.service.openDialog('No hay actividades para mostrar');
    } else {
      //Obtenemos las actividades y las inicializamos como 'evento'
      content.map((c:any)=>{
        let event:any = {};
        //
        let start:string = c.activityDate;
        event.color = this.calendarService.getColorEvents().blue;
        //
        event.id = c.id;
        let activityDate:string = start;

        let newDateCal = this.calcTime(new Date(activityDate), c.timeZone/60);
        event.start = new Date(newDateCal.trim().replace(/ /g,"T"));

        let activityDateTime:string = this.datepipe.transform(newDateCal, 'd/MMM/y h:mm a');
        event.title = `Curso: ${c.courseName} | Actividad: ${c.activity} | ${activityDateTime}`;
        //
        let topic = topics.find(t => t.topic_id === c.topicRequiredId);
        //if (topic && topic.completed == true) {
          event.socialLink = c.socialLink;
          event.title += ` | Unirme a la reunión`;
        //}
        event.color = {
          primary: c.color
        };
        //Si el rol es instructor agregamos las acciones al calendario
        if (this.role.isInstructor()) {
          event.actions = this.actions;
        }
        if(this.role.isClient() && c.typeActivity == 2){
          event.title = `Simulador: ${c.activity}, ${c.registeredText}`;
          if(c.registeredUser==0){
            let actionStudent: CalendarEventAction[] = [{
              label: '<i class="fa fa-calendar-plus" aria-hidden="true"></i>',
              onClick: ()=> this.subscribeSimulator(c.id),
            }];
            event.actions = actionStudent;
            event.onClick = () => this.subscribeSimulator(c.id);
            event.socialLink = "";
          }else if(c.registeredUser==1){
            event.socialLink = c.socialLink;
          }
        }
        //Agregamos los eventos al calendario
        if (c?.studentCourseActive && c.studentCourseActive == 1){
          events.push(new EgretCalendarEvent(event));
        }
      });
      this.events = events;
      //console.log(this.events);
    }
  }

  subscribeSimulator(gameSessionId){ 
    this.service.openConfirm("¿Seguro(a) que desea inscribirse al simulador?").then(
      (response)=>{
        if(response){
          this.loader.open();
          let user = this.storage.getUserInfo();
          let data = {
            gameSessionId: gameSessionId,
            userId: user.id
          };
          this.service.registerUserToSimulator(data)
          .then(
            (response) => {
              this.loader.close();
              let body:IResponse = response.body;
              this.service.openDialog(body.message).subscribe(()=>{
                this.activatedRoute.paramMap.subscribe(params => {
                  this.initCalendar();
                });
              });
            }
          )
          .catch(
            (error:HttpErrorResponse) => {
              this.loader.close();
              this.service.processHttpResponse(error);
            }
          )
        }
      }
    );
  }

  private initEvents(events): EgretCalendarEvent[] {
    return events.map(event => {
      event.actions = this.actions;
      return new EgretCalendarEvent(event);
    });
  }

  public loadEvents() {
    this.calendarService
      .getEvents()
      .subscribe((events: CalendarEvent[]) => {
        this.events = this.initEvents(events);
      });
  }

  public removeEvent(event) {
    this.confirmService
      .confirm({
        title: 'Eliminar evento'
      })
      .subscribe(res => {
        if (!res) {
          return;
        }

        this.calendarService
          .deleteEvent(event._id)
          .subscribe(events => {
            this.events = this.initEvents(events);
            this.refresh.next();
          })
      })
  }

  public addEvent() {
    this.dialogRef = this.dialog.open(CalendarFormDialogComponent, {
      panelClass: 'calendar-form-dialog',
      data: {
        action: 'add',
        date: new Date()
      },
      width: '450px'
    });
    this.dialogRef.afterClosed()
      .subscribe((res) => {
        if (!res) {
          return;
        }
        let dialogAction = res.action;
        let responseEvent = res.event;
        this.calendarService
          .addEvent(responseEvent)
          .subscribe(events => {
            this.events = this.initEvents(events);
            this.refresh.next(true);
          });
      });
  }

  public handleEvent(action: string, event: EgretCalendarEvent): void {
    //console.log(event)
    switch (this.role.getRoleBySession()){
      case 1://Client
        if(event.socialLink != '')
        {
          window.open(event.socialLink, '_blank').focus();
        }
        if(event.onClick)
        {
          event.onClick();
        }
        break;
      case 2: //Instructor
        this.dialogRef = this.dialog.open(CalendarFormDialogComponent, {
          panelClass: 'calendar-form-dialog',
          data: { event, action },
          width: '450px'
        });
    
        this.dialogRef
        .afterClosed()
        .subscribe(res => {
          if (!res) {
            return;
          }
          let dialogAction = res.action;
          let responseEvent = res.event;
    
          if (dialogAction === 'save') {
            this.calendarService
              .updateEvent(responseEvent)
              .subscribe(events => {
                this.events = this.initEvents(events);
                this.refresh.next();
              })
          } else if (dialogAction === 'delete') {
            this.removeEvent(event);
          }
    
        });
        break;
      case 3://Administrator
        break;
    }
  }

  public dayClicked({ date, events }: { date: Date, events: CalendarEvent[] }): void {

    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }
  }

  calcTime(d,offset) {   
    let dateLocal = new Date();
    let utc = d.getTime() - (dateLocal.getTimezoneOffset() * 60000);
    let nd = new Date(utc - (3600000*offset));
    var datestring = nd.getFullYear() + "-" + ('0' + (nd.getMonth()+1)).slice(-2) + "-" + ('0' + nd.getDate()).slice(-2)  + " " 
                    + ('0' + nd.getHours()).slice(-2) + ":" + ('0' + nd.getMinutes()).slice(-2) + ":" + ('0' + nd.getSeconds()).slice(-2);
    return datestring;
  }

  public eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;

    this.calendarService
      .updateEvent(event)
      .subscribe(events => {
        this.events = this.initEvents(events);
        this.refresh.next();
      });
  }

}
