import { Component, Inject, Injector, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseService, IUserFilterTypes } from 'app/views/services/base.service';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { AdminService } from 'app/views/services/admin.service';
import { HttpErrorResponse } from '@angular/common/http';

interface IFormActions {
  UPDATE:number;
  CREATE:number;
};

@Component({
  selector: 'app-dialog-coupon',
  templateUrl: './dialog-coupon.component.html',
  styleUrls: ['./dialog-coupon.component.css']
})
export class DialogCouponComponent implements OnInit {

  baseService:BaseService;
  userId:any;
  userEmail:any;
  form:FormGroup;
  actionId:number = 1;// 1=create / 2=update
  stripeId:string;
  couponId:string;
  fActions:IFormActions = {
    CREATE: 1,
    UPDATE: 2,
  };
  errorMessages:any = {};
  filters:any = {};
  filterTypes:IUserFilterTypes = {
    roleId:'roleId'
  };

  courses:any;
  licensees:any;
  durationVar:number;
  discountTypeVar:number;
  currencyVar:number;
  redemptionTypeVar:number;
  licenseeVar:number;
  appliesTo:any = [];

  constructor(
    injector:Injector,
    private loader: AppLoaderService,
    private service: AdminService,
    public dialogRef: MatDialogRef<DialogCouponComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.baseService = new BaseService(injector);
    this.actionId = this.data.actionId;
    this.couponId = this.data.couponId;
    this.stripeId = this.data.stripeId;
    this.createForm();
  }

  async ngOnInit() {
    this.getCourses();
  }

  createForm(): void {
    let formControls:any = {
      amount_off: new FormControl('',[
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(6),
        Validators.pattern("^[0-9]*$")
      ]),
      discount_type: new FormControl('',[
        Validators.required,
      ]),
      duration: new FormControl('',[
        Validators.required,
      ]),
      duration_in_months: new FormControl('',[
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(2),
        Validators.pattern("^[0-9]*$")
      ]),
      name: new FormControl('',[
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(45)
      ]),
      percent_off: new FormControl('',[
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(3),
        Validators.pattern("^[0-9]*$")
      ]),
      applies_to: new FormControl('',[
        Validators.required,
      ]),
      max_redemptions: new FormControl('',[
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(3),
        Validators.pattern("^[0-9]*$")
      ]),
      redemption_type: new FormControl('',[
        Validators.required,
      ]),
      licensee: new FormControl('',[
        Validators.required,
      ]),
    };

    this.form = new FormGroup(formControls);
    this.errorMessages = {
      amount_off:[
        { type: 'required',  message: '* Este campo es requerido.' },
        { type: 'minlength', message: '* Al menos 1 caracter.' },
        { type: 'maxlength', message: '* No más de 6 caracteres.' },
        { type: 'pattern', message: '* Solamente números enteros.' },
      ],
      discount_type:[
        { type: 'required',  message: '* Este campo es requerido.' },
      ],
      duration:[
        { type: 'required',  message: '* Este campo es requerido.' },
      ],
      duration_in_months:[
        { type: 'required',  message: '* Este campo es requerido.' },
        { type: 'minlength', message: '* Al menos 1 caracter.' },
        { type: 'maxlength', message: '* No más de 2 caracteres.' },
        { type: 'pattern', message: '* Solamente números enteros.' },
      ],
      name:[
        { type: 'required',  message: '* Este campo es requerido.' },
        { type: 'minlength', message: '* Al menos 5 caracteres.' },
        { type: 'maxlength', message: '* No más de 45 caracteres.' },
      ],
      percent_off:[
        { type: 'required',  message: '* Este campo es requerido.' },
        { type: 'minlength', message: '* Al menos 1 caracter.' },
        { type: 'maxlength', message: '* No más de 3 caracteres.' },
        { type: 'pattern', message: '* Solamente números enteros.' },
      ],
      applies_to:[
        { type: 'required',  message: '* Este campo es requerido.' },
      ],
      max_redemptions:[
        { type: 'required',  message: '* Este campo es requerido.' },
        { type: 'minlength', message: '* Al menos 1 caracter.' },
        { type: 'maxlength', message: '* No más de 3 caracteres.' },
        { type: 'pattern', message: '* Solamente números enteros.' },
      ],
      redemption_type:[
        { type: 'required',  message: '* Este campo es requerido.' },
      ],
      licensee:[
        { type: 'required',  message: '* Este campo es requerido.' },
      ],
    };
  }

  get amount_off(): AbstractControl {
    return this.form.controls['amount_off'];
  }

  get discount_type(): AbstractControl {
    return this.form.controls['discount_type'];
  }

  get duration(): AbstractControl {
    return this.form.controls['duration'];
  }

  get duration_in_months(): AbstractControl {
    return this.form.controls['duration_in_months'];
  }

  get name(): AbstractControl {
    return this.form.controls['name'];
  }

  get percent_off(): AbstractControl {
    return this.form.controls['percent_off'];
  }

  get applies_to(): AbstractControl {
    return this.form.controls['applies_to'];
  }

  get max_redemptions(): AbstractControl {
    return this.form.controls['max_redemptions'];
  }

  get redemption_type(): AbstractControl {
    return this.form.controls['redemption_type'];
  }

  get licensee(): AbstractControl {
    return this.form.controls['licensee'];
  }


  getCourses(){
    this.loader.open('Obteniendo datos ...');
    this.service.getStripeCourses().then(
      (data:any) => {
        let resp = data.body;
        
        if(!resp.success){
          this.service.openDialog('Error al obtener los datos de los cursos');
          this.dialogRef.close(false);
          return;
        }

        this.courses = resp.data;
        this.getLicensees();
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.service.openDialog("Error en el Servicio");
      }
    );
  }

  getLicensees(){
    this.service.getLicensees().then(
      (data:any) => {
        let resp = data.body;        
        if(!resp.success){
          this.service.openDialog('Error al obtener los datos de los cursos');
          this.dialogRef.close(false);
          return;
        }
        this.licensees = resp.data;

        if(this.actionId == this.fActions.CREATE){
          this.onFilterChange({id:1,filterType:1});
          this.onFilterChange({id:1,filterType:2});
          this.onFilterChange({id:1,filterType:4});
          this.loader.close(); 
        }else if(this.actionId == this.fActions.UPDATE) {
          this.getCoupon();
        }       
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.service.openDialog("Error en el Servicio");
      }
    );
  }

  getCoupon(): void {
    if(!this.stripeId){
      this.service.openDialog('Este cupón no está registrado en la plataforma de Stripe. Favor de verificar.'); 
      this.dialogRef.close(true);
      return;
    }

    let data = {
      couponId: this.couponId,
      stripeId: this.stripeId
    }
    this.service.getCoupon(data).then(
      async (data:any) => {
        let resp = data.body;
        this.loader.close();
        if(!resp.success){
          this.service.openDialog(resp.message);
          this.dialogRef.close(false);
          return;
        }

        let dataCoupon = resp.data;

        this.form.controls['name'].setValue(dataCoupon.stripeData.name);

        let duration = dataCoupon.stripeData.duration == 'forever' ? 1 : dataCoupon.stripeData.duration == 'once' ? 2 : 3;
        this.onFilterChange({id:duration,filterType:1});
        if(duration == 3){
          this.form.controls['duration_in_months'].setValue(dataCoupon.stripeData.duration_in_months);
        }

        if(dataCoupon.stripeData.percent_off){
          this.onFilterChange({id:1,filterType:2});
          this.form.controls['percent_off'].setValue(dataCoupon.stripeData.percent_off);
        }else{
          this.onFilterChange({id:2,filterType:2});
          this.form.controls['amount_off'].setValue(dataCoupon.stripeData.amount_off/100);
        }

        if(dataCoupon.stripeData.max_redemptions){
          this.onFilterChange({id:1,filterType:4});
          this.form.controls['max_redemptions'].setValue(dataCoupon.stripeData.max_redemptions);
        }else{
          this.onFilterChange({id:2,filterType:4});
        }
        
        this.onFilterChange({id:dataCoupon.licenseeId,filterType:5});

        if(dataCoupon.appliesTo){
          let appliesToArr = dataCoupon.appliesTo.slice(1,-1).split(",");
          appliesToArr.forEach(a => {
            let id = a.slice(1,-1);
            let course = this.courses.find(c => c.stripeId === id);
            if(course){
              this.appliesTo.push(course.course);
            }
          });          
        }

        this.form.disable();
        this.loader.close(); 
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.service.openDialog("Error en el Servicio");
      }
    );
  }

  onFilterChange(filter:any): void {
    switch (filter.filterType) {
      case 1:
        this.duration.setValue(filter.id);
        this.durationVar = filter.id;
        if(filter.id != 3){
          this.form.controls['duration_in_months'].setValue('1');
          this.form.controls['duration_in_months'].disabled;
        }else{
          this.form.controls['duration_in_months'].enabled;
        }
        this.form.updateValueAndValidity();
        break;
      case 2:
        this.discount_type.setValue(filter.id);
        this.discountTypeVar = filter.id;

        if(filter.id == 1){
          this.form.controls['percent_off'].enabled;
          this.form.controls['amount_off'].setValue('1');
          this.form.controls['amount_off'].disabled;
        }else if(filter.id == 2){
          this.form.controls['amount_off'].enabled;
          this.form.controls['percent_off'].setValue('1');
          this.form.controls['percent_off'].disabled;
        }
        this.form.updateValueAndValidity();
        break;
      case 3:
        //this.currency.setValue(filter.id);
        this.currencyVar = filter.id;
        break;
      case 4:
        this.redemption_type.setValue(filter.id);
        this.redemptionTypeVar = filter.id;

        if(filter.id == 1){
          this.form.controls['max_redemptions'].enabled;
        }else if(filter.id == 2){
          this.form.controls['max_redemptions'].setValue('1');
          this.form.controls['max_redemptions'].disabled;
        }
        this.form.updateValueAndValidity();
        break;
      case 5:
        this.licensee.setValue(filter.id);
        this.licenseeVar = filter.id;
        break;
    }
  }

  saveUser(){
    if(this.actionId == this.fActions.CREATE){
      this.create();
    }else{
      this.update();
    }
  }

  validateForm(data):boolean{
    if(data.discount_type == 1 && data.percent_off > 100){
      this.service.openDialog('El porcentaje no puede ser mayor a 100'); 
      return false;
    }
    return true;
  }

  create(){
    
    let data = this.form.value;
    var dataSave = {
      duration: data.duration == 1 ? 'forever' : data.duration == 2 ? 'once' : data.duration == 3 ? 'repeating' : 'forever',
      name: data.name,
      applies_to: data.applies_to,
      licensee: data.licensee
    };

    if(!this.validateForm(data)){
      return;
    }

    this.loader.open('Guardando datos ...');

    if(data.duration == 3){
      dataSave['duration_in_months'] = data.duration_in_months;
    }

    if(data.discount_type == 1){
      dataSave['percent_off'] = data.percent_off;
    }else if(data.discount_type == 2){
      dataSave['currency'] = 'USD';
      dataSave['amount_off'] = Math.round(data.amount_off*100);
    }

    if(data.redemption_type == 1){
      dataSave['max_redemptions'] = data.max_redemptions;
    }

    let appliesToString = "[";
    data.applies_to.forEach(a => {
      appliesToString += `"${a}",`;
    });
    appliesToString = appliesToString.substring(0, appliesToString.length - 1);
    appliesToString += "]";
    dataSave['appliesToString'] = appliesToString;

    this.service.createCoupon(dataSave).then(
    async (data:any) => {
        this.loader.close();
        let resp = data.body;
        this.service.openDialog(resp.message);        
        if(resp.success){
          this.dialogRef.close(true);
        }        
      },
      (http: HttpErrorResponse) => {
        console.log(http);
        this.loader.close();
        this.service.openDialog(http.error.message);
      }
    );
  }

  update(){

  }
}
