import { HttpErrorResponse } from '@angular/common/http';
import { Component, Injector, OnInit } from '@angular/core';
import { DialogCourseDetailsComponent } from 'app/views/dialogs/dialog-course-details/dialog-course-details.component';
import { Course } from 'app/views/shared/interfaces';
import { egretAnimations } from '../../../shared/animations/egret-animations';
import { BaseService, IResponse } from 'app/views/services/base.service';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css'],
  animations: [egretAnimations]
})
export class CoursesComponent extends BaseService implements OnInit {

  public viewMode: string = 'grid-view';//layout config
  public itemsPerPage:number = 5; // pageSize
  public currentPageP1:number; // page
  public itemsPerPageP1:number = 5; // pageSize
  public rowsNumberP1:number = 0; // totalRows
  public rowNumberInitP1:number = 1; // pagination config
  //
  public currentPageP2:number;//pagination config
  public itemsPerPageP2:number = 5;//pagination config
  public rowsNumberP2:number = 0;//pagination config
  public rowNumberInitP2:number = 1;//pagination config
  //
  public currentPageP3:number;
  public itemsPerPageP3:number = 5;
  public rowsNumberP3:number = 0;
  public rowNumberInitP3:number = 1;
  //
  public currentPageP4:number;
  public itemsPerPageP4:number = 5;
  public rowsNumberP4:number = 0;
  public rowNumberInitP4:number = 1;
  //
  public isLogged:boolean = false;
  public courses:Course[];
  public _courses:any[] = [];
  public expiredCourses:Course[] = [];
  public acquiredCourses:Course[] = [];
  public availableCourses:Course[] = [];
  public membershipsCouses:Course[] = [];
  public memberships:Array<any> = [];
  public haveMembership:number = 0;
  //
  currency:string = 'USD';
  userInfo:any;

  constructor(
    injector:Injector
  ) {
    super(injector);
    this.userInfo = this.storage.getUserInfo();
    this.isLogged = (this.userInfo != null && this.userInfo != undefined);
  }

  ngOnInit(): void {
    this.seoService.init(this.seoService.getCoursesNoAuthMetaTags());
    this.analyticsService.trackEvent('Cursos','Página de cursos 4x4','cursos');
    if (this.isLogged) {
      this.getCourses();
    } else {
      this.getCoursesNotLoggedIn();
    }
    this.activatedRoute.queryParams.subscribe(
      (params:any) => {
        try {
          if (params.hasOwnProperty('success') && params.hasOwnProperty('message')) {
            let message:string  = String(params.message) || undefined;
            this.service.openDialog(message).subscribe(
              () => {}
            );
          }
        } catch (error) {
          console.log(error);
          this.service.openDialog(`Ocurrió un error al obtener la información del pedido, contacte con el equipo de soporte.`);
        }
      }
    ); 
  }

  showTutorial(){
    let tutorialIsDisplayed = localStorage.getItem('tutorialCoursesIsDisplayed');
    if(this.role.isClient() && tutorialIsDisplayed!='1'){
      localStorage.setItem('tutorialCoursesIsDisplayed','1');
      setTimeout(this.service.showTutorialCoursesClient, 1000);
    }
  }

  getCourses(type:number=0,page=0,pageSize=this.itemsPerPage){
    this.loader.open('Cargando cursos');
    const loader = this.loader.getDialog();
    const user:any = this.storage.getUserInfo();
    let params = {
      userId:user.id,
      type:type,
      page:page,
      pageSize:pageSize
    };
    this.service.getCoursesByUser(params).subscribe(
      (data:any) => {
        loader.close();
        let body:IResponse = data.body;
        if (body.success){
          const expiredCourses = body.data?.expiredCourses;
          if (expiredCourses?.data instanceof Array && expiredCourses?.data.length > 0){
            this.expiredCourses = this.setCoursesImageUrl(expiredCourses.data);
            this.rowsNumberP1 = expiredCourses.totalRows;
          }
          const acquiredCourses = body.data?.acquiredCourses;
          if (acquiredCourses?.data instanceof Array && acquiredCourses?.data.length > 0){
            this.acquiredCourses = this.setCoursesImageUrl(acquiredCourses.data);
            this.rowsNumberP2 = acquiredCourses.totalRows;
          }
          const unpurchasedCouses = body.data?.unpurchasedCouses;
          if (unpurchasedCouses?.data instanceof Array && unpurchasedCouses?.data.length > 0){
            this.availableCourses = this.setCoursesImageUrl(unpurchasedCouses.data);
            this.rowsNumberP3 = unpurchasedCouses.totalRows;
          }
          const memberships = body.data?.memberships;
          if (memberships instanceof Array && memberships?.length > 0){
            this.memberships = memberships;
            this.haveMembership = memberships[0].haveMembership;
          }
        }else{
          this.service.openDialog(body.message);
        }
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        this.service.processHttpResponse(http);
      },
      ()=>this.showTutorial()
    );
  }

  public getCoursesNotLoggedIn(page=0,pageSize=this.itemsPerPage){
    this.loader.open('Cargando cursos');
    const loader = this.loader.getDialog();
    let params = {
      page:page,
      pageSize:pageSize
    };
    this.service.getCoursesNoLoggedIn(params).subscribe(
      (data : any) => {
        loader.close();
        let body:IResponse = data;
        if (body.success){
          const availableCourses = body.data;
          if (availableCourses?.data instanceof Array && availableCourses?.data.length > 0){
            this.availableCourses = this.setCoursesImageUrl(availableCourses.data);
            this.rowsNumberP3 = availableCourses.totalRows;
          }
        }
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        switch (http.status) {
          case 401:
            this.service.openDialog('Operación no permitida');
            this.service.clearUserInfo();
            this.service.navigateTo('/signin');
            break;
          case 500:
            this.service.openDialog(http.error.content);
            break;
          default:
            this.service.openDialog('Error con el servicio.');
            break;
        }
      }
    );
  }

  splitCourses(){
    if (this.courses.length > 4) {
      let temp = [], courses:any[] = [];
      let i = 0;
      while (i < this.courses.length) {
        if (i % 4 == 0) {
          let k = i;
          let inc = i + 4;
          for (let j = k; j < inc; j++){
            if (this.courses[j] != undefined) {
              temp.push(this.courses[j]);
            }
          }
          courses.push(temp);
          temp = [];
          i += 4;
        } 
      }
      this._courses = courses;
    }
  }

  public scrollTop(){
    let top = document.querySelector('.shop-wrap');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  goToDetails(course_id){
    this.router.navigate(['/dashboard/course', course_id]);
  }

  changePagePaginator1(page:any){
    this.currentPageP1 = Number(page);
    this.rowNumberInitP1 = this.currentPageP1==1 ? 1 : ((this.currentPageP1-1) * this.itemsPerPageP1);
    this.scrollTop();
    this.getCourses(1,this.currentPageP1-1,this.itemsPerPageP1);
  }

  changePagePaginator2(page:any){
    this.currentPageP2 = Number(page);
    this.rowNumberInitP2 = this.currentPageP2==1 ? 1 : ((this.currentPageP2-1) * this.itemsPerPageP2);
    this.scrollTop();
    this.getCourses(2,this.currentPageP2-1,this.itemsPerPageP2);
  }

  changePagePaginator3(page:any){
    this.currentPageP3 = Number(page);
    this.rowNumberInitP3 = this.currentPageP3==1 ? 1 : ((this.currentPageP3-1) * this.itemsPerPageP3);
    this.scrollTop();
    if (this.isLogged) {
      this.getCourses(3,this.currentPageP3-1,this.itemsPerPageP3);
    }else{
      this.getCoursesNotLoggedIn(this.currentPageP3-1,this.itemsPerPageP3);
    }
  }

  changePagePaginator4(page:any){
    this.currentPageP4 = Number(page);
    this.rowNumberInitP4 = this.currentPageP4==1 ? 1 : ((this.currentPageP4-1) * this.itemsPerPageP4);
    this.scrollTop();
    if (this.isLogged) {
      this.getCourses(4,this.currentPageP4-1,this.itemsPerPageP4);
    }else{
      this.getCoursesNotLoggedIn(this.currentPageP4-1,this.itemsPerPageP4);
    }
  }

  getCourseDetails(courseId:number,acquiredCourse){
    this.loader.open();
    const loader = this.loader.getDialog();
    this.service.getCourse(courseId).subscribe(
      (data) => {
        loader.close();
        if (data.status == 200 && data.body.content[0] != undefined){
          const course = data.body.content[0];
          this.openDialogCourseDetails(course,acquiredCourse);
        }
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        switch (http.status) {
          case 401:
            this.service.openDialog('Operación no permitida');
            this.service.clearUserInfo();
            this.service.navigateTo('/signin');
            break;
          case 500:
              this.service.openDialog(http.error.content);
              break;
          default:
            this.service.openDialog('Error con el servicio.');
            break;
        }
      }
    );
  }

  openDialogCourseDetails(course:any,acquiredCourse:boolean){
    this.dialog.open(DialogCourseDetailsComponent, {
      data: {
        course:course,
        acquiredCourse:acquiredCourse
      },
      autoFocus: false
    }).afterClosed().subscribe(result => {
    });
  }

  userHasLevelrequired(levelRequiredId:number):boolean{
    const user:any = this.service.getUserInfo();
    return (user.userLevelId >= levelRequiredId)?true:false;
  }

  buyCourse(id:number){
    this.router.navigate(['/dashboard/course/buy/',id,{isMentoria: false}]);
  }

  membershipRedirect(membership:any){
    this.navigateTo(`${this.SUBSCRIPTION_DETAILS_ROUTE}`);
  }
}
