import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogComponent } from 'app/shared/components/dialog/dialog.component';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { Observable } from 'rxjs';
import { DialogConfirmComponent } from '../dialogs/dialog-confirm/dialog-confirm.component';
import { AdminService } from './admin.service';
import { DateService } from './date.service';
import { PaginatorService } from './paginator.service';
import { RoleService } from './role.service';
import { DatePipe, Location } from '@angular/common';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { StorageService } from './storage.service';
import { DialogMassUploadComponent } from '../dialogs/dialog-mass-upload/dialog-mass-upload.component';
import { DialogResetPasswordComponent } from '../dialogs/dialog-reset-password/dialog-reset-password.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogEditUserComponent } from '../dialogs/dialog-edit-user/dialog-edit-user.component';
import { DialogCodeInscriptionComponent } from '../dialogs/dialog-code-inscription/dialog-code-inscription.component';
import { DialogRegistrationCodeGroupsComponent } from '../dialogs/dialog-registration-code-groups/dialog-registration-code-groups.component';
import { DialogConfirmRegistrationCodeComponent } from '../dialogs/dialog-confirm-registration-code/dialog-confirm-registration-code.component';
import { SeoService } from 'app/shared/services/seo.service';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { NavigationService } from 'app/shared/services/navigation.service';
import { ThemeService } from 'app/shared/services/theme.service';
import { LayoutService } from 'app/shared/services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { Profile } from '../dashboard/users/profile';
import { ComunicationService } from 'app/comunication.service';
import { AuthGuard } from 'app/shared/services/auth/auth.guard';
import { AuthenticationService } from 'app/shared/services/api/authentication.service';
import { SocialAuthService } from 'angularx-social-login';
import { API } from '../shared/api';
import { AnalyticsService } from 'app/shared/services/analytics.service';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  public router:Router;
  public service:AdminService;
  public redirect:string;
  public activatedRoute:ActivatedRoute;
  public role:RoleService;
  public dateService:DateService;
  public paginatorService:PaginatorService;
  public dialog:MatDialog;
  public loader:AppLoaderService;
  public location:Location;
  public formBuilder:FormBuilder;
  public storage:StorageService;
  public datepipe: DatePipe;
  public form:FormGroup;
  public snackBar:MatSnackBar;
  public seoService:SeoService;
  public meta:Meta;
  public date:DateService;
  public navService:NavigationService;
  public layout: LayoutService;
  public themeService: ThemeService;
  public translate: TranslateService;
  public renderer: Renderer2;
  public profileInfo: Profile;
  public notificacionC:ComunicationService;
  public ContentTypes = ContentTypes;
  public authGuard: AuthGuard;
  public authenticationService: AuthenticationService;
  public authService: SocialAuthService;
  public validSlugRegex = /^[a-z0-9-]+$/;
  public sanitizer:DomSanitizer;
  public analyticsService:AnalyticsService;
  
  constructor(
    injector: Injector
  ) {
    this.redirect= '/signin';
    this.router  = injector.get(Router);
    this.service = injector.get(AdminService);
    this.activatedRoute  = injector.get(ActivatedRoute);
    this.role = injector.get(RoleService);
    this.dateService = injector.get(DateService);
    this.paginatorService = injector.get(PaginatorService);
    this.dialog = injector.get(MatDialog);
    this.loader = injector.get(AppLoaderService);
    this.location = injector.get(Location);
    this.formBuilder = injector.get(FormBuilder);
    this.storage = injector.get(StorageService);
    this.datepipe = injector.get(DatePipe);
    this.snackBar = injector.get(MatSnackBar);
    this.seoService = injector.get(SeoService);
    this.meta = injector.get(Meta);
    this.date = injector.get(DateService);
    this.navService = injector.get(NavigationService);
    this.layout = injector.get(LayoutService);
    this.themeService = injector.get(ThemeService);
    this.translate = injector.get(TranslateService);
    this.renderer = injector.get(Renderer2);
    this.profileInfo = injector.get(Profile);
    this.notificacionC = injector.get(ComunicationService);
    this.authGuard = injector.get(AuthGuard);
    this.authenticationService = injector.get(AuthenticationService);
    this.authService = injector.get(SocialAuthService);
    this.sanitizer = injector.get(DomSanitizer);
    this.analyticsService = injector.get(AnalyticsService);
  }

  public getVersion():string {
    return `Versión - 0.0.1`;
  }

  gotoTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

  public validSlugName(displayName:string): boolean {
    if (this.validSlugRegex.test(displayName)) {
      return true;
    } else {
      return false;
    }
  }

  async checkImage(url: string): Promise<boolean> {
    return new Promise(resolve => {
      const img = new Image();
      img.src = url;
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
    });
  }

  setCoursesImageUrl(courses:any[]):any[] {
    courses.forEach(course => {
      this.checkImage(API.URL_BASE_STORAGE+course.imageUrl).then(isValid => {
        if (!isValid) {
          course.imageUrl = this.PLACEHOLDER_COURSES_URL;
        } else {
          course.imageUrl = this.getImage(course.imageUrl);
        }
      });
    });
    return courses;
  }

  typeRouteByCourse(course:any){
    if(course?.typeId == this.ContentTypes.PREMIUM){
      this.goTo(`/studyPlan/${course.courseSlug}`);
    }else if(course?.typeId == this.ContentTypes.MEMBERSHIP){
      this.goTo(`/studyPlan/${course.courseSlug}`);
    }else if(course?.typeId == this.ContentTypes.FREE){
      this.goTo(`/dashboard/course/buy/${course.id}`);
    }
  }

  getImage(url:string) {
    //return this._sanitizer.bypassSecurityTrustStyle(`url(${"img-src 'self' data:;" + (url != "")?API.URL_BASE_STORAGE+url:""})`);
    return API.URL_BASE_STORAGE+url;
  }

  generatePassword(): string {
    const length = 12; // Longitud de la contraseña
    const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz'; // Caracteres en minúscula
    const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'; // Caracteres en mayúscula
    const numericChars = '0123456789'; // Caracteres numéricos
    const specialChars = '!@#$%&*()-_+=[]{}<>?'; // Caracteres especiales

    // Combinamos todos los conjuntos de caracteres en uno solo
    const allChars = lowercaseChars + uppercaseChars + numericChars + specialChars;

    let password = '';

    // Generamos la contraseña asegurándonos de incluir al menos un carácter de cada conjunto
    password += lowercaseChars.charAt(Math.floor(Math.random() * lowercaseChars.length));
    password += uppercaseChars.charAt(Math.floor(Math.random() * uppercaseChars.length));
    password += numericChars.charAt(Math.floor(Math.random() * numericChars.length));
    password += specialChars.charAt(Math.floor(Math.random() * specialChars.length));

    // Completamos el resto de la contraseña con caracteres aleatorios del conjunto completo
    for (let i = 0; i < length - 4; i++) {
      password += allChars.charAt(Math.floor(Math.random() * allChars.length));
    }

    // Mezclamos los caracteres de la contraseña para mayor aleatoriedad
    password = password.split('').sort(() => Math.random() - 0.5).join('');

    return password
  }

  copyToClipboard(text: string): void {
    const textarea = document.createElement('textarea');
    textarea.value = text;

    textarea.style.position = 'fixed';
    textarea.style.left = '-9999px';

    document.body.appendChild(textarea);

    textarea.select();
    document.execCommand('copy');

    document.body.removeChild(textarea);
  }

  onClickCopy(text:string,title:string=null): void {
    this.copyToClipboard(text);
    let message:string = !title ? 'Texto copiado al portapapeles.' : `${title} copiado(a) al portapapeles.`;
    this.openSnackBar(message.trim());
  }

  public openSnackBar(message: string, action: string = 'Aceptar') {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }

  public navigateTo(url:string) {
    this.router.navigate([url],{replaceUrl:true});
  }

  public openDialog(content:string):Observable<any> {
    return this.dialog.open(DialogComponent, {
      data: {
        title: "Mensaje de la Plataforma: ",
        content: content
      },
      autoFocus: false,
      disableClose:true
    }).afterClosed();
  }

  public openConfirm(message: string):Observable<any> {
    return this.dialog.open(DialogConfirmComponent, {
      data: { message: message, confirm:true },
      autoFocus: false,
      disableClose: true
    }).afterClosed();
  }

  public openDialogMassUpload():Observable<any> {
    return this.dialog.open(DialogMassUploadComponent, {
      data: { },
      autoFocus: false,
      disableClose: true,
      width:'1080px',
      height:'720px'
    }).afterClosed();
  }

  public openDialogResetPassword(user:any):Observable<any> {
    return this.dialog.open(DialogResetPasswordComponent, {
      data: { 
        user:user
      },
      autoFocus: false,
      disableClose: true,
      width:'720px',
      height:'480px'
    }).afterClosed();
  }

  public openDialogEditUser(roleId:any=null,user:any=null):Observable<any> {
    return this.dialog.open(DialogEditUserComponent, {
      data: { 
        user:user,
        roleId:roleId,
        actionId: !user ? 1 : 2
      },
      autoFocus: false,
      disableClose: true,
      width:'720px',
      height:'480px'
    }).afterClosed();
  }

  public openNewCodeInscription(data:ICreateCodeInscripcion) {
    return this.dialog.open(DialogCodeInscriptionComponent, {
      data: data,
      autoFocus: false,
      disableClose: true,
      width:'720px',
      height:'485px'
    }).afterClosed().toPromise();
  }

  public openRegistrationCodeGroups(data:ICreateCodeInscripcion) {
    return this.dialog.open(DialogRegistrationCodeGroupsComponent, {
      data: data,
      autoFocus: false,
      disableClose: true,
      width:'950px',
      height:'520px'
    }).afterClosed().toPromise();
  }

  public openConfirmRegistrationCode(data) {
    return this.dialog.open(DialogConfirmRegistrationCodeComponent, {
      data: data,
      autoFocus: false,
      disableClose: true,
      width:'720px',
      height:'520px'
    }).afterClosed().toPromise();
  }

  public proccessHttpResponse(http:HttpErrorResponse):void{
    console.log(http);
    let datetime:string = this.dateService.getCurrentDateTime();
    let message:string = (http.error.hasOwnProperty('message'))?http.error.message:"";
    switch (http.status) {
      case 0:
        this.openDialog(`Servicio no disponible intente más tarde o verifique su conexión. ${datetime}`).subscribe(
          () => {
            this.router.navigate([this.redirect],{replaceUrl:true});
          }
        );
        break;
      case 401:
        let msg:string = (this.router.url == '/sessions/signin') 
        ? http.error.message
        : `Operación no permitida: "${message}" Serás redirigido al inicio ${datetime}`
        ;
        this.openDialog(`${msg}`).subscribe(
          () => {
            if (this.router.url != '/sessions/signin') this.navigateTo(this.redirect);
          }
        )
        break;
      case 404:
        this.openDialog(`Recurso o servicio no encontrado, intente de nuevo o contacte con el área de soporte. ${datetime}`);
        break;
      case 405:
        this.openDialog(` Método de consumo no válido: [${message}]. ${datetime}`);
        break;
      case 422:
        this.openDialog(`${message} ${datetime}`);
        break;
      case 500:
        this.openDialog(`${message} ${datetime}`);
        break;
      default:
        this.openDialog(`Error con el servicio. Intente entrar nuevamente o contacte con el area de soporte. ${datetime}`).subscribe(
          () => {
            this.router.navigate([this.redirect],{replaceUrl:true});
          }
        );
        break;
    }
  }

  public signout() {
    this.service.logout().subscribe(
      (response) => {
        let body:IResponse = response.body;
        if (!body.success){
          this.openDialog(body.message);
        }
        this.navigateTo('/sessions/signin');
      }, 
      (error:HttpErrorResponse) => {
        this.proccessHttpResponse(error);
      }
    )
  }
  
  public goTo(url:string){
    if (url.includes('home')) {
      window.location.replace(url);
    } else {
      this.router.navigateByUrl(url,{replaceUrl:true});
    } 
  }

  public formControlHasError(control:AbstractControl,error:any): boolean {
    return control.hasError(error.type) && (control.dirty || control.touched);
  }

  public noWhiteSpaceInputKeyUp(event:any) {
    const whiteSpacesRex = new RegExp(/\s/g);
    let input = event.target.value;
    event.target.value = input.replace(whiteSpacesRex,'');
  }

  CMS_ASSIGN_COURSES_ROUTE:string = '/dashboard/cms/users/courses';
  CMS_ADMIN_USERS_ROUTE:string = '/dashboard/cms/users';
  COURSES_NO_AUTH_ROUTE:string = '/cursos';
  ABOUT_ROUTE:string = '/somos';
  BLOG_ROUTE:string = '/blog';
  CONTACT_ROUTE:string = '/contacto';
  HOME_ROUTE:string = '/home';
  COURSES_AUTH_ROUTE:string = '/dashboard/cursos';
  STUDY_PLAN_ROUTE:string = '/studyPlan';
  SIMULATOR_STUDENT_MODULE_1:string = '/dashboard/emprendiendodesdecasa';
  SIMULATOR_STUDENT_MODULE_2:string = '/dashboard/ofertaydemanda';
  SIMULATOR_STUDENT_MODULE_3:string = '/dashboard/estrategiasdeaccesoamercados';
  SIMULATOR_STUDENT_MODULE_4:string = '/dashboard/operandoenmercadosdiversos';
  SIMULATOR_FACILITATOR_MODULE_1:string = '/dashboard/facilitador/emprendiendodesdecasa';
  SIMULATOR_FACILITATOR_MODULE_2:string = '/dashboard/facilitador/ofertaydemanda';
  SIMULATOR_FACILITATOR_MODULE_3:string = '/dashboard/facilitador/estrategiasdeaccesoamercados';
  SIMULATOR_FACILITATOR_MODULE_4:string = '/dashboard/facilitador/operandoenmercadosdiversos';
  CALENDAR_ROUTE:string = '/dashboard/calendar';
  SUBSCRIPTION_DETAILS_ROUTE:string = '/dashboard/subscription/details';
  SUBSCRIPTION_BUY_ROUTE:string = '/subscription/buy';
  CMS_ADMIN_PARTICIPANTS_ROUTE:string = '/dashboard/cms/users/participants';
  //
  PLACEHOLDER_COURSES_URL:string = './assets/images/4x4-placeholder.jpg';

}
export interface ICourse {
  courseId:number;
  course:string;
  productId?:string;
  salePrice:number;
};
export interface IResponse{
  success:boolean;
  message:string;
  datetime?:string;
  data:any;
};
export interface IRelCoursesPaymentsMethods{
  rcpmId:number;
  courseId:number;
  paymentMethodId:number;
  course?:string;
  paymentMethod?:string;
};
export interface ICatPaymentMethods{
  id:number;
  paymentMethod:string;
  active?:boolean;
};
export interface IStripePriceType{
  id:number;
  priceType:string;
};
export interface IStripePrice{
  priceId:string;
  productId:string;
  courseId:number;
  priceTypeId:number;
  active:boolean;
  createdAt:string;
  updatedAt:string;
  priceType:string;
  unitAmount:number;
  courseSalePrice:any;
  promotionAmount?:number;
};
export interface IFilter {
  id:number;
  filter?:string;
  filterType:string;
};
export interface IUserFilterTypes {
  roleId:string;
};
export interface ICreateCodeInscripcion {
  course:any;
  facilitator:any;
  isEdit:boolean;
  dataCodeSession?:any;
};
export const ContentTypes = {
  PREMIUM:    1,
  MEMBERSHIP: 2,
  FREE:       3
};
