<div fxLayout="row" fxLayoutAlign="center center" [hidden]="course?.typeId==ContentTypes.FREE">
    <form [formGroup]="form" fxFlex="80" fxFlex.xs="90" fxLayout.xs="column" fxLayout="column">
        <!--boton de retroceso-->
        <div fxLayout="row" fxLayoutAlign="start center" style="width: 100%;margin-left: 14px;">
            <div flex="30" class="m-1">
                <button mat-mini-fab color="purple" focusable="false" (click)="service.goBack()" [matTooltip]="'Regresar'">
                  <mat-icon>arrow_back</mat-icon>
                </button>
            </div>
            <div flex="70" class="m-1">
              <h3>Finalizar tu compra de {{(isMentory)?'Mentoría':'Curso'}} </h3>
            </div>
        </div>
        <!--/-->

        <!-- Rediseño de la vista de compras de cursos y mentorías -->
        <mat-horizontal-stepper [linear]="isLinear" #stepper fxFlex="100" fxFlex.xs="100" style="width: 100% !important">
            <mat-step [stepControl]="secondForm">
              
                <ng-template matStepLabel>Selecciona tu método de pago</ng-template>

                <label style="font-weight: bold;">Metodos de pago disponibles:</label>
                <br/><br/>
                <mat-radio-group fxFlex formControlName="paymentMethod" fxLayout="column" (change)="showTypePayment($event.value)">
                    <mat-radio-button *ngFor="let method of paymentMethods" [value]="method.id">{{method?.paymentMethod}}</mat-radio-button>
                </mat-radio-group>
                <br/>
                <div>
                  <label *ngIf="form.controls?.paymentMethod?.value==1"><b> * Continue para realizar pago con Paypal. </b></label>
                  <label *ngIf="form.controls?.paymentMethod?.value==2"><b> * Continue para rellenar formulario. </b></label>
                  <label *ngIf="form.controls?.paymentMethod?.value==3"><b> * Continue para rellenar formulario. </b></label>
                </div>
                <div fxFlex="100" fxLayout="row" fxLayoutAlign="end center" fxLayout.sm="column" fxLayout.xs="column" fxFlex.xs="100" fxFlex.sm="100" fxLayoutAlign.sm="center space-between" fxLayoutAlign.xs="center space-between">
                  <button class="m-1" mat-flat-button color="primary" [disabled]="secondForm.invalid" matStepperNext>
                    <mat-label [ngStyle.xs]="'margin-left: 20px;'">Siguiente</mat-label>
                    <mat-icon>navigate_next</mat-icon>
                  </button>
                </div>
              
            </mat-step>
            <!-- Informacion de pago -->
            <mat-step>
              <ng-template matStepLabel>Confirma los detalles del pedido</ng-template>
              <mat-card fxFlex="100" fxLayout="column" style="width: 95%;box-shadow: none;">
                  <h3>Detalles del pedido:</h3>
                  <div fxFlex="100" fxLayout="row" fxLayoutAlign="center center">
                      <!--<img onerror="this.src='assets/images/entrepeneurs/not-found.png'" [src]="" alt="" height="50" width="50">-->
                      <mat-icon style="margin-bottom: 8px;margin-left: 10px;">article</mat-icon>
                      <h4 style="font-weight: bold; word-break: break-all; margin-left: 10px;vertical-align: middle;">{{isMentory == true ? "Mentoría -" : "" }} {{course?.course}}</h4>
                  </div>
                  <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-around center">
                    <div fxFlex="50" fxLayoutAlign="end center">
                      <label style="margin-right: 10px;">Precio:</label>
                    </div>
                    <div fxFlex="50" fxLayoutAlign="start center">
                      <label style="margin-left: 10px;"><b>${{ isMentory == true ? "100.00 USD": this.service.getPrecio( course?.salePrice) }}</b></label>
                    </div>
                  </div>
                  <mat-divider [inset]="true"></mat-divider>
                  <div fxFlex="100" fxLayout="row" fxLayoutAlign="center center" style="margin-top: 15px; margin-bottom: 15px;">
                    <div fxFlex="50" fxLayoutAlign="end center">
                      <label style="margin-right: 10px;">Total:</label>
                    </div>
                    <div fxFlex="50" fxLayoutAlign="start center">
                      <label style="margin-left: 10px;"><b>${{isMentory == true ? "100.00 " : calculateTotal()}} USD</b></label>
                    </div>
                  </div>
                  <div #bbva>
                      <button mat-button class="btn btn-purple" fxFlex="100" (click)="comprarCurso()">
                          <mat-label>Pagar con BBVA</mat-label>
                      </button>
                  </div>
                  <div #creditCard fxFlex="100" fxLayout="row" fxLayoutAlign="center center">
                    <!---->
                    <button type="button" mat-flat-button class="btn-green" fxFlex="50" (click)="createSessionStripeType()">
                      <mat-label>Continuar con el pago</mat-label>
                      <mat-icon>credit_card</mat-icon>
                    </button>
                  </div>
                  <div class="text-center paypal-div" #paypal></div>
                  <div #pagoDirecto>
                    <button type="button" mat-button class="btn btn-purple" fxFlex="100" (click)="openConfirmDialog();">
                      <mat-label>Comprar {{(isMentory)?'Mentoría':'Curso'}}</mat-label>
                      <mat-icon class="m-1">credit_card</mat-icon>
                    </button>
                  </div>
                  <div class="text-center m-1">
                    <label>Al completar la compra, aceptas nuestros <a class="hyper" (click)="openDialogTerms()">Términos y Condiciones</a>.</label>
                </div>
              </mat-card>
              <!---->
              <div fxFlex="95" fxLayout="row" fxLayoutAlign="end center" fxLayout.sm="column" fxLayout.xs="column" fxFlex.xs="100" fxFlex.sm="100" fxLayoutAlign.sm="center space-between" fxLayoutAlign.xs="center space-between" >
                <button mat-flat-button color="primary" matStepperPrevious>
                    <mat-icon>navigate_before</mat-icon>
                    Regresar
                </button>
                <!-- <button mat-flat-button color="primary" (click)="stepper.reset(); ">Reiniciar</button> -->
              </div>
            </mat-step>
        </mat-horizontal-stepper>
        <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%; background: white; padding: 0px 0px 25px 0px;">
          <img src="assets/images/pago-seguro.jpg" style="width: 200px; height: auto;">
        </div>
    </form>
    <br/>
</div>
<div fxLayout="row" fxLayoutAlign="center center" [hidden]="course?.typeId!=ContentTypes.FREE">
  <div fxFlex="80" fxFlex.xs="90" fxLayout.xs="column" fxLayout="column">
    <ng-template matStepLabel>Confirma los detalles del pedido</ng-template>
    <mat-card fxFlex="100" fxLayout="column" style="width: 95%;box-shadow: none;">
      <h3>Detalles del pedido:</h3>
      <div fxFlex="100" fxLayout="row" fxLayoutAlign="center center">
        <!--<img onerror="this.src='assets/images/entrepeneurs/not-found.png'" [src]="" alt="" height="50" width="50">-->
        <mat-icon style="margin-bottom: 8px;margin-left: 10px;">article</mat-icon>
        <h4 style="font-weight: bold; word-break: break-all; margin-left: 10px;vertical-align: middle;">{{course?.course}}</h4>
      </div>
      <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-around center">
        <div fxFlex="50" fxLayoutAlign="end center">
          <label style="margin-right: 10px;">Precio:</label>
        </div>
        <div fxFlex="50" fxLayoutAlign="start center">
          <label style="margin-left: 10px;"><b>{{course?.salePrice}}</b></label>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div fxFlex="100" fxLayout="row" fxLayoutAlign="center center" style="margin-top: 15px; margin-bottom: 15px;">
        <div fxFlex="50" fxLayoutAlign="end center">
          <label style="margin-right: 10px;">Total:</label>
        </div>
        <div fxFlex="50" fxLayoutAlign="start center">
          <label style="margin-left: 10px;"><b>{{course?.salePrice}}</b></label>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <button type="button" mat-button class="btn btn-purple" fxFlex="50" (click)="buyCourseFree();">
          <mat-label>Adquirir gratis</mat-label>
          <!-- <mat-icon class="m-1">credit_card</mat-icon> -->
        </button>
      </div>
      <div class="text-center m-1">
        <label>Al completar la compra, aceptas nuestros <a class="hyper" (click)="openDialogTerms()">Términos y Condiciones</a>.</label>
      </div>
    </mat-card>
  </div>
</div>