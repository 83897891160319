import { Component, ElementRef, Inject, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseService } from 'app/views/services/base.service';
import { API } from 'app/views/shared/api';

@Component({
  selector: 'app-offer-demand-moulo2',
  templateUrl: './offer-demand-moulo2.component.html',
  styleUrls: ['./offer-demand-moulo2.component.css']
})
export class OfferDemandMoulo2Component implements OnInit {
  week:any;
  productMax:any = 18;
  img:string = 'assets/modulo2/tabla.jpg';
  baseService:BaseService;
  productSell:number = 1;
  prices:Array<number> = [0];
  pricesForWeek:any = {
    2: [[0],[138],[136],[134],[132],[130],[127],[124],[121],[118],[117],[115/* ,111,103,99,94 */],[89]],
    3: [[0],[0],[140],[138],[136],[134],[132],[130],[127],[124],[121],[118/* ,117,115,111,103 */],[99]],
    4: [[0],[0],[0],[0],[0],[140],[138],[136],[134],[132],[130],[127/* ,124,121,118,117 */],[115]],
    5: [[0],[0],[0],[0],[0],[0],[0],[140],[138],[136],[134],[132/* ,130,127,124,121 */],[118]]
  };
  WEEK_DESCRIPTION = [
    "",
    "",
    "Semana anterior al día de pago",
    "Mediados de mes",
    "Semana siguiente al día de pago",
    "Día de pago"
  ];

  constructor(injector:Injector,@Inject(MAT_DIALOG_DATA) public data: any) {
    this.baseService = new BaseService(injector);
   }

  ngOnInit() {
    if(this.data?.week){
      this.week = this.data.week;
    }
    console.log(this.week);
    this.sumProduct(0);
  }

  async sumProduct(sum:number){
    if(this.week<=1) return;

    let count = 0;
    count = this.productSell + (sum);
    if(count <= this.productMax && count >= 1){
      if(count > 18){
        await this.baseService.openDialog(`La cantidad maxima a vender es 18`);
        return
      }
      this.productSell = count;
    } else if(count < 1){
      await this.baseService.openDialog(`La cantidad minima a vender es 1`);
    } else{
      await this.baseService.openDialog(`${this.productMax} es lo maximo que se puede vender`);
    }

    let quantity = 0;
    if(this.productSell > 11 && this.productSell < 18){
      quantity = 11;
    } else if(this.productSell >= 18){
      quantity = 12;
    }else{
      quantity = this.productSell;
    }
    this.prices = this.pricesForWeek[this.week][quantity];
    console.log(this.prices);
  }
}
