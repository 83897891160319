<div class="container">
<div class="container-fluid">
	<h4>Perfil</h4>
	<hr/>
	<form [formGroup]="form" (ngSubmit)="openConfirmDialog();">
		<!--form-->
		<div fxFlexAlign="center" fxLayout="column" fxLayoutGap="20px">
			<div class="mat-elevation-z4" fxLayout="row" fxLayoutAlign="space-around strech" fxLayout.lt-md="column" fxLayout.xs="column">
				<div class="p-3" fxFlex="30">
					<mat-label class="fw-bold">Información básica</mat-label>
					<br/>
					<mat-label>Edita los detalles de tu cuenta y opciones</mat-label>
				</div>
				<div class="p-2 bg-light-gray" fxLayout.xs="column" fxFlex="70" fxFlex.xs="100">
					<div class="row">
						<div class="col">
							<div class="p-1">
								<mat-form-field appearance="outline" fxFlex.xs="100">
									<mat-label>NOMBRE:</mat-label>
									<input matInput formControlName="name" value="">
								</mat-form-field>
								<small 
								*ngIf="form.controls['name'].hasError('required') && form.controls['name'].touched" 
								class="form-error-msg"> * Requerido. </small>
							</div>
						</div>
						<div class="col">
							<div class="p-1">
								<mat-form-field appearance="outline" fxFlex.xs="100">
									<mat-label>APELLIDOS:</mat-label>
									<input matInput formControlName="last_name" placeholder="Apellido Paterno:" value="">
								</mat-form-field>
								<small 
								*ngIf="form.controls['last_name'].hasError('required') && form.controls['last_name'].touched" 
								class="form-error-msg"> * Requerido. </small>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="p-3" fxFlex="100">
							<mat-form-field appearance="outline" fxFlex="100">
								<mat-label>DESCRIPCIÓN:</mat-label>
								<textarea matInput rows="5" formControlName="description" placeholder="Edita tu descripción:" value=""></textarea>
							</mat-form-field>
							<small 
							*ngIf="form.controls['description'].hasError('required') && form.controls['description'].touched" 
							class="form-error-msg"> * Requerido. </small>
						</div>
					</div>
					<div class="row">
						<div class="p-3" fxFlex.xs="100" fxFlex="30" *ngIf="infoUser.roleId==2">
							<mat-form-field appearance="outline" fxFlex="100">
								<mat-label>TITULO:</mat-label>
								<input matInput type="text" formControlName="title" value="">
							</mat-form-field>
							<!-- <small 
							*ngIf="form.controls['password'].hasError('required') && form.controls['password'].touched" 
							class="form-error-msg"> * Requerido. </small> -->
						</div>
					</div>
					<div class="row">
						<div class="p-3" fxFlex.xs="100" fxFlex="70" fxLayout="row" fxLayout.xs="column">
							<mat-form-field appearance="outline">
								<mat-label>PAÍS:</mat-label>
								<mat-select matInput formControlName="country" fxFlex="100" (selectionChange)="getStates($event.value,true);">
									<mat-option *ngFor="let country of countriesArray" [value]="country.id">{{country.country}}</mat-option>
								</mat-select>
							</mat-form-field>
							<small 
							*ngIf="form.controls['country'].hasError('required') && form.controls['country'].touched" 
							class="form-error-msg"> * Requerido. </small>
							<br/><small>* El país no será visible para otros usuarios.</small>
						</div>
					</div>
					<div class="row">
						<div class="p-3" fxFlex.xs="100" fxFlex="70" fxLayout="row" fxLayout.xs="column">
							<mat-form-field appearance="outline">
								<mat-label>Estado:</mat-label>
								<mat-select matInput formControlName="state_id" fxFlex="100">
									<mat-option *ngFor="let state of statesArray" [value]="state.id">{{state.state}}</mat-option>
								</mat-select>
							</mat-form-field>
							<small 
							*ngIf="form.controls['state_id'].hasError('required') && form.controls['state_id'].touched" 
							class="form-error-msg"> * Requerido. </small>
							<!-- <br/><small>* El país no será visible para otros usuarios.</small> -->
						</div>
					</div>
					<div class="row">
						<div class="p-3" fxFlex="100">
							<mat-slide-toggle matInput formControlName="profile_public">Perfil público (Tu correo será visible para el público)</mat-slide-toggle>
						</div>
					</div>
					<div fxLayout="row" fxLayout.xs="column" *ngIf="infoUser.roleId==2">
						<div fxFlex="80" fxFlex.xs="100">
							<mat-form-field fxFlex="100">
								<mat-label>Certificados</mat-label>
								<mat-chip-list style="padding: 0.1em !important;" #chipListCertifications aria-label="Certificados">
								  <mat-chip  *ngFor="let certification of certifications" [selectable]="selectable"
										   [removable]="removable" (removed)="remove(certification)">
									<img src="assets/images/certifications/{{certification.image_id}}.png" height="20px" width="20px" alt="">
									{{certification.certification}}
									<mat-icon matChipRemove *ngIf="removable">cancelar</mat-icon>
								  </mat-chip>
								  <!-- <input placeholder="Nuevo certificado..."
										 [matChipInputFor]="chipListCertifications"
										 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
										 [matChipInputAddOnBlur]="addOnBlur"
										 (matChipInputTokenEnd)="add($event)"> -->
								</mat-chip-list>
							  </mat-form-field>						  
						</div>
						<div fxFlex="20" fxFlex.xs="100" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutAlign.xs="space-around strech">
							<button type="button" mat-button class="btn btn-purple m-1" (click)="addCertification()">
								<mat-icon>add</mat-icon>
								<mat-label>Agregar</mat-label>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="mat-elevation-z4" fxLayout="row" fxLayoutAlign="space-around strech" fxLayout.lt-md="column" fxLayout.xs="column">
				<div class="p-3" fxFlex="30">
					<mat-label class="fw-bold">Contraseña</mat-label>
					<br/>
					<mat-label>Modifica tu contraseña.</mat-label>
				</div>
				<div class="p-2 bg-light-gray" fxLayout.xs="column" fxFlex="70" fxFlex.xs="100">
					<div class="p-1">
						<mat-form-field appearance="outline" [floatLabel]="true" fxFlex.xs="100">
							<mat-label>CONTRASEÑA ACTUAL:</mat-label>
							<input #passBefore matInput type="password" formControlName="password" value="">
							<mat-icon matSuffix style="width: auto !important; cursor: pointer;" (click)="passBefore.type = (passBefore.type == 'password' ? 'text' : 'password')">{{passBefore.type == 'password' ? 'visibility_off' : 'visibility'}}</mat-icon>
						</mat-form-field>
						<small 
						*ngIf="form.controls['password'].hasError('required') && form.controls['password'].touched" 
						class="form-error-msg"> * Requerido. </small>
					</div>
					<div class="p-1">
						<mat-form-field appearance="outline" fxFlex.xs="100">
							<mat-label>NUEVA CONTRASEÑA:</mat-label>
							<input #passNew matInput type="password" formControlName="password_new" value="">
							<mat-icon matSuffix style="width: auto !important; cursor: pointer;" (click)="passNew.type = (passNew.type == 'password' ? 'text' : 'password')">{{passNew.type == 'password' ? 'visibility_off' : 'visibility'}}</mat-icon>
						</mat-form-field>
						<small 
						*ngIf="form.controls['password_new'].hasError('required') && form.controls['password_new'].touched" 
						class="form-error-msg"> * Requerido. </small>
						<small *ngIf="form.controls['password_new'].hasError('pattern') && form.controls['password_new'].touched" 
						class="form-error-msg"><br> * Ingrese 8 caracteres, 1 letra mayúscula, sin espacios. </small>
					</div>
					<div class="p-1">
						<mat-form-field appearance="outline" fxFlex.xs="100">
							<mat-label>CONFIRMAR CONTRASEÑA:</mat-label>
							<input #passConfirm matInput type="password" formControlName="password_confirm" placeholder="Confirma tu contraseña." value="">
							<mat-icon matSuffix style="width: auto !important; cursor: pointer;" (click)="passConfirm.type = (passConfirm.type == 'password' ? 'text' : 'password')">{{passConfirm.type == 'password' ? 'visibility_off' : 'visibility'}}</mat-icon>
						</mat-form-field>
						<small 
						*ngIf="form.controls['password_confirm'].hasError('required') && form.controls['password_confirm'].touched" 
						class="form-error-msg"> * Requerido. </small>
						<small *ngIf="form.controls['password_confirm'].hasError('pattern') && form.controls['password_confirm'].touched" 
						class="form-error-msg"><br>* Ingrese 8 caracteres, 1 letra mayúscula, sin espacios. </small>
						<small *ngIf="form.errors?.mismatch && (form.controls['password_confirm'].dirty || form.controls['password_confirm'].touched)" 
                      	class="form-error-msg"> * Las contraseñas no coinciden</small>
					</div>
				</div>
			</div>
			<div class="mat-elevation-z4" fxLayout="row" fxLayoutAlign="space-around strech" fxLayout.lt-md="column" fxLayout.xs="column">
				<div class="p-3" fxFlex="30">
					<mat-label class="fw-bold">Perfil</mat-label>
					<br/>
					<mat-label>Modifica la foto de tu perfil.</mat-label>
				</div>
				<div class="p-2 bg-light-gray" fxLayout="column" fxFlex="100">
					<div fxLayout="row" fxLayout.xs="column">
						<mat-label>FOTO:</mat-label>
						<div style="margin: 20px;" fxFlex="15" fxFlex.xs="100" fxLayoutAlign="space-around strech">
							<img [src]="imageUrl" class="rounded img-fluid img-thumbnail" onError="this.src='./assets/images/perfil-usuario.png'" style="max-height: 200px;"/>
						</div>
						<div style="margin: 20px;" fxFlex="80" fxFlex.xs="100" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutAlign.xs="space-around strech">
							<input style="display: none" id="file-input" type="file" (change)="onFileChanged($event)"/>
							<button mat-button *ngIf="fileUploaded();" type="button" class="btn btn-sm btn-danger m-1" (click)="removeFile();">
								<mat-label>Quitar foto</mat-label>
							</button>
							<label for="file-input" mat-button class="btn btn-purple m-1">
								Seleccionar foto
							</label>
							<button mat-button *ngIf="imageProfileFile" type="button" class="btn btn-purple m-1" (click)="uploadImageProfile();">
								<mat-label>Subir foto</mat-label>
							</button>
						</div>
					</div>
					<div *ngIf="fileUploaded();">
						<mat-label><b>Nombre:</b>&nbsp;<span class="text-purple">{{imagePath[0].name}}</span></mat-label><br/>
						<mat-label><b>Tipo:</b>&nbsp;<span class="text-purple">{{imagePath[0].type}}</span></mat-label><br/>
						<mat-label><b>Tamaño:</b>&nbsp;<span class="text-purple">{{imagePath[0].size/1024/1024 | number:'.2'}} MB.</span></mat-label>
					</div>
					<mat-form-field appearance="outline" fxFlex="100">
						<mat-label>INTERESES Y EXPECTATIVAS:</mat-label>
						<textarea matInput rows="5" formControlName="interests" placeholder="Descripción ..." value=""></textarea>
					</mat-form-field>
					<small 
					*ngIf="form.controls['description'].hasError('required') && form.controls['description'].touched" 
					class="form-error-msg"> * Requerido. </small>
					
				</div>
			</div>
			<div class="p-3" fxLayout="row" fxLayoutAlign="space-around strech">
				<button type="submit" mat-button class="btn-green btn-lg" fxFlex="30" fxFlex.xs="100">
					<mat-label>Guardar</mat-label>
				</button>
			</div>
		</div>
		<!--end form-->

	</form>
	<hr/>
</div>
</div>