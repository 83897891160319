import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, MetaDefinition } from '@angular/platform-browser';
const hreflangURLs = [
  { lang: 'es-MX', url: 'https://my4x4academy.com/' },
  { lang: 'es-CL', url: 'https://my4x4academy.com/' },
  { lang: 'en', url: 'https://my4x4academy.com/' }
];
@Injectable({
  providedIn: 'root'
})
export class SeoService {
  
  private renderer: Renderer2;

  constructor(
    rendererFactory: RendererFactory2,
    private meta:Meta
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  getAboutMetaTags():MetaDefinition[] {
    return [
      { name: 'description', content: 'Descubre My4x4 Academy, un enfoque innovador y probado para potenciar tu emprendimiento. Únete y alcanza el éxito empresarial con nosotros.' },
      { name: 'keywords', content: 'Potencial Empresarial,Emprendedores,Verdades Empresariales, Lecciones del Emprendimiento, Capacitacion emprendedores, Emprendimiento, Innovación, Comunidad Empresarial, My4X4Academy, Habilidades Empresariales' },
    ];
  }

  getCoursesNoAuthMetaTags():MetaDefinition[] {
    return [
      { name: 'description', content: 'Descubre cursos diseñados para potenciar tu emprendimiento en My4X4Academy. Únete a nuestra comunidad y alcanza el éxito empresarial.' },
      { name: 'keywords', content: 'Potencial Empresarial,Emprendedores,Verdades Empresariales, Lecciones del Emprendimiento, Capacitacion emprendedores, Emprendimiento, Innovación, Comunidad Empresarial, My4X4Academy, Habilidades Empresariales' },
    ];
  }

  getHomeMetaTags():MetaDefinition[] {
    return [
      { name: 'description', content: 'Crece tu emprendimiento con My4X4 Academy. Descubre verdades y lecciones empresariales clave para alcanzar el éxito. Unéte a la comunidad' },
      { name: 'keywords', content: 'Potencial Empresarial,Emprendedores,Verdades Empresariales, Lecciones del Emprendimiento, Capacitacion emprendedores, Emprendimiento, Innovación, Comunidad Empresarial, My4X4Academy, Habilidades Empresariales' },
    ];
  }
  /*
  console.log('Meta description:', this.meta.getTag('name="description"'));
  console.log('Meta keywords:', this.meta.getTag('name="keywords"'));
  */

  getLogoAltText():string {
    return '4x4_Academy';
  }

  getBannerOfferAltText():string {
    return 'Oferta_My4X4_Academy';
  }

  setCanonicalURL(url?: string):void {
    const canURL = url === undefined ? window.location.href : url;
    const head = document.head;
    // Elimina la etiqueta canónica actual si existe
    const existingLink = head.querySelector('link[rel="canonical"]');
    if (existingLink) {
      this.renderer.removeChild(head, existingLink);
    }
    // Crea y añade la nueva etiqueta canónica
    const link: HTMLLinkElement = this.renderer.createElement('link');
    this.renderer.setAttribute(link, 'rel', 'canonical');
    this.renderer.setAttribute(link, 'href', canURL);
    this.renderer.appendChild(head, link);
  }

  setHreflangURLs():void {
    const head = document.head;
    // Elimina todas las etiquetas hreflang actuales
    const existingLinks = head.querySelectorAll('link[rel="alternate"]');
    existingLinks.forEach(link => this.renderer.removeChild(head, link));
    // Añade nuevas etiquetas hreflang
    hreflangURLs.forEach(({ lang, url }) => {
      const link: HTMLLinkElement = this.renderer.createElement('link');
      this.renderer.setAttribute(link, 'rel', 'alternate');
      this.renderer.setAttribute(link, 'hreflang', lang);
      this.renderer.setAttribute(link, 'href', url);
      this.renderer.appendChild(head, link);
    });
  }

  init(metaTags:MetaDefinition[] = []): void {
    this.setCanonicalURL();//Agrega url canónica
    this.setHreflangURLs();//Agrega las referencias de lenguaje
    //Agrega metatags por componente
    metaTags.forEach(
      (mt:MetaDefinition) => {
        this.meta.removeTag(mt.name);
        this.meta.addTag(mt);
      }
    );
  }

}
