<div class="container-fluid">
  <div fxFlex="100" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
    <div fxLayout="row">
      <div fxFlex="5">
          <button mat-mini-fab color="primary" focusable="false" (click)="backToUsersTabFacilitator()">
              <mat-icon>arrow_back</mat-icon>
          </button>
      </div>
      <div fxFlex="95" fxLayoutAlign="start center">
          <h4>
              Administración de Usuarios
          </h4>
      </div>
    </div>
    <br>
    <div [style.display]="dataSource.data.length > 0 ? 'flex' : 'none'" fxLayout="column" fxFlex="100" fxLayoutAlign="center center">
      <mat-drawer-container class="example-container">
        <mat-drawer #drawer mode="side" position="end">
          <div fxFlex="100" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" class="overflow-hidden">
            <!--CURSOS ASIGNADOS-->
            <div fxFlex="50" fxLayout="column" fxLayoutAlign="start center" class="overflow-auto">
              <div fxFlex="10" fxLayoutAlign="start center">
                <mat-label class="text-purple">Cursos asignados a <b>{{currUser?.name}} {{currUser?.lastname}}</b>:</mat-label>
              </div>
              <div fxFlex="20">
                <mat-paginator #paginatorAssigned [pageIndex]="paginatorAssignedIndex" [length]="totalRowsAssigned" [pageSize]="pageSizeCourses" (page)="onChangePaginatorAssigned($event)" showFirstLastButtons></mat-paginator>
              </div>
              <div fxFlex="70">
                <mat-table #table [dataSource]="dataSourceAssigned" class="mat-elevation-z2">
                  <ng-container matColumnDef="id">
                    <mat-header-cell class="centrado" *matHeaderCellDef>#</mat-header-cell>
                    <mat-cell class="centrado" *matCellDef="let row;let i = index;"> 
                      {{i+1}} 
                    </mat-cell>
                  </ng-container>
                  
                  <ng-container matColumnDef="course">
                    <mat-header-cell *matHeaderCellDef>Curso</mat-header-cell>
                    <mat-cell *matCellDef="let row"> 
                      {{row.name}} {{row.course}} 
                    </mat-cell>
                  </ng-container>
          
                  <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>Acciones</mat-header-cell>
                    <mat-cell *matCellDef="let row" fxLayoutGap="10px">
                      <button mat-mini-fab color="purple" matTooltip="Códigos de inscripción" (click)="createCodeInscription(row)">
                        <mat-icon>confirmation_number</mat-icon>
                      </button>
                      <button mat-mini-fab color="red" matTooltip="Retirar curso" (click)="setCourseToInstructor(row,0)">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </mat-cell>
                  </ng-container>
                  
                  <mat-header-row *matHeaderRowDef="displayedColumnsAssigned"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumnsAssigned;"></mat-row>
                </mat-table>
              </div>
            </div>
            <!--CURSOS DISPONIBLES-->
            <div fxFlex="50" fxLayout="column" fxLayoutAlign="start center" class="overflow-auto">
              <div fxFlex="10" fxLayoutAlign="start center">
                <mat-label class="text-purple">Cursos disponibles:</mat-label>
              </div>
              <div fxFlex="20">
                <mat-paginator #paginatorCourses [pageIndex]="paginatorCoursesIndex" [length]="totalRowsCourses" [pageSize]="pageSizeCourses" (page)="onChangePaginatorCourses($event)" showFirstLastButtons></mat-paginator>
              </div>
              <div fxFlex="70">
                <mat-table #table [dataSource]="dataSourceCourses" class="mat-elevation-z2">
                  <ng-container matColumnDef="id">
                    <mat-header-cell class="centrado" *matHeaderCellDef>#</mat-header-cell>
                    <mat-cell class="centrado" *matCellDef="let row;let i = index;"> 
                      {{i+1}} 
                    </mat-cell>
                  </ng-container>
                  
                  <ng-container matColumnDef="course">
                    <mat-header-cell *matHeaderCellDef>Curso</mat-header-cell>
                    <mat-cell *matCellDef="let row"> 
                      {{row.name}} {{row.course}} 
                    </mat-cell>
                  </ng-container>
          
                  <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>Acciones</mat-header-cell>
                    <mat-cell *matCellDef="let row" fxLayoutGap="10px">
                      <button mat-mini-fab color="purple" matTooltip="Asignar curso" (click)="setCourseToInstructor(row,1)">
                        <mat-icon>add</mat-icon>
                      </button>
                    </mat-cell>
                  </ng-container>
                  
                  <mat-header-row *matHeaderRowDef="displayedColumnsAssigned"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumnsAssigned;"></mat-row>
                </mat-table>
              </div>
            </div>
          </div>
        </mat-drawer>
        <mat-drawer-content>
          <mat-table #table [dataSource]="dataSource" class="mat-elevation-z2 mat-table-users">
            <ng-container matColumnDef="id">
              <mat-header-cell class="centrado" *matHeaderCellDef>#</mat-header-cell>
              <mat-cell class="centrado" *matCellDef="let row;let i = index;"> 
                {{i+1}} 
              </mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef>Nombre</mat-header-cell>
              <mat-cell *matCellDef="let row"> 
                {{row.name}} {{row.lastname}} 
              </mat-cell>
            </ng-container>
    
            <ng-container matColumnDef="email">
              <mat-header-cell *matHeaderCellDef>Correo electrónico</mat-header-cell>
              <mat-cell *matCellDef="let row"> 
                {{row.email}} 
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="createdAt">
              <mat-header-cell *matHeaderCellDef [ngClass]="drawerOpened ? 'show-column' : 'hide-column' ">Fecha de registro</mat-header-cell>
              <mat-cell *matCellDef="let row" [ngClass]="drawerOpened ? 'show-column' : 'hide-column' "> 
                {{row.createdAt}} 
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="role">
              <mat-header-cell *matHeaderCellDef [ngClass]="drawerOpened ? 'show-column' : 'hide-column' ">Rol</mat-header-cell>
              <mat-cell *matCellDef="let row" [ngClass]="drawerOpened ? 'show-column' : 'hide-column' "> 
                {{row.role}} 
              </mat-cell>
            </ng-container>
    
            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef>Acciones</mat-header-cell>
              <mat-cell *matCellDef="let row" fxLayoutGap="10px">
                <button *ngIf="!drawerOpened || (drawerOpened && currUser.id != row.id)" mat-mini-fab color="purple" matTooltip="Asignar cursos" (click)="showCourses(drawer,row)">
                  <mat-icon>arrow_circle_right</mat-icon>
                </button>
                <button *ngIf="drawerOpened && currUser.id == row.id" mat-mini-fab color="white" matTooltip="Cancelar" (click)="hideCourses(drawer,row)">
                  <mat-icon>arrow_circle_left</mat-icon>
                </button>
              </mat-cell>
            </ng-container>
            
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row [ngClass]="currUser?.id == row.id ? 'mat-row-active' : ''" *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
          <mat-paginator #paginatorUsers [pageIndex]="paginatorUsersIndex" [length]="totalRowsF" [pageSize]="pageSize" (page)="onChangePaginatorF($event)" showFirstLastButtons></mat-paginator>
        </mat-drawer-content>
      </mat-drawer-container>      
    </div>
    <div *ngIf="dataSource.data.length <= 0" fxFlex="100" fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="10pxs">
      <mat-label class="text-purple">No hay facilitadores/as activos/as.</mat-label>
    </div>
  </div>
</div>