<div fxLayout="row" fxLayoutAlign="space-between center" >
  <p mat-dialog-title class="text-purple text-bold" style="margin: 0px;">Restablecer contraseña:</p>
  <button mat-icon-button aria-label="close icon" [mat-dialog-close]="false">
      <mat-icon>close</mat-icon>
  </button>
</div>
<mat-divider style="margin: 10px 0 10px 0;" [inset]="true"></mat-divider>
<mat-dialog-content class="dialog-content">
  <form [formGroup]="form">
    <div fxFlex="100" fxLayout="column" fxLayoutAlign="end center" fxLayoutGap="10px">
      <div fxFlex="33" fxLayoutAlign="center center" fxLayoutGap="10px">
        <div fxFlex="50" fxLayoutAlign="center center">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Nombre:</mat-label>
            <mat-icon matPrefix>person</mat-icon>
            <input matInput formControlName="name" required=""/>
            <mat-hint>Nombre completo</mat-hint>
          </mat-form-field>
        </div>
        <div fxFlex="50" fxLayoutAlign="center center">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Apellidos:</mat-label>
            <mat-icon matPrefix>person</mat-icon>
            <input matInput formControlName="lastname" required=""/>
            <mat-hint>Apellidos</mat-hint>
          </mat-form-field>        
        </div>
      </div>
      <div fxFlex="33" fxLayoutAlign="center center" fxLayoutGap="10px">
        <div fxFlex="50" fxLayoutAlign="center center">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Correo electrónico:</mat-label>
            <mat-icon matPrefix>email</mat-icon>
            <input matInput formControlName="email" required=""/>
            <mat-hint>Correo electrónico</mat-hint>
          </mat-form-field>        
        </div>
        <div fxFlex="50" fxLayoutAlign="center center">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Creación:</mat-label>
            <mat-icon matPrefix>schedule</mat-icon>
            <input matInput formControlName="createdAt" required=""/>
            <mat-hint>Fecha y hora de creación de la cuenta</mat-hint>
          </mat-form-field>        
        </div>
      </div>
      <div fxFlex="33" fxLayoutAlign="center center" fxLayoutGap="10px">
        <div fxFlex="50" fxLayoutAlign="center center">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Tipo de usuario(a):</mat-label>
            <mat-icon matPrefix>person</mat-icon>
            <input matInput formControlName="role" required=""/>
            <mat-hint>Tipo de usuario(a) / rol dentro de la plataforma.</mat-hint>
          </mat-form-field>        
        </div>
        <div fxFlex="50" fxLayoutAlign="center center">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Nueva contraseña:</mat-label>
            <mat-icon matPrefix matTooltip="Generar contraseña" class="cursor-pointer" (click)="password.setValue(baseService.generatePassword())">lock_reset</mat-icon>
            <input matInput formControlName="password" required=""/>
            <mat-icon matSuffix matTooltip="Copiar contraseña" class="cursor-pointer" (click)="baseService.onClickCopy(password.value,'Contraseña')">attach_file</mat-icon>
            <mat-hint>Contraseña de acceso generada automáticamente.</mat-hint>
          </mat-form-field>        
        </div>
      </div>
      <div fxFlex fxLayoutAlign="center center">
        <mat-label class="text-purple">* Al restablecer la contraseña el/la participante recibirá su nuevo acceso por correo electrónico.</mat-label>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-divider style="margin: 10px 0 10px 0;" [inset]="true"></mat-divider>
<mat-dialog-actions align="center">
  <button mat-button [mat-dialog-close]="true" class="btn-purple">
    <mat-label class="m-1">Cancelar</mat-label>
    <mat-icon aria-hidden="false">cancel_circle</mat-icon>
  </button>
  <button (click)="update()" mat-button class="btn-green">
    <mat-label class="m-1">Restablecer</mat-label>
    <mat-icon aria-hidden="false">check_circle</mat-icon>
  </button>
</mat-dialog-actions>