import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  private STORAGE_KEYS:IStorageKeys = {
    USER_INFO:'0e8d7222698321998df7f91690fa05c2',
    CHECKOUT_URL:'3278b57be41ea1a4d1d16ca271ce72e4'
  };

  public getUserInfo():any{
    let user:any = null;
    try {
      user = JSON.parse(localStorage.getItem(this.STORAGE_KEYS.USER_INFO));
    } catch (error) {
      console.error(error);
    }
    return user;
  }

  public setUserInfo(value:any):boolean{
    let success:boolean = false;
    try {
      localStorage.setItem(this.STORAGE_KEYS.USER_INFO,JSON.stringify(value));
      success = true;
    } catch (error) {
      console.error(error);
    }
    return success;
  }

  public cleanUserInfo():any{
    let remove:boolean = false;
    try {
      localStorage.removeItem(this.STORAGE_KEYS.USER_INFO);
      remove = true;
    } catch (error) {
      console.error(error);
    }
    return remove;
  }

  public getCheckoutUrl():string{
    let user:any = null;
    try {
      user = JSON.parse(localStorage.getItem(this.STORAGE_KEYS.CHECKOUT_URL));
    } catch (error) {
      console.error(error);
    }
    return user;
  }

  public setCheckoutUrl(value:any):boolean{
    let success:boolean = false;
    try {
      localStorage.setItem(this.STORAGE_KEYS.CHECKOUT_URL,JSON.stringify(value));
      success = true;
    } catch (error) {
      console.error(error);
    }
    return success;
  }

}
interface IStorageKeys{
  USER_INFO:string;
  CHECKOUT_URL?:string;
}
