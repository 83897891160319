<div class="container-fluid">
  <mat-tab-group #adminUsersTabs (selectedTabChange)="onTabChanged($event)">
    <mat-tab label="Participantes">
      <ng-template matTabContent>
        <div fxLayout="row" fxLayoutAlign="end center">
          <div fxFlex="100" fxLayoutAlign="end center" fxLayoutGap="10px">
            <button mat-flat-button type="button" class="btn-purple btn-sm" (click)="onOpenDialogMassUpload()">
              <mat-label>Carga masiva</mat-label>
              <mat-icon>upload_file</mat-icon>
            </button>
            <button mat-flat-button type="button" class="btn-purple btn-sm" [routerLink]="CMS_ADMIN_PARTICIPANTS_ROUTE">
              <mat-label>Reporte de avance</mat-label>
              <mat-icon>description</mat-icon>
            </button>
          </div>
        </div>
        <div fxFlexAlign="center" fxLayout="column" fxLayout.md="row">
          <div fxLayout="row" fxLayoutAlign="space-around strech" fxLayout.lt-md="column" fxLayout.md="column" fxFlex.md="100">
            <div class="bg-light-gray" fxLayout.md="column" fxFlex="100">
              <div class="example-container mat-elevation-z2">
                <mat-table #table [dataSource]="dataSourceP">
                  <ng-container matColumnDef="id">
                    <mat-header-cell class="centrado" *matHeaderCellDef>#</mat-header-cell>
                    <mat-cell class="centrado" *matCellDef="let row;let i = index;"> 
                      <span class="mobile-label">#</span>
                      {{i+1}} 
                    </mat-cell>
                  </ng-container>
                  
                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>Nombre</mat-header-cell>
                    <mat-cell *matCellDef="let row"> 
                      <span class="mobile-label">Nombre:</span>
                      {{row.name}} 
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="lastname">
                    <mat-header-cell *matHeaderCellDef>Apellidos</mat-header-cell>
                    <mat-cell *matCellDef="let row"> 
                      <span class="mobile-label">Apellidos:</span>
                      {{row.lastname}} 
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="email">
                    <mat-header-cell *matHeaderCellDef>Correo electrónico</mat-header-cell>
                    <mat-cell *matCellDef="let row"> 
                      <span class="mobile-label">Correo electrónico:</span>
                      {{row.email}} 
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="role">
                    <mat-header-cell *matHeaderCellDef>Rol</mat-header-cell>
                    <mat-cell *matCellDef="let row"> 
                      <span class="mobile-label">Rol:</span>
                      {{row.role}} 
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="active">
                    <mat-header-cell class="centrado" *matHeaderCellDef>Activo</mat-header-cell>
                    <mat-cell class="centrado" *matCellDef="let row">
                      <span class="mobile-label">Activo:</span>
                      <mat-checkbox (change)="activate(row)" [checked]="row.active">
                      </mat-checkbox>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>Acciones</mat-header-cell>
                    <mat-cell *matCellDef="let row" fxLayoutGap="10px">
                      <button mat-mini-fab color="purple" matTooltip="Editar" (click)="openDialogUser(1,row);">
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button mat-mini-fab color="purple" matTooltip="Restablecer contraseña" (click)="openDialogResetPassword(row)">
                        <mat-icon>lock_reset</mat-icon>
                      </button>
                    </mat-cell>
                  </ng-container>
                  
                  <mat-header-row *matHeaderRowDef="displayedColumnsP"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumnsP;"></mat-row>
                </mat-table>
                <mat-paginator #paginatorP [pageIndex]="paginatorIndexP" [length]="totalRowsP" [pageSize]="pageSize" (page)="onChangePaginatorP($event)" showFirstLastButtons></mat-paginator>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <!---->
    <mat-tab label="Facilitadores(as)">
      <ng-template matTabContent>
        <div layout="row" fxLayoutAlign="end center">
          <div fxFlex="100" fxLayoutAlign="end center" fxLayoutGap="10px">
            <button mat-button class="btn-purple btn-sm" (click)="openDialogUser(2)">
              <mat-label>Crear facilitador</mat-label>
              <mat-icon>add</mat-icon>
            </button>
            <button mat-button class="btn-purple btn-sm" (click)="navigateTo(CMS_ASSIGN_COURSES_ROUTE)">
              <mat-label>Asignar cursos</mat-label>
              <mat-icon>library_add</mat-icon>
            </button>
          </div>
        </div>
        <div fxFlexAlign="center" fxLayout="column" fxLayout.md="row">
          <div fxLayout="row" fxLayoutAlign="space-around strech" fxLayout.lt-md="column" fxLayout.md="column" fxFlex.md="100">
            <div class="bg-light-gray" fxLayout.md="column" fxFlex="100">
              <div class="example-container mat-elevation-z2">
                <mat-table #table [dataSource]="dataSourceF">
                  <ng-container matColumnDef="id">
                    <mat-header-cell class="centrado" *matHeaderCellDef>#</mat-header-cell>
                    <mat-cell class="centrado" *matCellDef="let row;let i = index;"> 
                      <span class="mobile-label">#</span>
                      {{i+1}} 
                    </mat-cell>
                  </ng-container>
                  
                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>Nombre</mat-header-cell>
                    <mat-cell *matCellDef="let row"> 
                      <span class="mobile-label">Nombre:</span>
                      {{row.name}} 
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="lastname">
                    <mat-header-cell *matHeaderCellDef>Apellidos</mat-header-cell>
                    <mat-cell *matCellDef="let row"> 
                      <span class="mobile-label">Apellidos:</span>
                      {{row.lastname}} 
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="email">
                    <mat-header-cell *matHeaderCellDef>Correo electrónico</mat-header-cell>
                    <mat-cell *matCellDef="let row"> 
                      <span class="mobile-label">Correo electrónico:</span>
                      {{row.email}} 
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="role">
                    <mat-header-cell *matHeaderCellDef>Rol</mat-header-cell>
                    <mat-cell *matCellDef="let row"> 
                      <span class="mobile-label">Rol:</span>
                      {{row.role}} 
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="active">
                    <mat-header-cell class="centrado" *matHeaderCellDef>Activo</mat-header-cell>
                    <mat-cell class="centrado" *matCellDef="let row">
                      <span class="mobile-label">Activo:</span>
                      <mat-checkbox (change)="activate(row)" [checked]="row.active">
                      </mat-checkbox>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>Acciones</mat-header-cell>
                    <mat-cell *matCellDef="let row" fxLayoutGap="10px">
                      <button mat-mini-fab color="purple" matTooltip="Editar" (click)="openDialogUser(2,row);">
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button mat-mini-fab color="purple" matTooltip="Restablecer contraseña" (click)="openDialogResetPassword(row)">
                        <mat-icon>lock_reset</mat-icon>
                      </button>
                    </mat-cell>
                  </ng-container>
                  
                  <mat-header-row *matHeaderRowDef="displayedColumnsF"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumnsF;"></mat-row>
                </mat-table>
                <mat-paginator #paginatorF [pageIndex]="paginatorIndexF" [length]="totalRowsF" [pageSize]="pageSize" (page)="onChangePaginatorF($event)" showFirstLastButtons></mat-paginator>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <!---->
    <mat-tab label="Licenciatarios(as)">
      <ng-template matTabContent>
        <div layout="row" fxLayoutAlign="end center">
          <div fxFlex="100" fxLayoutAlign="end center" fxLayoutGap="10px">
            <button mat-button class="btn-purple btn-sm" (click)="openDialogUser(4)">
              <mat-label>Crear Licenciatario</mat-label>
              <mat-icon>add</mat-icon>
            </button>
            <!--
            <button mat-button class="btn-purple btn-sm" (click)="navigateTo(CMS_ASSIGN_COURSES_ROUTE)">
              <mat-label>Asignar cursos</mat-label>
              <mat-icon>library_add</mat-icon>
            </button>
            -->
          </div>
        </div>
        <div fxFlexAlign="center" fxLayout="column" fxLayout.md="row">
          <div fxLayout="row" fxLayoutAlign="space-around strech" fxLayout.lt-md="column" fxLayout.md="column" fxFlex.md="100">
            <div class="bg-light-gray" fxLayout.md="column" fxFlex="100">
              <div class="example-container mat-elevation-z2">
                <mat-table #table [dataSource]="dataSourceL">
                  <ng-container matColumnDef="id">
                    <mat-header-cell class="centrado" *matHeaderCellDef>#</mat-header-cell>
                    <mat-cell class="centrado" *matCellDef="let row;let i = index;"> 
                      <span class="mobile-label">#</span>
                      {{i+1}} 
                    </mat-cell>
                  </ng-container>
                  
                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>Nombre</mat-header-cell>
                    <mat-cell *matCellDef="let row"> 
                      <span class="mobile-label">Nombre:</span>
                      {{row.name}} 
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="lastname">
                    <mat-header-cell *matHeaderCellDef>Apellidos</mat-header-cell>
                    <mat-cell *matCellDef="let row"> 
                      <span class="mobile-label">Apellidos:</span>
                      {{row.lastname}} 
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="email">
                    <mat-header-cell *matHeaderCellDef>Correo electrónico</mat-header-cell>
                    <mat-cell *matCellDef="let row"> 
                      <span class="mobile-label">Correo electrónico:</span>
                      {{row.email}} 
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="role">
                    <mat-header-cell *matHeaderCellDef>Rol</mat-header-cell>
                    <mat-cell *matCellDef="let row"> 
                      <span class="mobile-label">Rol:</span>
                      {{row.role}} 
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="active">
                    <mat-header-cell class="centrado" *matHeaderCellDef>Activo</mat-header-cell>
                    <mat-cell class="centrado" *matCellDef="let row">
                      <span class="mobile-label">Activo:</span>
                      <mat-checkbox (change)="activate(row)" [checked]="row.active">
                      </mat-checkbox>
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>Acciones</mat-header-cell>
                    <mat-cell *matCellDef="let row" fxLayoutGap="10px">
                      <button mat-mini-fab color="purple" matTooltip="Editar" (click)="openDialogUser(4,row);">
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button mat-mini-fab color="purple" matTooltip="Restablecer contraseña" (click)="openDialogResetPassword(row)">
                        <mat-icon>lock_reset</mat-icon>
                      </button>
                    </mat-cell>
                  </ng-container>
                  
                  <mat-header-row *matHeaderRowDef="displayedColumnsL"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumnsL;"></mat-row>
                </mat-table>
                <mat-paginator #paginatorL [pageIndex]="paginatorIndexL" [length]="totalRowsL" [pageSize]="pageSize" (page)="onChangePaginatorL($event)" showFirstLastButtons></mat-paginator>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
