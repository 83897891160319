<div fxLayout="row" fxLayoutAlign="space-between center" >
    <p mat-dialog-title class="text-purple text-bold" style="margin: 0px;">Códigos de promoción:</p>
    <button mat-icon-button aria-label="close icon" [mat-dialog-close]="false">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-divider style="margin: 10px 0 10px 0;" [inset]="true"></mat-divider>
<mat-dialog-content class="dialog-content">
    <div *ngIf="role.isAdmin()" class="col-md-12 center-button">
        <button mat-button class="btn-green" (click)="create()">
            <mat-label class="m-1">Crear código</mat-label>
            <mat-icon aria-hidden="false">check_circle</mat-icon>
        </button>
    </div>
    <div *ngIf="promotionCodes.length > 0" class="col-md-12">
        <table class="table">
            <thead>
                <tr>
                    <th>Código</th>
                    <th>Veces canjeado</th>
                    <th>Activo</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of promotionCodes">
                    <td >
                        {{ item.code }}
                        <button mat-mini-fab color="purple" matTooltip="Copiar código" (click)="copyMessage(item.code)">
                            <mat-icon>file_copy</mat-icon>
                        </button>
                    </td>
                    <td>
                        {{ item.times_redeemed }}
                    </td>
                    <td>
                        <mat-checkbox #_checkboxActive (change)="activate(item,_checkboxActive)" [checked]="item.active" disabled="{{ !coupon.active || role.isLicensee() || item.times_redeemed >= 1 }}">Activo</mat-checkbox>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</mat-dialog-content>
<mat-divider style="margin: 10px 0 10px 0;" [inset]="true"></mat-divider>
<mat-dialog-actions align="center">
  <button mat-button [mat-dialog-close]="false" class="btn-purple">
    <mat-label class="m-1">Cancelar</mat-label>
    <mat-icon aria-hidden="false">cancel_circle</mat-icon>
  </button>
</mat-dialog-actions>