import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { BaseService, IResponse } from 'app/views/services/base.service';

@Component({
  selector: 'app-admin-participants',
  templateUrl: './admin-participants.component.html',
  styleUrls: ['./admin-participants.component.css']
})
export class AdminParticipantsComponent extends BaseService implements OnInit, AfterViewInit {

  filters:any = {
    courses: []
  };
  pageSize:number = 10;
  displayedColumns: string[] = ["id","name","email","course","topics","progress","lastLogin"];
  dataSource = new MatTableDataSource<any>();
  @ViewChild('paginatorP') paginator: MatPaginator;
  totalRows:number = 0;
  page:number = 0;
  courseId:number = 0;
  filter:any = {};//Filtro elegido

  constructor(injector:Injector) { super(injector); }

  ngOnInit(): void {
    this.getCmsCourseFilters();
    if (this.courseId > 0) this.getCourseProgress();
  }

  ngAfterViewInit(): void {
    if(this.paginator != undefined){
      this.paginator._intl = this.paginatorService.translatePaginator(this.paginator._intl);
      this.dataSource.paginator = this.paginator;
    }
  }

  onFilterChange(filter:any): void {
    this.filter = filter;
    this.courseId = filter.id;
    this.clearTable();
    this.getCourseProgress();
  }

  onPaginationChange(event:any){
    this.getCourseProgress(event.pageIndex,event.pageSize);
  }

  clearTable(): void {
    this.dataSource.data = [];
    this.totalRows = 0;
    this.page = 0;
    this.dataSource.paginator = this.paginator;
  }

  getCmsCourseFilters(): void {
    this.loader.open();
    this.service.getCmsCourseFilters().subscribe(
      (response) => {
        this.loader.close();
        let body:IResponse = response.body;
        if (!body.success) {
          this.service.openDialog(`No se encontraron datos.`);
          return;
        }
        this.filters = body.data;
        if (this.dataSource.data.length <= 0 && this.page == 0 && this.filters.courses?.length > 0) {
          this.onFilterChange(this.filters?.courses[0]);
        }
      },
      (error:HttpErrorResponse) => {
        this.loader.close();
        this.proccessHttpResponse(error);
      }
    );
  }

  getCourseProgress(page:number=0,pageSize:number=10): void {
    let data = {
      page:page,
      pageSize:pageSize,
      courseId:this.courseId
    };
    this.clearTable();
    this.loader.open();
    this.service.getCourseProgress(data).subscribe(
      (response) => {
        this.loader.close();
        let body:IResponse = response.body;
        if (!body.success) {
          this.service.openDialog(`No se encontraron datos.`);
          return;
        }
        this.dataSource.data = body.data.data;
        this.totalRows = body.data.totalRows;
        this.page = page;
        this.dataSource.paginator = this.paginator;
      },
      (error:HttpErrorResponse) => {
        this.loader.close();
        this.proccessHttpResponse(error);
      }
    );
  }

}
