import { HttpErrorResponse } from '@angular/common/http';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatDrawer } from '@angular/material/sidenav';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabGroup } from '@angular/material/tabs';
import { BaseService, ICreateCodeInscripcion, IResponse } from 'app/views/services/base.service';

@Component({
  selector: 'app-admin-assign-courses',
  templateUrl: './admin-assign-courses.component.html',
  styleUrls: ['./admin-assign-courses.component.css']
})
export class AdminAssignCoursesComponent extends BaseService implements OnInit {

  //Tabla principal
  displayedColumns: string[] = ["id","name","email","role",/* "createdAt", */"actions"];
  dataSource = new MatTableDataSource<any>();
  totalRowsF:number = 0;
  pageSize:number = 10;
  pageSizeCourses:number = 2;
  @ViewChild('paginatorUsers') paginator: MatPaginator;
  paginatorUsersIndex:number = 0;
  //Cursos asignados
  displayedColumnsAssigned: string[] = ["id","course","actions"];
  dataSourceAssigned = new MatTableDataSource<any>();
  @ViewChild('paginatorAssigned') paginatorAssigned: MatPaginator;
  totalRowsAssigned:number = 0;
  paginatorAssignedIndex:number = 0;
  //Cursos disponibles
  displayedColumnsCourses: string[] = ["id","course","actions"];
  dataSourceCourses = new MatTableDataSource<any>();
  @ViewChild('paginatorCourses') paginatorCourses: MatPaginator;
  totalRowsCourses:number = 0;
  paginatorCoursesIndex:number = 0;
  @ViewChild('adminUsersTabs', {static: false}) tab: MatTabGroup;

  constructor(
    injector:Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.getUsers();
  }

  ngAfterViewInit() {
    this.paginator._intl = this.paginatorService.translatePaginator(this.paginator._intl);
    this.dataSource.paginator = this.paginator;
    //
    this.paginatorAssigned._intl = this.paginatorService.translatePaginator(this.paginatorAssigned._intl);
    this.dataSourceAssigned.paginator = this.paginatorAssigned;
    //
    this.paginatorCourses._intl = this.paginatorService.translatePaginator(this.paginatorCourses._intl);
    this.dataSourceCourses.paginator = this.paginatorCourses;
    
  }


  getUsers(page:number=0,pageSize:number=10): void {
    this.loader.open();
    let data = {
      page:page,
      pageSize:pageSize,
      roleId:2
    };
    this.dataSource.data = [];
    this.totalRowsF = 0;
    this.service.getUserByRole(data).then(
      (response) => {
        this.loader.close();
        let body:IResponse = response.body;
        if (!body.success) {
          this.service.openDialog(`No se encontraron datos.`);
          return;
        }
        this.dataSource.data = body.data.data;
        this.totalRowsF = body.data.totalRows;
        this.paginatorUsersIndex = page;
        this.dataSource.paginator = this.paginator;
      }
    ).catch(
      (error:HttpErrorResponse) => {
        this.loader.close();
        this.service.processHttpResponse(error);
      }
    );
  }

  onChangePaginatorF(event:any){
    this.getUsers(event.pageIndex,event.pageSize);
  }

  drawerOpened:boolean = false;
  currUser:any;
  showCourses(drawer:MatDrawer,user:any): void {
    drawer.open();
    this.drawerOpened = true;
    this.currUser = user;
    this.getUserCourses(user.id,0,this.pageSizeCourses);
  }

  hideCourses(drawer:MatDrawer,user:any): void {
    drawer.close();
    this.drawerOpened = false;
    this.currUser = undefined;
  }

  getUserCourses(userId:number,page:number=0,pageSize:number=10,assigned=null): void {
    this.loader.open();
    let data = {
      page:page,
      pageSize:pageSize,
      userId:userId,
      assigned:assigned
    };
    if(assigned==null){
      delete data.assigned;
      this.dataSourceAssigned.data = [];
      this.totalRowsAssigned = 0;
      // cursos sin asignar
      this.dataSourceCourses.data = [];
      this.totalRowsCourses = 0;
    }else{
      if(assigned){
        this.dataSourceAssigned.data = [];
        this.totalRowsAssigned = 0;
      } else {
        this.dataSourceCourses.data = [];
        this.totalRowsCourses = 0;
      }
    }
    this.service.getInstructorCourses(data).then(
      (response) => {
        this.loader.close();
        let body:IResponse = response.body;
        if (!body.success) {
          this.service.openDialog(`No se encontraron datos.`);
          return;
        }

        // si es nulo entonces recargamos ambas
        if(assigned==null){
          // cursos asignados
          this.dataSourceAssigned.data = body.data.assignedCourses.data;
          this.totalRowsAssigned = body.data.assignedCourses.totalRows;
          // cursos sin asignar
          this.dataSourceCourses.data = body.data.unassignedCourses.data;
          this.totalRowsCourses = body.data.unassignedCourses.totalRows;
          this.paginatorAssignedIndex = page;
          this.paginatorCoursesIndex = page;
          this.dataSourceAssigned.paginator = this.paginatorAssigned;
          this.dataSourceCourses.paginator = this.paginatorCourses;
        }else{
          if(assigned){
            this.dataSourceAssigned.data = body.data.data;
            this.totalRowsAssigned = body.data.totalRows;
            this.paginatorAssignedIndex = page;
            this.dataSourceAssigned.paginator = this.paginatorAssigned;
          } else {
            this.dataSourceCourses.data = body.data.data;
            this.totalRowsCourses = body.data.totalRows;
            this.paginatorCoursesIndex = page;
            this.dataSourceCourses.paginator = this.paginatorCourses;
          }
        }
      }
    ).catch(
      (error:HttpErrorResponse) => {
        this.loader.close();
        this.service.processHttpResponse(error);
      }
    );
  }

  onChangePaginatorAssigned(event:any){
    this.getUserCourses(this.currUser.id,event.pageIndex,event.pageSize,1);
  }

  onChangePaginatorCourses(event:any){
    this.getUserCourses(this.currUser.id,event.pageIndex,event.pageSize,0);
  }

  setCourseToInstructor(course:any,active:number){
    let action = active==1 ? 'asignar' : 'eliminar';
    this.openConfirm(`¿Seguro(a) que desea ${action} el curso '${course?.course}' a '${this.currUser?.name}'?`)
    .subscribe((response) => {
        if(response){
          this.loader.open();
          let data = {
            courseId:course.id,
            instructorId:this.currUser.id,
            active:active
          };
          this.service.setCourseToInstructor(data).then(
            (response) => {
              this.loader.close();
              let body:IResponse = response.body;
              this.service.openDialog(body.message);
              this.getUserCourses(this.currUser.id,0,this.pageSizeCourses);
            }
          ).catch(
            (error:HttpErrorResponse) => {
              this.loader.close();
              this.service.processHttpResponse(error);
            }
          );
        }
      },
      (error) => {
        this.service.openDialog(`Error al: ${JSON.stringify(error)}`);
      }
    );
  }

  createCodeInscription(course:any){
    let data:ICreateCodeInscripcion = {
      course: course,
      facilitator: this.currUser,
      isEdit: false
    };
    this.openRegistrationCodeGroups(data).then((response)=>{}).catch(()=>{});
  }

  backToUsersTabFacilitator(){
    this.router.navigate([this.CMS_ADMIN_USERS_ROUTE],{replaceUrl:true}).then(()=>{});
  }
}
