<div style="position: relative; height: 30px;">
  <button style="position: absolute; top: -0.5em; right: -0.5em;" mat-icon-button aria-label="close icon"
    [mat-dialog-close]>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content style="position: relative;" class="wh-100">
  <div class="alert-green">
    <div class="fs-6">Oferta y Demanda</div>
  </div>
  <div class="offer-demand-image sim-card">
    <img [src]="img" alt="">
  </div>
  <div class="d-flex flex-row container-div">
    <div class="d-flex flex-column w-50">
      <div class="offer-demand-info-week sim-card bg-green fx-column">
        <div class="d-flex flex-row">
          <div class="fs-5">Semana</div>
        </div>
        <div class="d-flex flex-row">
          <div class="fs-2 text-bold">{{ week }}</div>
        </div>
        <div class="d-flex flex-row text-center">
          <div class="fs-5">
            {{ WEEK_DESCRIPTION[week] }}
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column w-50 container-div">
      <div class="offer-demand-info-calc sim-card fx-center d-flex flex-column w-100">
        <div class="d-flex flex-row fx-center wh-100 fs-5">Productos</div>
        <div class="d-flex flex-row wh-100 fx-center">
          <div class="d-flex flex-column fx-center">
            <div class="fs-5 quantity w-100">{{productSell}}</div>
          </div>
          <div class="d-flex flex-column">
            <button class="btn-icon" size="small" (click)="sumProduct(1)" [disabled]="week<2">
              <mat-icon>keyboard_arrow_up</mat-icon>
            </button>
            <button class="btn-icon" size="small" (click)="sumProduct(-1)" [disabled]="week<2">
              <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="offer-demand-info-calc sim-card fx-center d-flex flex-column w-100">
        <!-- <ion-row>
          <ion-label>{{ 'OFFER_AND_DEMAND.TOTAL_LABEL' | translate }} ${{ total }}</ion-label>
        </ion-row>
        <ion-row class="w-100">
          <ion-button class="btn-primary w-100" fill="clear" [disabled]="week<2">
            <ion-label>{{ 'OFFER_AND_DEMAND.SELL_BUTTON' | translate }}</ion-label>
          </ion-button>
        </ion-row> -->
        <div class="d-flex flex-row">
          <div class="fs-5">Precio de venta</div>
        </div>
        <div class="d-flex flex-row" *ngFor="let p of prices">
          <div class="fs-5"> ${{p}}</div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>