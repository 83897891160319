import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseService, ICreateCodeInscripcion, IResponse } from 'app/views/services/base.service';

@Component({
  selector: 'app-dialog-code-inscription',
  templateUrl: './dialog-code-inscription.component.html',
  styleUrls: ['./dialog-code-inscription.component.css']
})
export class DialogCodeInscriptionComponent implements OnInit {
  baseService:BaseService;
  public modules:Array<any> = [];
  public countries:Array<any> = [];
  public module:any;
  public codigo:string;
  message:string;
  name:string;
  firstFormGroup:FormGroup;
  secondFormGroup:FormGroup;
  errorMessages:any;

  constructor(
    injector:Injector,
    public dialog: MatDialogRef<DialogCodeInscriptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data:ICreateCodeInscripcion
  ) {
    this.baseService = new BaseService(injector);
    this.name = `${data.facilitator?.name} ${data.facilitator?.lastname}`;
  }

  ngOnInit(): void {
    this.getModules();
    this.createForms();
    this.completeForms();
  }

  createForms(){
    this.firstFormGroup = this.baseService.formBuilder.group({
      facilitatorId: ['', [
        Validators.required
      ]],
      courseId: ['', [
        Validators.required
      ]],
      moduleId: ['', [
        Validators.required
      ]],
      codeSession: ['', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(45),
      ]],
    });
    this.secondFormGroup = this.baseService.formBuilder.group({
      groupName: ['', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(45),
      ]],
      maxCapacity: ['', [
        Validators.required,
        Validators.max(30),
        Validators.pattern("^[0-9]*$"),
      ]],
      countryId: ['', [
        Validators.required
      ]],
    });
    this.errorMessages = {
      moduleId: [
        { type: 'required',  message: '* Seleccione un módulo.' }
      ],
      codeSession: [
        { type: 'required',  message: '* Ingrese un código de inscripción.' },
        { type: 'minlength', message: '* Al menos 3 caracteres.' },
        { type: 'maxlength', message: '* No más de 45 caracteres.' },
      ],
      groupName: [
        { type: 'required',  message: '* Ingrese un nombre del grupo.' },
        { type: 'minlength', message: '* Al menos 3 caracteres.' },
        { type: 'maxlength', message: '* No más de 45 caracteres.' },
      ],
      maxCapacity: [
        { type: 'required',  message: '* Ingrese el límite del grupo.' },
        { type: 'pattern', message: '* Solo permiten números.' },
        { type: 'max', message: '* máximo 30 alumnos.' },
      ],
      countryId: [
        { type: 'required',  message: '* Seleccione un país.' }
      ],
    };
  }

  completeForms(){
    if(this.data?.course?.id){
      this.firstFormGroup.controls.courseId.setValue(this.data.course.id);
    }
    if(this.data?.facilitator?.id){
      this.firstFormGroup.controls.facilitatorId.setValue(this.data.facilitator.id);
    }
  }
  
  completeFormsEdit(){
    if(this.data.isEdit){
      if(this.data.dataCodeSession?.codeSession){
        this.firstFormGroup.controls.codeSession.setValue(this.data.dataCodeSession.codeSession);
      }
      if(this.data.dataCodeSession?.moduleId){
        this.firstFormGroup.controls.moduleId.setValue(this.data.dataCodeSession.moduleId);
      }
      if(this.data.dataCodeSession?.groupName){
        this.secondFormGroup.controls.groupName.setValue(this.data.dataCodeSession.groupName);
      }
      if(this.data.dataCodeSession?.countryId){
        this.secondFormGroup.controls.countryId.setValue(this.data.dataCodeSession.countryId);
      }
      if(this.data.dataCodeSession?.maxCapacity){
        this.secondFormGroup.controls.maxCapacity.setValue(this.data.dataCodeSession.maxCapacity);
      }
    }
  }

  get facilitatorId(): AbstractControl {
    return this.firstFormGroup.controls['facilitatorId'];
  }
  get courseId(): AbstractControl {
    return this.firstFormGroup.controls['courseId'];
  }
  get moduleId(): AbstractControl {
    return this.firstFormGroup.controls['moduleId'];
  }
  get codeSession(): AbstractControl {
    return this.firstFormGroup.controls['codeSession'];
  }
  get groupName(): AbstractControl {
    return this.secondFormGroup.controls['groupName'];
  }
  get maxCapacity(): AbstractControl {
    return this.secondFormGroup.controls['maxCapacity'];
  }
  get countryId(): AbstractControl {
    return this.secondFormGroup.controls['countryId'];
  }

  getModules(){
    this.baseService.loader.open('Obteniendo modulos..');
    this.baseService.service.getSimulatorsModuleByInstructorCourse({ courseId: this.data.course?.id, userId: this.data.facilitator?.id }).then(
      (result:any)=>{
        this.baseService.loader.close();
        if(result.success==true){
          this.modules = result.data.courses;
          this.countries = result.data.countries;
        }else{
          this.modules = [];
          this.countries = [];
          this.baseService.service.openDialog(result.message);
          this.closeDialog();
        }
        setTimeout(() => {
          this.completeFormsEdit();
        }, 100);
      }
    ).catch(
      (error:HttpErrorResponse)=>{
        console.log(error);
        this.baseService.loader.close();
        this.baseService.proccessHttpResponse(error);
      }
    );
  }

  save(){
    this.baseService.openConfirm('¿Seguro(a) que desea crear el código de inscripción?').subscribe(
      (response)=>{
        if(response){
          this.baseService.loader.open();
          let user = this.baseService.storage.getUserInfo();
          let data = {
            ...this.firstFormGroup.value,
            ...this.secondFormGroup.value,
            modifiedBy: user.id
          };
          this.baseService.service.createCodeSession(data).then(
            (result:any)=>{
              let body:IResponse = result.body;
              this.baseService.loader.close();
              this.baseService.service.openDialog(body.message);
              if(body.success){
                this.closeDialog();
              }
            }
          ).catch(
            (error:HttpErrorResponse)=>{
              this.baseService.loader.close();
              this.baseService.proccessHttpResponse(error);
            }
          );
        }
      }
    )
  }
  
  edit(){
    this.baseService.openConfirm('¿Seguro(a) que desea editar el código de inscripción?').subscribe(
      (response)=>{
        if(response){
          this.baseService.loader.open();
          let user = this.baseService.storage.getUserInfo();
          let data = {
            facilitatorId:this.firstFormGroup.value.facilitatorId,
            courseId:this.firstFormGroup.value.courseId,
            moduleId:this.firstFormGroup.value.moduleId,
            codeSession:this.firstFormGroup.value.codeSession,
            countryId:this.secondFormGroup.value.countryId,
            groupName:this.secondFormGroup.value.groupName,
            maxCapacity:this.secondFormGroup.value.maxCapacity,
            modifiedBy: user.id,
            id: this.data.dataCodeSession.id
          };
          this.baseService.service.updateCodeSession(data).then(
            (result:any)=>{
              let body:IResponse = result.body;
              this.baseService.loader.close();
              this.baseService.service.openDialog(body.message);
              if(body.success){
                this.closeDialog();
              }
            }
          ).catch(
            (error:HttpErrorResponse)=>{
              this.baseService.loader.close();
              this.baseService.proccessHttpResponse(error);
            }
          );
        }
      }
    )
  }

  closeDialog(): void {
    this.dialog.close(false);
  }
}
