<div fxLayout="row" fxLayoutAlign="space-between center" >
  <p mat-dialog-title class="text-purple text-bold" style="margin: 0px;">{{title}}</p>
  <button mat-icon-button aria-label="close icon" (click)="cerrarDialogo()"  >
      <mat-icon>close</mat-icon>
  </button>
</div>

<form [formGroup]="form" (ngSubmit)="confirmado()">
<div mat-dialog-content class="example-form">
    <div layout="row" layout-xs="column">
        <div flex="">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Ingrese el nombre del curso:</mat-label>
                <input matInput placeholder="Curso" formControlName="course">
            </mat-form-field>
            <small *ngIf="form.controls['course'].hasError('required') && form.controls['course'].touched" class="form-error-msg">              * Requerido.
            </small>
            <small *ngIf="form.controls['course'].hasError('maxlength') && form.controls['course'].touched" class="form-error-msg">
              * Máximo 100 caracteres.
            </small>
        </div>
    </div>
    <div layout="row" layout-xs="column">
      <div flex="">
          <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Ingrese la descripción del curso:</mat-label>
              <textarea matInput placeholder="Descripción" formControlName="description"></textarea>
          </mat-form-field>
          <small *ngIf="form.controls['description'].hasError('required') && form.controls['description'].touched" class="form-error-msg">
            * Requerido.
          </small>
          <small *ngIf="form.controls['description'].hasError('maxlength') && form.controls['description'].touched" class="form-error-msg">
            * Máximo 500 caracteres.
          </small>
      </div>
  </div>
  <div layout="row" layout-xs="column">
    <div flex="">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Elige el tipo de contenido:</mat-label>
        <mat-select formControlName="contentTypeId" [(ngModel)]="contentTypeId">
          <mat-option *ngFor="let type of contentTypes" [value]="type.id">
            {{type.contentType}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <small *ngIf="form.controls['contentTypeId'].hasError('required') && form.controls['contentTypeId'].touched" class="form-error-msg">
        * Requerido.
      </small>        
    </div>
  </div>
    <div layout="row" layout-xs="column">
      <div flex="">
        <mat-checkbox [disabled]="this.contentTypeId == 3" class="example-margin" formControlName="IsCheckedPromo">Admitir promociones para este curso (si no admite promociones ningún alumno podrá aplicar descuentos).</mat-checkbox>
      </div>
    </div>
    <div layout="row" layout-xs="column">
      <div flex="">
          <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Ingrese el precio en USD:</mat-label>
              <input matInput placeholder="300.00" formControlName="salePrice" type="number">
              <span matPrefix>$&nbsp;</span>
            </mat-form-field>
            <small *ngIf="form.controls['salePrice'].hasError('required') && form.controls['salePrice'].touched" class="form-error-msg">
              * Requerido.
            </small>
            <small *ngIf="form.controls['salePrice'].hasError('pattern') && form.controls['salePrice'].touched" class="form-error-msg">
              * Solo número.
            </small>
      </div>
  </div>
    <div layout="row" layout-xs="column">
        <div flex="">
            <mat-checkbox class="example-margin" formControlName="IsCheckedActivo">Activo (Los cursos activos se pueden adquirir o renovar)</mat-checkbox>
        </div>
    </div>
    <div class="row">
      <div class="col-md-12">
          <div class="form-group">
              <div class="row">
                  <div class="col-sm-9" color="primary">
                      <span style="display:none ;">
                      <input type="file" #fileInput ng2FileSelect [uploader]="uploader" (onFileSelected)="onFileSelected($event)" accept=".jpg, .png" />
                      </span>
                      <div class="btn-group" (click)="fileInput.click()">
                        <button type="button" mat-flat-button class="btn-purple">
                          Agregar imagen del curso
                          <mat-icon>drive_folder_upload</mat-icon>  
                        </button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div *ngIf="arrayObjetFile.length > 0" class="col-md-12">
          <table class="table">
              <thead>
                  <tr>
                      <th>Imagen</th>
                      <th>Tamaño</th>
                      <th>Acción</th>
                  </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let item of arrayObjetFile">
                      <td >
                          {{ item.fileName }}
                      </td>
                      <td *ngIf="showFromDB == false">
                              {{ item?.fileSize/1024/1024 | number:'.2' }} MB
                      </td>
                      <td *ngIf="showFromDB == true">
                        {{ data?.fileSize | number:'.2' }} MB
                      </td>
                      <td>
                          <button mat-mini-fab type="button" color="primary"  (click)="deleteFile(item)">
                              <mat-icon>delete</mat-icon>
                          </button>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-flat-button type="button" class="btn-purple" (click)="cerrarDialogo()">
    <mat-label>Cancelar</mat-label>
    <mat-icon>cancel_outline</mat-icon>
  </button>
  <button mat-flat-button type="submit" class="btn-green" [disabled]="form.invalid">
    <mat-label>Crear</mat-label>
    <mat-icon>check_circle_outline</mat-icon>
  </button>
</div>
</form>
  