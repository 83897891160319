import { HttpErrorResponse } from '@angular/common/http';
import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MetaDefinition } from '@angular/platform-browser';
import { ComunicationService } from 'app/comunication.service';
import { egretAnimations } from 'app/shared/animations/egret-animations';
import { DialogShowVideoComponent } from 'app/views/dialogs/dialog-show-video/dialog-show-video.component';
import { AdminService } from 'app/views/services/admin.service';
import { BaseService, IResponse } from 'app/views/services/base.service';
import { API } from 'app/views/shared/api';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [egretAnimations]
})
export class HomeComponent extends BaseService implements OnInit {
  
  constructor(
    public notificationC: ComunicationService,
    public services: AdminService,
    injector:Injector
  ) {
    super(injector);
  }

  public viewMode: string = 'grid-view';//layout config
  
  public currentPageP1:number;//pagination config
  public itemsPerPageP1:number = 3;//pagination config
  public rowsNumberP1:number = 0;//pagination config
  public rowNumberInitP1:number = 1;//pagination config
  tope = 0;
  intervalo;
  activo: boolean = false;
  testimonials:any[] = [];
  courses:any[] = [];

  getImage(url:string) {
    //return this._sanitizer.bypassSecurityTrustStyle(`url(${"img-src 'self' data:;" + (url != "")?API.URL_BASE_STORAGE+url:""})`);
    return API.URL_BASE_STORAGE+url;
  }

  async mensaje() {
    await this.notificationC.setNotifications();
    this.tope++;
  }

  intervalo_(notificationC_) {
    this.intervalo = setInterval(() => { this.mensaje(); }, 600000);
  }

  ngOnInit(): void {
    this.seoService.init(this.seoService.getHomeMetaTags());
    this.analyticsService.trackEvent('Inicio','Página de inicio 4x4','inicio');
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.activo = (userInfo != null && userInfo != undefined);
    if (this.activo) {
      this.notificationC.setNotifications();
      this.intervalo_(this.notificationC);
    }
    this.getHomeInformation();
  }

  getMargin(){
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let activo = (userInfo != null && userInfo != undefined);
    let margin = '';
    if(this.router.url.includes('dashboard')){
      margin = activo ? '0px;' : '192px';
    }else{
      margin = '192px';
    }
    return margin;
  }

  getHomeInformation():void {
    this.services.getHomeInformation().subscribe(
      (response:any) => {
        const body:IResponse = response.body;
        if (!body.success) {
          this.services.openDialog(body.message);
        }
        this.courses = body.data.courses;
        this.testimonials = body.data.testimonials;
      },
      (error:HttpErrorResponse) => {
        this.services.processHttpResponse(error);
      }
    );
  }

  ngAfterViewInit(){
    let tutorialIsDisplayed = localStorage.getItem('tutorialHomeIsDisplayed');
    if(this.role.isClient() && tutorialIsDisplayed!='1'){
      localStorage.setItem('tutorialHomeIsDisplayed','1');
      setTimeout(this.services.showTutorialHomeClient, 100);
    }
  }

  ngOnDestroy() {}

  goTo(url: string) {
    this.router.navigateByUrl(url);
  }

  changePagePaginator1(page:any){
    this.currentPageP1 = Number(page);
    this.rowNumberInitP1 = this.currentPageP1==1 ? 1 : ((this.currentPageP1-1) * this.itemsPerPageP1);
  }

  gotoTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

  form:FormGroup = new FormGroup({
    name:new FormControl('',[
      Validators.required
    ]),
    lastname:new FormControl('',[
      Validators.required
    ]),
    email:new FormControl('',[
      Validators.required,
      Validators.email
    ]),
  });
  onSubmit(): void {
    if (this.form.valid) {
      let data:any = this.form.value;
      let values:string[] = [];
      Object.keys(data).forEach(
        (k) => {
          values.push(`${k}=${data[k]}`);
        }
      );
      let url:string = '/signup?'+values.join('&');
      this.router.navigateByUrl(url,{replaceUrl:true});
    } else {
      this.services.openDialog(`Complete el formulario por favor.`);
    }
  }

  openDialogVideo(src:string) {
    return this.dialog.open(DialogShowVideoComponent,{
      data:{
        video:{
          src:src
        }
      },panelClass:'dialog-video',width:'900px'
    }).afterClosed();
  }

  openDialogTerms():void {
    this.services.openDialogTerms();
  }

  openDialogPrivacy():void {
    this.services.openDialogPrivacy();
  }
}
