import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { API } from 'app/views/shared/api';
import { BaseService, IResponse } from 'app/views/services/base.service';

@Component({
  selector: 'app-study-plan',
  templateUrl: './study-plan.component.html',
  styleUrls: ['./study-plan.component.css']
})
export class StudyPlanComponent extends BaseService implements OnInit,AfterViewInit {
  
  public course:any;
  public modules:any;
  public modifiedAt:string;
  userInfo:any;
  public isLogged:boolean = false;
  panelOpenState = false;
  layoutConf:any = {};
  expanded:boolean = false;

  constructor(
    injector:Injector
  ) { 
    super(injector);
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.isLogged = (userInfo != null && userInfo != undefined);
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      const courseId = String(params.get('id')).trim();
      if (this.isLogged) {
        if (courseId) {
          this.getUserInformation();
          this.getCourse(courseId);
        } else {
          this.service.openDialog('No fue posible obtener la información del curso.');
          this.location.back();
        }
      } else {
        if (courseId.length > 0 && this.validSlugName(courseId)) {
          this.getCourse(courseId);
        } else {
          this.service.openDialog('No fue posible obtener la información del curso.');
          this.location.back();
        }
      }
    });
  }

  ngAfterViewInit(): void {
    this.layoutConf = this.layout.layoutConf;
    if (!this.layoutConf.hasOwnProperty('isMobile')) {
      this.layoutConf.isMobile = window.innerWidth <= 600 ? true : false;
    }
    if (this.layoutConf.isMobile) this.expanded = true;
  }

  getCourse(courseId:string){
    this.loader.open();
    const loader = this.loader.getDialog();
    this.service.getCourse(courseId).subscribe(
      (data) => {
        loader.close();
        if (data.status == 200){
          let content = data.body.content;
          this.course = content.course;
          this.course.imageUrl = (this.course.imageUrl && this.course.imageUrl != '')?API.URL_BASE_STORAGE+this.course.imageUrl:'';
          this.modifiedAt = this.datepipe.transform(this.course?.modifiedAt, 'dd/MMM/yyyy','UTC-6','ES-MX');
          this.modules = content.modules;
          if (this.modules){
            this.modules.forEach(
              (module) => {
                if (module.topics) {
                  module.topics = this.service.sortByOrder(module.topics);
                }
              }
            )
          }
        } else {
          this.service.openDialog('No fue posible obtener la información del curso.');
        }
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        this.service.openDialog("No se encontro información del curso").subscribe(()=>{
          this.service.goBack();
        });
      }
    );
  }

  buyCourse(){
    this.router.navigate(['/dashboard/course/buy/',this.course.courseSlug]);
  }

  userHasLevelrequired(levelRequiredId:number):boolean{
    if(!this.isLogged) return false;
    const user:any = this.service.getUserInfo();
    return (user.userLevelId >= levelRequiredId)?true:false;
  }

  async getUserInformation(){
    this.userInfo = await this.service.getUserInfo();
  }
}
