import { Routes } from '@angular/router';
import { AuthGuard } from './shared/services/auth/auth.guard';
import { ForgotPasswordComponent } from './views/sessions/forgot-password/forgot-password.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { SigninComponent } from './views/sessions/signin/signin.component';
import { HomeComponent } from './views/dashboard/home/home.component';
import { NavigationBarComponent } from './views/dashboard/navigation-bar/navigation-bar.component';
import { CalendarComponent } from './views/dashboard/calendar/calendar.component';
import { CoursesComponent } from './views/dashboard/courses/courses.component';
import { BillingComponent } from './views/dashboard/billing/billing.component';
import { InstructorComponent } from './views/dashboard/instructor/instructor.component';
import { ProfileComponent } from './views/dashboard/users/profile/profile.component';
import { WallComponent } from './views/dashboard/users/wall/wall.component';
import { CourseDetailsComponent } from './views/dashboard/courses/course-details/course-details.component';
import { AdminCoursesComponent } from './views/dashboard/admin-courses/admin-courses.component';
import { ResetPasswordComponent } from './views/sessions/reset-password/reset-password.component';
import { SignupComponent } from './views/sessions/signup/signup.component';
import { AdminModuleComponent } from './views/dashboard/admin-module/admin-module.component';
import { AdminActivityComponent } from './views/dashboard/admin-activity/admin-activity.component';
import { InstructorsComponent } from './views/dashboard/instructors/instructors.component';
import { AdminTopicsComponent } from './views/dashboard/admin-topics/admin-topics.component';
import { AdminTeamComponent } from './views/dashboard/admin-team/admin-team.component';
import { BuyCourseComponent } from './views/dashboard/courses/buy-course/buy-course.component';
import { StudyPlanComponent } from './views/dashboard/courses/study-plan/study-plan.component';
import { AdminPromotionsComponent } from './views/dashboard/admin-promotions/admin-promotions.component';
import { ReportsComponent } from './views/dashboard/reports/reports.component';
import { AdminCoursePupilComponent } from './views/dashboard/admin-course-pupil/admin-course-pupil.component';
import { ChatCourseComponent } from './views/dashboard/chat-course/chat-course.component';
import { GameComponent } from './views/dashboard/game/game.component';
import { GameInstructorComponent } from './views/dashboard/game-instructor/game-instructor.component'
import { SimulatorModule2Component } from './views/dashboard/simulator-module2/simulator-module2.component';
import { SimulatorInstructorModule2Component } from './views/dashboard/simulator-instructor-module2/simulator-instructor-module2.component';
import { SimulatorModule3Component } from './views/dashboard/simulator-module3/simulator-module3.component';
import { SimulatorInstructorModule3Component } from './views/dashboard/simulator-instructor-module3/simulator-instructor-module3.component';
import { ContactUsComponent } from './views/dashboard/contact-us/contact-us.component';
import { AboutComponent } from './views/dashboard/about/about.component';
import { SimulatorModule4Component } from './views/dashboard/simulator-module4/simulator-module4.component';
import { SimulatorInstructorModule4Component } from './views/dashboard/simulator-instructor-module4/simulator-instructor-module4.component';
import { TermsComponent } from './views/legal/terms/terms.component';
import { PrivacyComponent } from './views/legal/privacy/privacy.component';
import { PaymentMethodsComponent } from './views/dashboard/admin-courses/payment-methods/payment-methods.component';
import { CancelComponent } from './views/payments/cancel/cancel.component';
import { ManageStripePricesComponent } from './views/dashboard/admin-courses/manage-stripe-prices/manage-stripe-prices.component';
import { AdminUsersComponent } from './views/dashboard/admin-users/admin-users.component';
import { AdminAssignCoursesComponent } from './views/dashboard/admin-users/admin-assign-courses/admin-assign-courses.component';
import { SalesCodesComponent } from './views/dashboard/sales-codes/sales-codes.component';
import { FacilitatorRegistrationCodesComponent } from './views/dashboard/facilitator-registration-codes/facilitator-registration-codes.component';
import { NotFoundComponent } from './views/errors/not-found/not-found.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { BuyComponent } from './views/payments/subscription/buy/buy.component';
import { DetailsComponent } from './views/dashboard/subscription/details/details.component';
import { AdminParticipantsComponent } from './views/dashboard/admin-users/admin-participants/admin-participants.component';
import { SuccessComponent } from './views/payments/success/success.component';
import { BlogComponent } from './views/dashboard/blog/blog.component';

export const rootRouterConfig: Routes =
[
  {// Session pages
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'signin',
    data: { title: 'Iniciar sesión'},
    component: SigninComponent,
  },
  {
    path: 'forgot',
    data: { title: 'Olvidé contraseña'},
    component: ForgotPasswordComponent,
  },
  {
    path: 'resetPwd',
    data: { title: 'Restablecer contraseña'},
    component: ResetPasswordComponent,
  },
  {
    path: 'signup',
    data: { title: 'Registro'},
    component: SignupComponent,
  },
  {
    path: 'home',
    data: { title: 'Inicio'},
    component: HomeComponent
  },
  {
    path: 'cursos',
    data: { title: 'Cursos'},
    component: CoursesComponent
  },
  {
    path: 'contacto',
    data: { title: 'Contacto'},
    component: ContactUsComponent,
  },
  {
    path: 'terms',
    data: { title: 'Términos'},
    component: TermsComponent,
  },
  {
    path: 'privacy',
    data: { title: 'Política'},
    component: PrivacyComponent,
  },
  {
    path: 'somos',
    data: { title: 'Somos'},
    component: AboutComponent,
  },
  {
    path: 'payments/cancel',
    data: { title: 'Cancelación de pago'},
    component: CancelComponent,
  },
  {
    path: 'payments/success',
    data: { title: 'Pago realizado'},
    component: SuccessComponent,
  },
  {
    path: 'blog',
    data: { title: 'Blog'},
    component: BlogComponent,
  },
  {
    //Dashboard pages
    path: 'dashboard',
    canActivate: [AuthGuard],
    component: DashboardComponent,
    children: [
      {
        path: 'terms',
        data: { title: 'Términos'},
        component: TermsComponent,
      },
      {
        path: 'privacy',
        data: { title: 'Política'},
        component: PrivacyComponent,
      },
      {
        path: 'home',
        data: { title: 'Inicio'},
        component: HomeComponent
      },
      {//Lista todas las actividades
        path: 'calendar',
        data: { title: 'Calendario'},
        component: CalendarComponent
      },
      {//Lista todas las actividades por Curso
        path: 'calendar/:id',
        data: { title: 'Calendario'},
        component: CalendarComponent
      },
      {
        path: 'cursos',
        data: { title: 'Cursos'},
        component: CoursesComponent
      },
      {
        path: 'course/buy/:id',
        data: { title: 'Comprar curso'},
        component: BuyCourseComponent
      },
      {
        path: 'studyPlan/:id',
        data: { title: 'Plan de estudio'},
        component: StudyPlanComponent
      },
      {
        path: 'billing',
        data: { title: 'Facturación'},
        component: BillingComponent
      },
      {
        path: 'instructors',
        data: { title: 'Instructores'},
        component: InstructorsComponent
      },
      {
        path: 'instructor/:id',
        data: { title: 'Instructor'},
        component: InstructorComponent
      },
      {
        path: 'profile',
        data: { title: 'Perfil'},
        component: ProfileComponent
      },
      {
        path: 'wall',
        data: { title: 'Mi Muro'},
        component: WallComponent
      },
      {
        path: 'cursos/:id',
        data: { title: 'Curso Detalles'},
        component: CourseDetailsComponent
      },
      {
        path: 'cmsCourses',
        data: { title: 'Cursos'},
        component: AdminCoursesComponent
      },
      {
        path: 'cmsModule/:id',
        data: { title: 'Administración de módulos'},
        component: AdminModuleComponent
      },
      {
        path: 'cmsActivity/:id',
        data: { title: 'Administración de Actividades'},
        component: AdminActivityComponent
      },
      {
        path: 'cmsTopic/:id/:courseId',
        data: { title: 'Administración de Temas'},
        component: AdminTopicsComponent
      },
      {
        path: 'cmsTeam/:id',
        data: { title: 'Administración de Equipos'},
        component: AdminTeamComponent
      },
      {
        path: 'cmsAlumnos/:id',
        data: { title: 'Alumnos inscritos por curso'},
        component: AdminCoursePupilComponent
      },
      {
        path: 'cmsPromotions',
        data: { title: 'Administración de Promociones'},
        component: AdminPromotionsComponent
      },
      {
        path: 'chatCourse/:id/:courseId',
        data: { title: 'Chat curso'},
        component: ChatCourseComponent
      },
      {
        path: 'Reports',
        data: { title: 'Reportes'},
        component: ReportsComponent
      },
      {
        path: 'emprendiendodesdecasa',//Simulador modulo 1
        data: { title: 'Juego'},
        component: GameComponent
      },
      {
        path: 'facilitador/emprendiendodesdecasa',
        data: { title: 'Juego Instructor'},
        component: GameInstructorComponent
      },
      {
        path: 'ofertaydemanda',//Simulador modulo 2
        data: { title: 'Juego'},
        component: SimulatorModule2Component
      },
      {
        path: 'facilitador/ofertaydemanda',
        data: { title: 'Juego Instructor'},
        component: SimulatorInstructorModule2Component
      },
      {
        path: 'estrategiasdeaccesoamercados',//Simulador modulo 3
        data: { title: 'Juego'},
        component: SimulatorModule3Component
      },
      {
        path: 'facilitador/estrategiasdeaccesoamercados',
        data: { title: 'Juego Instructor'},
        component: SimulatorInstructorModule3Component
      },
      {
        path: 'operandoenmercadosdiversos',//simulador modulo 4
        data: { title: 'Juego'},
        component: SimulatorModule4Component
      },
      {
        path: 'facilitador/operandoenmercadosdiversos',
        data: { title: 'Juego Instructor'},
        component: SimulatorInstructorModule4Component
      },
      {
        path: 'cms/courses/payments/methods/:id',
        data: { title: 'Administración de Pagos'},
        component: PaymentMethodsComponent
      },
      {
        path: 'cms/courses/stripe/prices',
        data: { title: 'Administración de Precios'},
        component: ManageStripePricesComponent
      },
      {
        path: 'cms/users',
        data: { title: 'Administración de usuarios(as)'},
        component: AdminUsersComponent
      },
      {
        path: 'cms/users/courses',
        data: { title: 'Administración de cursos'},
        component: AdminAssignCoursesComponent
      },
      {
        path: 'cms/users/participants',
        data: { title: 'Administración de participantes'},
        component: AdminParticipantsComponent
      },
      {
        path: 'sales-codes',
        data: { title: 'Cupones'},
        component: SalesCodesComponent
      },
      {
        path: 'registration-code',
        data: { title: 'Códigos de inscripción a simulador'},
        component: FacilitatorRegistrationCodesComponent
      },
      {
        path: 'subscription/details',
        data: { title: 'Detalles de suscripción'},
        component: DetailsComponent
      },
    ]
  },
  {
    path: 'subscription/buy',
    data: { title: 'Comprar suscripción'},
    canActivate: [AuthGuard],
    component: BuyComponent
  },
  {
    path: 'studyPlan/:id',
    data: { title: 'Plan de Estudios'},
    component: StudyPlanComponent

  },
  //nav-bar
  {
    path: 'navigationBar',
    component: NavigationBarComponent
  },
  {
    path: '',
    component:AuthLayoutComponent,
    children: [
      {
        path: 'notfound',
        component: NotFoundComponent
      },
    ]
  },
  {
    path: '**',
    redirectTo: 'notfound'
  },
  
];

