<div fxLayout="row" fxLayoutAlign="space-between center" >
  <p mat-dialog-title class="text-purple text-bold" style="margin: 0px;">{{actionId == 1 ? 'Crear' : 'Información del'}} cupón:</p>
  <button mat-icon-button aria-label="close icon" [mat-dialog-close]="false">
      <mat-icon>close</mat-icon>
  </button>
</div>
<mat-divider style="margin: 10px 0 10px 0;" [inset]="true"></mat-divider>
<mat-dialog-content class="dialog-content">
  <form [formGroup]="form">
    <div fxFlex="100" fxLayout="column" fxLayoutAlign="end center" fxLayoutGap="10px">
      <div fxFlex="33" fxLayoutAlign="center center" fxLayoutGap="10px">
        <div fxFlex="100" fxLayoutAlign="center center">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Nombre</mat-label>
            <mat-icon matPrefix>person</mat-icon>
            <input (input)="name.markAsTouched()" matInput formControlName="name" required=""/>
            <mat-hint>
              <ng-container *ngFor="let error of errorMessages.name">
                <ng-container *ngIf="baseService.formControlHasError(name,error)">
                  <small class="form-error">{{error.message}}</small>
                </ng-container>
              </ng-container>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
      <div fxFlex="33" fxLayoutAlign="center center" fxLayoutGap="10px">

        <div fxFlex="50" fxLayoutAlign="center center">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Duración</mat-label>
            <mat-icon matPrefix>alarm</mat-icon>
            <mat-select formControlName="duration" (selectionChange)="onFilterChange({id:$event.value,filterType:1})">
              <mat-option [value]="1">Permanente</mat-option>
              <mat-option [value]="2">Solo una vez</mat-option>
              <mat-option [value]="3">Por meses</mat-option>
            </mat-select>
            <mat-hint>
              <ng-container *ngFor="let error of errorMessages.duration">
                <ng-container *ngIf="baseService.formControlHasError(duration,error)">
                  <small class="form-error">{{error.message}}</small>
                </ng-container>
              </ng-container>
            </mat-hint>
          </mat-form-field>
        </div>

        <div *ngIf="durationVar != 3" fxFlex="50" fxLayoutAlign="center center"></div>

        <div *ngIf="durationVar == 3" fxFlex="50" fxLayoutAlign="center center">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Meses</mat-label>
            <mat-icon matPrefix>calendar_today</mat-icon>
            <input (input)="duration_in_months.markAsTouched()" matInput formControlName="duration_in_months" required=""/>
            <mat-hint>
              <ng-container *ngFor="let error of errorMessages.duration_in_months">
                <ng-container *ngIf="baseService.formControlHasError(duration_in_months,error)">
                  <small class="form-error">{{error.message}}</small>
                </ng-container>
              </ng-container>
            </mat-hint>
          </mat-form-field>        
        </div>
      </div>

      <div fxFlex="33" fxLayoutAlign="center center" fxLayoutGap="10px">
        <div fxFlex="50" fxLayoutAlign="center center">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Tipo descuento</mat-label>
            <mat-icon matPrefix>shopping_basket</mat-icon>
            <mat-select formControlName="discount_type" (selectionChange)="onFilterChange({id:$event.value,filterType:2})">
              <mat-option [value]="1">Porcentaje</mat-option>
              <mat-option [value]="2">Cantidad</mat-option>
            </mat-select>
            <mat-hint>
              <ng-container *ngFor="let error of errorMessages.discount_type">
                <ng-container *ngIf="baseService.formControlHasError(discount_type,error)">
                  <small class="form-error">{{error.message}}</small>
                </ng-container>
              </ng-container>
            </mat-hint>
          </mat-form-field>
        </div>

        <div *ngIf="!discountTypeVar" fxFlex="50" fxLayoutAlign="center center"></div>

        <div *ngIf="discountTypeVar == 1" fxFlex="50" fxLayoutAlign="center center">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Porcentaje</mat-label>
            <mat-icon matPrefix>percent</mat-icon>
            <input (input)="percent_off.markAsTouched()" matInput formControlName="percent_off" required=""/>
            <mat-hint>
              <ng-container *ngFor="let error of errorMessages.percent_off">
                <ng-container *ngIf="baseService.formControlHasError(percent_off,error)">
                  <small class="form-error">{{error.message}}</small>
                </ng-container>
              </ng-container>
            </mat-hint>
          </mat-form-field>        
        </div>

        <div *ngIf="discountTypeVar == 2" fxFlex="50" fxLayoutAlign="center center">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Cantidad</mat-label>
            <mat-icon matPrefix>payments</mat-icon>
            <input (input)="amount_off.markAsTouched()" matInput formControlName="amount_off" required=""/>
            <mat-hint>
              <ng-container *ngFor="let error of errorMessages.amount_off">
                <ng-container *ngIf="baseService.formControlHasError(amount_off,error)">
                  <small class="form-error">{{error.message}}</small>
                </ng-container>
              </ng-container>
            </mat-hint>
          </mat-form-field>        
        </div>
      </div>

      <div fxFlex="33" fxLayoutAlign="center center" fxLayoutGap="10px">
        <div fxFlex="50" fxLayoutAlign="center center">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Tipo de canjeos</mat-label>
            <mat-icon matPrefix>supervisor_account</mat-icon>
            <mat-select formControlName="redemption_type" (selectionChange)="onFilterChange({id:$event.value,filterType:4})">
              <mat-option [value]="1">Limitado</mat-option>
              <mat-option [value]="2">Ilimitado</mat-option>
            </mat-select>
            <mat-hint>
              <ng-container *ngFor="let error of errorMessages.redemption_type">
                <ng-container *ngIf="baseService.formControlHasError(redemption_type,error)">
                  <small class="form-error">{{error.message}}</small>
                </ng-container>
              </ng-container>
            </mat-hint>
          </mat-form-field>
        </div>

        <div *ngIf="redemptionTypeVar != 1" fxFlex="50" fxLayoutAlign="center center"></div>

        <div *ngIf="redemptionTypeVar == 1" fxFlex="50" fxLayoutAlign="center center">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Máximo canjeos</mat-label>
            <mat-icon matPrefix>person</mat-icon>
            <input (input)="max_redemptions.markAsTouched()" matInput formControlName="max_redemptions" required=""/>
            <mat-hint>
              <ng-container *ngFor="let error of errorMessages.max_redemptions">
                <ng-container *ngIf="baseService.formControlHasError(max_redemptions,error)">
                  <small class="form-error">{{error.message}}</small>
                </ng-container>
              </ng-container>
            </mat-hint>
          </mat-form-field>        
        </div>
      </div>

      <div fxFlex="33" fxLayoutAlign="center center" fxLayoutGap="10px">
        <div fxFlex="50" fxLayoutAlign="center center">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Licenciatario asignado</mat-label>
            <mat-icon matPrefix>assignment_ind</mat-icon>
            <mat-select formControlName="licensee" (selectionChange)="onFilterChange({id:$event.value,filterType:5})">
              <mat-option *ngFor="let item of licensees" [value]="item.userId">{{item.fullname}}</mat-option>
            </mat-select>
            <mat-hint>
              <ng-container *ngFor="let error of errorMessages.licensee">
                <ng-container *ngIf="baseService.formControlHasError(licensee,error)">
                  <small class="form-error">{{error.message}}</small>
                </ng-container>
              </ng-container>
            </mat-hint>
          </mat-form-field>
        </div>

        <div *ngIf="actionId == fActions.CREATE" fxFlex="50" fxLayoutAlign="center center">
          <mat-form-field appearance="outline" class="full-width">
            <mat-icon matPrefix>assignment_turned_in</mat-icon>
            <mat-label>Cursos que aplican</mat-label>
            <mat-select [formControl]="applies_to" multiple>
              <mat-option *ngFor="let item of courses" [value]="item.stripeId">{{item.course}}</mat-option>
            </mat-select>
            <mat-hint>
              <ng-container *ngFor="let error of errorMessages.applies_to">
                <ng-container *ngIf="baseService.formControlHasError(applies_to,error)">
                  <small class="form-error">{{error.message}}</small>
                </ng-container>
              </ng-container>
            </mat-hint>
          </mat-form-field>
        </div>

        <div *ngIf="actionId == fActions.UPDATE" fxFlex="50" fxLayoutAlign="center center"></div>
      </div>

      <div *ngIf="actionId == fActions.UPDATE" fxFlex="33" fxLayoutAlign="center center" fxLayoutGap="10px">
        <div fxFlex="50">
          <mat-label class="text-purple text-bold f-18">Cursos a los que se aplica</mat-label>
          <p *ngFor="let item of appliesTo">- {{item}}</p>
        </div>
        <div fxFlex="50" fxLayoutAlign="center center"></div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-divider style="margin: 10px 0 10px 0;" [inset]="true"></mat-divider>
<mat-dialog-actions align="center">
  <button mat-button [mat-dialog-close]="false" class="btn-purple">
    <mat-label class="m-1">Cancelar</mat-label>
    <mat-icon aria-hidden="false">cancel_circle</mat-icon>
  </button>
  <button *ngIf="actionId == fActions.CREATE" mat-button class="btn-green" [disabled]="form.invalid" (click)="saveUser()">
    <mat-label class="m-1">Crear</mat-label>
    <mat-icon aria-hidden="false">check_circle</mat-icon>
  </button>
</mat-dialog-actions>