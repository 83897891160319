import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { AdminService } from 'app/views/services/admin.service';
import { BaseService } from 'app/views/services/base.service';

@Component({
  selector: 'app-dialog-reset-password',
  templateUrl: './dialog-reset-password.component.html',
  styleUrls: ['./dialog-reset-password.component.css']
})
export class DialogResetPasswordComponent implements OnInit {

  baseService:BaseService;
  user:any;
  form:FormGroup;

  constructor(
    injector:Injector,
    public dialogRef: MatDialogRef<DialogResetPasswordComponent>,
    private loader: AppLoaderService,
    private service: AdminService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.baseService = new BaseService(injector);
    this.user = this.data.user;
    this.createForm();
  }

  createForm(): void {
    this.form = new FormGroup({
      name: new FormControl({value:'',disabled:true},[
        Validators.required
      ]),
      lastname: new FormControl({value:'',disabled:true},[
        Validators.required
      ]),
      email: new FormControl({value:'',disabled:true},[
        Validators.required
      ]),
      role: new FormControl({value:'',disabled:true},[
        Validators.required
      ]),
      createdAt: new FormControl({value:'',disabled:true},[
        Validators.required
      ]),
      password: new FormControl({value:'',disabled:true},[
        Validators.required
      ]),
    })
  }

  get password():AbstractControl {
    return this.form.controls['password'];
  }

  ngOnInit(): void {
    this.getUser();
  }

  getUser(): void {
    this.loader.open('Obteniendo datos ...');
    this.service.getUserById(this.user.id).subscribe(
      async (data:any) => {
        let resp = data.data;
        this.loader.close();
        if(!resp){
          this.service.openDialog('Error al obtener los datos del usuario');
          this.dialogRef.close();
          return;
        }
        this.form.controls['name'].setValue(resp.name);
        this.form.controls['lastname'].setValue(resp.last_name);
        this.form.controls['email'].setValue(resp.email);
        this.form.controls['createdAt'].setValue(resp.created_at);
        this.form.controls['role'].setValue(resp.role_id == 1 ? 'Participante' : 'Facilitador(a)');
        this.form.controls['password'].setValue(this.baseService.generatePassword());
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.service.openDialog("Error en el Servicio");
      }
    );
  }

  update(){
    this.loader.open('Guardando datos ...');
    let dataSave = {userId:this.user.id, email:this.form.controls["email"].value, password:this.form.controls["password"].value};

    this.service.userResetPassword(dataSave).subscribe(
    async (data:any) => {
        this.loader.close();
        this.service.openDialog(data.message);
        this.dialogRef.close();
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.service.openDialog("Error en el Servicio");
      }
    );
  }
}
