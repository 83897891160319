<div fxLayout="row" fxLayoutAlign="space-between center">
    <p mat-dialog-title class="text-purple text-bold" style="margin: 0px;">Códigos de Inscripción a simulador</p>
    <button mat-icon-button aria-label="close icon" [mat-dialog-close]="true">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div mat-dialog-content fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="end center" class="w-100 mb-1 mt-1">
        <button mat-button class="btn-purple" matTooltip="Crear código" (click)="createCodeInscription()">
            <mat-label>Crear código</mat-label>
        </button>
    </div>
    <mat-table #table [dataSource]="dataSource" class="mat-elevation-z2 mat-table-users">
        <ng-container matColumnDef="id">
            <mat-header-cell class="centrado" *matHeaderCellDef>#</mat-header-cell>
            <mat-cell class="centrado" *matCellDef="let row;let i = index;">
                {{i+1}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="groupName">
            <mat-header-cell *matHeaderCellDef>Grupo</mat-header-cell>
            <mat-cell *matCellDef="let row">
                {{row.groupName}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="codeSession">
            <mat-header-cell *matHeaderCellDef>Código</mat-header-cell>
            <mat-cell *matCellDef="let row">
                {{row.codeSession}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="facilitatorName">
            <mat-header-cell *matHeaderCellDef>Facilitador</mat-header-cell>
            <mat-cell *matCellDef="let row">
                {{row.facilitatorName}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="course">
            <mat-header-cell *matHeaderCellDef>Curso</mat-header-cell>
            <mat-cell *matCellDef="let row">
                {{row.course}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="module">
            <mat-header-cell *matHeaderCellDef>Módulo</mat-header-cell>
            <mat-cell *matCellDef="let row">
                {{row.module}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="createdAt">
            <mat-header-cell *matHeaderCellDef>Fecha de creación</mat-header-cell>
            <mat-cell *matCellDef="let row">
                {{row.createdAt}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef>Acciones</mat-header-cell>
            <mat-cell *matCellDef="let row" fxLayoutGap="10px">
                <button mat-mini-fab color="purple" matTooltip="Editar código" (click)="editCode(row)">
                    <mat-icon>edit</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row [ngClass]="currUser?.id == row.id ? 'mat-row-active' : ''"
            *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <mat-paginator #paginatorUsers [pageIndex]="paginatorIndex" [length]="totalRows" [pageSize]="pageSize"
        (page)="onChangePaginator($event)" showFirstLastButtons></mat-paginator>
</div>