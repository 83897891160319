<div fxLayout="row" fxLayoutAlign="space-between center">
  <p mat-dialog-title class="text-purple text-bold" style="margin: 0px;" >Elige el orden de los recursos del tema:</p>
  <button mat-icon-button aria-label="close icon" [mat-dialog-close]="false"  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
    <div cdkDropList [cdkDropListData]="resources" class="example-list" (cdkDropListDropped)="drop($event)">
        <div class="example-box" *ngFor="let r of resources" [cdkDrag]>
          <span><b>{{getFileExtension(r.fileName)}}</b>&nbsp;|&nbsp;{{r.titulo}}</span>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="btn-outline-light" mat-button [mat-dialog-close]="false">
      <mat-label class="m-1">Cancelar</mat-label>
      <mat-icon aria-hidden="false">cancel</mat-icon>
    </button>
    <button class="btn-outline-light" mat-button (click)="openConfirmUpdateOrder()">
      <mat-label class="m-1">Confirmar</mat-label>
      <mat-icon aria-hidden="false">check_circle</mat-icon>
    </button>
</mat-dialog-actions>