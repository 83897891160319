<div class="container-fluid">
    <div layout="row" fxLayoutAlign="end center">
        <div *ngIf="role.isAdmin()" fxFlex="20" fxLayoutAlign="center center">
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Licenciatario(a):</mat-label>
              <mat-icon matPrefix>person</mat-icon>
              <mat-select (selectionChange)="onFilterChange()" [(ngModel)]="selectedFilter">
                <mat-option [value]="0">Todos</mat-option>
                <mat-option *ngFor="let item of licensees" [value]="item.userId">{{item.fullname}}</mat-option>
              </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex="100" fxLayoutAlign="end center" fxLayoutGap="10px">
            <button *ngIf="role.isAdmin()" mat-button class="btn-purple btn-sm" (click)="openDialogCode()">
                <mat-label>Crear cupón</mat-label>
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </div>
    <div fxFlexAlign="center" fxLayout="column" fxLayout.md="row">
        <div fxLayout="row" fxLayoutAlign="space-around strech" fxLayout.lt-md="column" fxLayout.md="column" fxFlex.md="100">
            <div class="bg-light-gray" fxLayout.md="column" fxFlex="100">
                <div class="example-container mat-elevation-z2">
                    <mat-table #table [dataSource]="dataSourceL">
                        <ng-container matColumnDef="id">
                            <mat-header-cell class="centrado" *matHeaderCellDef>#</mat-header-cell>
                            <mat-cell class="centrado" *matCellDef="let row;let i = index;"> 
                            <span class="mobile-label"></span>
                            {{i+1}} 
                            </mat-cell>
                        </ng-container>
                        
                        <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef>Cupón</mat-header-cell>
                            <mat-cell *matCellDef="let row"> 
                            <span class="mobile-label"></span>
                            {{row.name}} 
                            </mat-cell>
                        </ng-container>
    
                        <ng-container matColumnDef="duration">
                            <mat-header-cell *matHeaderCellDef>Duración</mat-header-cell>
                            <mat-cell *matCellDef="let row"> 
                            <span class="mobile-label"></span>
                            {{row.duration}} 
                            </mat-cell>
                        </ng-container>
    
                        <ng-container matColumnDef="licensee">
                            <mat-header-cell *matHeaderCellDef>Licenciatario</mat-header-cell>
                            <mat-cell *matCellDef="let row"> 
                            <span class="mobile-label"></span>
                            {{row.licensee}} 
                            </mat-cell>
                        </ng-container>
    
                        <ng-container matColumnDef="timesRedeemed">
                            <mat-header-cell *matHeaderCellDef>Veces usado</mat-header-cell>
                            <mat-cell *matCellDef="let row"> 
                            <span class="mobile-label"></span>
                            {{row.timesRedeemed}} 
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="createdAt">
                            <mat-header-cell *matHeaderCellDef>Fecha creación</mat-header-cell>
                            <mat-cell *matCellDef="let row"> 
                            <span class="mobile-label"></span>
                            {{row.createdAt}} 
                            </mat-cell>
                        </ng-container>
    
                        <ng-container matColumnDef="active">
                            <mat-header-cell class="centrado" *matHeaderCellDef>Activo</mat-header-cell>
                            <mat-cell class="centrado" *matCellDef="let row">
                            <span class="mobile-label"></span>
                            <mat-checkbox #_checkboxActive (change)="activate(row,_checkboxActive)" [checked]="row.active" disabled="{{ this.role.isLicensee() }}">
                            </mat-checkbox>
                            </mat-cell>
                        </ng-container>
    
                        <ng-container matColumnDef="actions">
                            <mat-header-cell *matHeaderCellDef>Acciones</mat-header-cell>
                            <mat-cell *matCellDef="let row" fxLayoutGap="10px">
                            <button mat-mini-fab color="purple" matTooltip="Ver detalles" (click)="openDialogCodeDetails(row);">
                                <mat-icon>remove_red_eye</mat-icon>
                            </button>
                            <button mat-mini-fab color="purple" matTooltip="Códigos de promoción" (click)="openDialogPromotionCodes(row);">
                                <mat-icon>local_offer</mat-icon>
                            </button>
                            </mat-cell>
                        </ng-container>
                        
                        <mat-header-row *matHeaderRowDef="displayedColumnsL"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumnsL;"></mat-row>
                    </mat-table>
                    <mat-paginator #paginatorL [pageIndex]="paginatorIndexL" [length]="totalRowsL" [pageSize]="pageSize" (page)="onChangePaginatorL($event)" showFirstLastButtons></mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>