import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogComponent } from 'app/shared/components/dialog/dialog.component';
import { API } from 'app/views/shared/api';
import { DialogConfirmComponent } from 'app/views/dialogs/dialog-confirm/dialog-confirm.component';
import { BaseService, IResponse } from 'app/views/services/base.service';
declare var paypal;
declare var bbva;
@Component({
  selector: 'app-buy-course',
  templateUrl: './buy-course.component.html',
  styleUrls: ['./buy-course.component.css']
})
export class BuyCourseComponent extends BaseService implements OnInit {
  @ViewChild('paypal',{static:true}) paypalElement:ElementRef<HTMLDivElement>;
  @ViewChild('bbva',{static:true}) bbvaElement:ElementRef<HTMLDivElement>;
  @ViewChild('creditCard',{static:true}) creditCard:ElementRef<HTMLDivElement>;
  @ViewChild('pagoDirecto',{static:true}) pagoDirecto:ElementRef<HTMLDivElement>;
  public infoUser:any;
  public course:any;
  public countriesArray:any;
  public selectedDiscount:number = 0;
  public discountIdSelected:number = 0;
  public form: FormGroup;
  public secondForm:FormGroup;
  public paymentMethods:any[] = [];
  public promotions:any;
  public isMentory:boolean=false;
  public isLinear = true;
  public courseId_:any;
  public isPagoDirecto:boolean = false;
  public countryId = 1;
  formArray: FormArray;
  currentCurrency = 20.0543;

  constructor(
    injector:Injector
  ) {
    super(injector);
    this.redirect = '/dashboard/cursos';
    this.createForms();
  }

  createForms(): void {
    this.formArray = this.formBuilder.array([ this.createItem() ])
    this.form = new FormGroup({
      country: new FormControl('',[Validators.required]),//{value: this.infoUser.country, disabled: true}
      paymentMethod: new FormControl('',[]),
      promotion: new FormControl('',[]),
    });
    this.secondForm = new FormGroup({
      paymentMethodId:new FormControl('',[Validators.required])
    });
  }

  openDialogTerms():void {
    this.service.openDialogTerms();
  }

  ngOnInit(): void {
    this.infoUser = JSON.parse(localStorage.getItem('userInfo'));
    //
    this.activatedRoute.paramMap.subscribe(params => {
      const courseId = params.get('id');
      this.courseId_ = courseId;
      this.isMentory = Boolean(params.get('isMentoria') == 'true');
      if (courseId) {
        this.createFormCreditCard();
        this.form.controls.country.disable();
        this.form.controls.country.setValue(this.countryId);
        this.getPurchaseInfo({courseId:courseId, userId: this.infoUser.id, countryId: this.countryId });
      } else {
        this.service.openDialog('No fue posible obtener la información del curso.');
        this.location.back();
      }
    });
  }
  
  getPurchaseInfo(data){
    this.loader.open();
    this.service.getPurchaseInfo(data).subscribe(
      (response:any) => {
        this.loader.close();
        try {
          this.countriesArray = response.data.countries;
          this.promotions = response.data.promotions;
          this.course = response.data.course;
          this.course.imageUrl = (this.course.imageUrl && this.course.imageUrl != '')?API.URL_BASE_STORAGE+this.course.imageUrl:'';
          this.paymentMethods = response.data.paymentMethods;
          this.currentCurrency = response.data.currentExchange[0] === undefined ? this.currentCurrency : response.data.currentExchange[0].value;
          if (this.paymentMethods.length <= 0) {
            this.service.openDialog(`Por el momento no hay métodos de pago disponibles en tu país. Intente más tarde o verifique con el área de soporte. ${this.dateService.getCurrentDateTime()}`)
            .subscribe(
              () => {this.navigateTo(this.redirect)}
            );
          }
          if(this.course?.typeId!=this.ContentTypes.FREE){
            this.showTypePayment();
            this.createPaypalButton();
          }
        } catch (error) {
          this.openDialog(`Ocurrió un error al inicializar la información de pago: ${error.message}. Favor de verificar.`).subscribe(
            () => {this.navigateTo(this.redirect)}
          );
        }
      },
      (error:HttpErrorResponse)=>{
        this.loader.close();
        this.openDialog(`Ocurrió un error al inicializar la información de pago. Favor de verificar.`).subscribe(
          () => {this.navigateTo(this.redirect)}
        );
      },
    );
  }

  createPaypalButton(): void {
    // construccion del botton con la info del producto
    paypal.Buttons({
      style: {
        layout: 'horizontal',
        color: 'blue',
        locale: 'es_ES',
        label: 'pay',
        branding: true
      },
      createOrder: (data,actions)=>{
        return actions.order.create({
          purchase_units: [
            {
              description: 'Compra de Mentoría - ' + this.course.course,
              amount: {
                currency_code: 'USD',
                value: this.calculateTotal()
              }
            }
          ]
        })
      },
      onApprove: async (data,actions)=>{
        this.loader.open('Procesando pedido..');
        const order = await actions.order.capture();
        let transactionId = order.purchase_units[0].payments.captures[0].id;
        if(order.status == 'COMPLETED'){

          if (this.isMentory) {
            this.comprarCursoMentory(1,transactionId,data.facilitatorAccessToken);
          } else {
            this.comprarCurso(1,transactionId,data.facilitatorAccessToken);
          }

        }else{
          if (this.isMentory) {
            this.comprarCursoMentory(3,transactionId,data.facilitatorAccessToken);
          } else {
            this.comprarCurso(3,transactionId,data.facilitatorAccessToken);
          }
          
        }
      },
      onError: (error:any) => {
        this.loader.close();
        this.service.openDialog('Error al intentar pagar con este metodo');
      }
    }).render(this.paypalElement.nativeElement);
  }

  calculateTotal(){
    let total = 0;
    if(this.isMentory){
      return 100;
    }
    this.discountIdSelected = this.form.controls.promotion.value.id;
    this.selectedDiscount = Number((this.form.controls.promotion.value).value || 0);
    let priceCourse = Number(this.course?.salePrice || 0)
    if(priceCourse>this.selectedDiscount){
      total = Number(Number(priceCourse - this.selectedDiscount).toFixed(2));
    }else{
      total = 0
    }

    this.isPagoDirecto = total == 0;

    switch (this.paymentMethodId){
      case 1:
        this.isPagoDirecto ? this.paypalElement.nativeElement.style.display = "none" : this.paypalElement.nativeElement.style.display = "flex";
        break;
      case 2:
        this.isPagoDirecto ? this.bbvaElement.nativeElement.style.display = "none" : this.bbvaElement.nativeElement.style.display = "flex";
        break;
      case 3://PayCode crédito/débito
        this.isPagoDirecto ? this.creditCard.nativeElement.style.display = "none" : this.creditCard.nativeElement.style.display = "flex";
        break;
    }

    this.isPagoDirecto ? this.pagoDirecto.nativeElement.style.display = "flex" : this.pagoDirecto.nativeElement.style.display = "none";

    return Number(total).toFixed(2);
  }

  paymentMethodId:number = 0;
  showTypePayment(paymentMethodId = 0){
    if (paymentMethodId > 0) {
      this.paymentMethodId = paymentMethodId;
      this.secondForm.controls['paymentMethodId'].setValue(this.paymentMethodId);
    }
    switch (this.paymentMethodId){
      case 1:
        this.paypalElement.nativeElement.style.display = "flex";
        this.bbvaElement.nativeElement.style.display = "none";
        this.creditCard.nativeElement.style.display = "none";
        break;
      case 2:
        this.bbvaElement.nativeElement.style.display = "none";
        this.paypalElement.nativeElement.style.display = "none";
        this.creditCard.nativeElement.style.display = "none";
        break;
      case 3://PayCode crédito/débito
        this.bbvaElement.nativeElement.style.display = "none";
        this.paypalElement.nativeElement.style.display = "none";
        this.creditCard.nativeElement.style.display = "flex";
        //this.createFormCreditCard();
        this.getCourseToPay();
        break;
    }
  }

  comprarCurso(transactionStatusId:number=3,transactionId:string='',transactionToken:string='',showLoader:boolean=false){
    if(this.infoUser && this.infoUser.country && this.infoUser.lastName && this.infoUser.name){
      if(showLoader) this.loader.open('Procesando pedido..');
      let data = {
        user_id: this.infoUser.id,
        course_id: this.course.id,
        final_cost: this.calculateTotal(),
        paymenth_method_id: this.form.controls.paymentMethod.value,
        transaction_state_id: transactionStatusId,
        transaction: transactionId,
        transaction_token: transactionToken,
        promotionId: this.discountIdSelected,
        isRenew: (this.course?.isRenew || 0)
      };
      this.service.buyCourse(data).subscribe(
        (data:any)=>{
          this.loader.close();
          this.dialog.open(DialogComponent, {
            data: {
              title: data.message,
              content: data.success ? `Se agregó el curso '${this.course.course}' a tu sección de cursos.` : 'verifique si su pago se proceso y comuniquese con soporte.',
              at: '',
              viewButton: false,
              onlyOk: true
            }
          });
          if(data.success) this.router.navigate(['/dashboard/cursos']);
        },
        (error)=>{
          this.loader.close();
          this.service.openDialog('No se pudo comprar el curso intente mas tarde.');
        }
      )
    }else{
      this.loader.close();
      this.service.openDialog('Complete su información, antes de comprar el curso.').subscribe(
        () => {this.router.navigate(['/dashboard/profile'],{ queryParams: { courseId: this.courseId_, isMentory: this.isMentory } });}
      )
    }
  }

  comprarCursoMentory(transactionStatusId:number=3,transactionId:string='',transactionToken:string='',showLoader:boolean=false){
    if(this.infoUser && this.infoUser.country && this.infoUser.lastName && this.infoUser.name){
      if(showLoader) this.loader.open('Procesando pedido..');
      let data = {
        user_id: this.infoUser.id,
        course_id: this.course.id,
        final_cost: this.calculateTotal(),
        paymenth_method_id: this.form.controls.paymentMethod.value,
        transaction_state_id: transactionStatusId,
        transaction: transactionId,
        transaction_token: transactionToken,
        instructor_id : this.course.instructorId,
        email_instructor: this.course.emailInstructor,
        course_name: this.course.course,
        client_name : String(this.infoUser.name) + String(this.infoUser.lastName),
        client_email: this.infoUser.email
      };
      this.service.buyCourseMentory(data).subscribe(
        (data:any)=>{
          this.loader.close();
          this.dialog.open(DialogComponent, {
            data: {
              title: data.message,
              content: `Se agregó la mentoría del curso '${this.course.course}'.`,
              at: '',
              viewButton: false,
              onlyOk: true
            }
          });
          this.router.navigate(['/dashboard/cursos']);
        },
        (error)=>{
          this.loader.close();
          this.service.openDialog('No se pudo comprar la mentoría del curso, intente mas tarde.');
        }
      )
    }else{
      this.service.openDialog('Complete su información, antes de comprar el la mentoría del curso.');
      this.router.navigate(['/dashboard/profile'],{ queryParams: { courseId: this.courseId_, isMentory: this.isMentory } });
    }
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      pais_: '',
      metodospago_: ''
    });
  }

  formCreditCard:FormGroup = new FormGroup({});
  createFormCreditCard(): void {
    this.formCreditCard = new FormGroup({
      cardNumber: new FormControl('',[
        Validators.required,
        Validators.minLength(19),//Por los espacios
        Validators.maxLength(19),//Por los espacios
        Validators.pattern(/^(?:[0-9 ]+$)/)
      ]),
      secCode: new FormControl('',[
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(3),
        Validators.pattern(/^(?:[0-9]+$)/)
      ]),
      expirationDate: new FormControl('',[
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(5)
      ]),
      expMonth: new FormControl('',[]),
      expYear: new FormControl('',[]),
      cardholderName: new FormControl('',[
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(80)
      ]),
      numCel: new FormControl('',[
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern(/^(?:[0-9]+$)/)
      ]),
      firstName: new FormControl('',[
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(50)
      ]),
      paternalSurname: new FormControl('',[
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50)
      ]),
      maternalSurname: new FormControl('',[
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(50)
      ]),
      concept: new FormControl({value:this.course?.course},[
        Validators.required
      ]),
      currency: new FormControl('',[]),
      courseId: new FormControl('',[]),
      conceptId: new FormControl('',[]),
      paymentMethodId: new FormControl('',[]),
    });
  }

  @ViewChild('ccNumber') ccNumberField: ElementRef;
  creditCardNumberSpacing() {
    this.formCreditCard.controls['cardNumber'].markAsTouched();
    const input = this.ccNumberField.nativeElement;
    const { selectionStart } = input;
    const { cardNumber } = this.formCreditCard.controls;

    let trimmedCardNum = cardNumber.value.replace(/\s+/g, '');

    

    if (trimmedCardNum.length > 16) {
      trimmedCardNum = trimmedCardNum.substr(0, 16);
    }

     /* Handle American Express 4-6-5 spacing */
    const partitions = trimmedCardNum.startsWith('34') || trimmedCardNum.startsWith('37') 
                       ? [4,6,5] 
                       : [4,4,4,4];

    const numbers = [];
    let position = 0;
    partitions.forEach(partition => {
      const part = trimmedCardNum.substr(position, partition);
      if (part) numbers.push(part);
      position += partition;
    })

    cardNumber.setValue(numbers.join(' '));

    /* Handle caret position if user edits the number later */
    if (selectionStart < cardNumber.value.length - 1) {
      input.setSelectionRange(selectionStart, selectionStart, 'none');
    }
  }

  setCardHolderName(controlName:string) {
    const control: AbstractControl = this.formCreditCard.controls[controlName];
    control.markAsTouched();
    let value:string = control.value;
    control.setValue(value.toUpperCase());
    let cardholderNameControl:AbstractControl = this.formCreditCard.controls['cardholderName'];
    cardholderNameControl.markAsTouched();
    cardholderNameControl.setValue(`${this.formCreditCard.controls['firstName'].value} ${this.formCreditCard.controls['paternalSurname'].value} ${this.formCreditCard.controls['maternalSurname'].value}`);
  }

  openConfirmDialog() {
    if(this.isPagoDirecto)
    {
      this.createDirectPayment();
    }
    else
    {
      if (this.isMentory == true) {
        this.service.openDialog(`La compra de mentoría no está habilitada para éste método de pago, verifique con el área de soporte ${this.dateService.getCurrentDateTime()}`);
      } else {
        let data:any = this.preparePayment(this.formCreditCard.value);
        //console.log("pago", this.form.controls.country.value);
        let moneda:string = this.form.controls.country.value == 1 ? 'MXN':'CLP';

        let condCambio:string = this.form.controls.country.value == 1 ? `- Precio ${moneda}: <b>$${data.amountConvert} ${moneda}</b><br/>` : ``;
        let condCambio1:string = data.applyPromotion ? this.form.controls.country.value == 1 ? `- Pagas con descuento: <b>$${data.finalAmountConvert} ${moneda}</b><br/>` : ``: ``;
        let condCambio2:string = this.form.controls.country.value == 1 ? `- Tipo de cambio al momento: <b>$${this.currentCurrency} ${moneda}</b><br/>` : ``;
        let condCambio3:string = data.applyPromotion ? `- Pagas con descuento: <b>$${data.finalAmount} USD</b><br/>` : ``;
  
        let msg:string = `
        <h3><b>¿Seguro(a) que desea confirmar el pago?</b></h3>
        <br/>
        <span class="color-purple"><b><i>Resumen del pedido:</i></b></span>
        <br/>
        - Concepto: <b>${data.concept}</b><br/>
        - Precio USD: <b>$${data.amount} USD</b><br/>
        ${condCambio3}
        ${condCambio}
        ${condCambio1}
        ${condCambio2}
        - Aplica promoción: <b>${(data.applyPromotion)?'Si':'No'}</b><br/>
        <br/>
        <span class="color-purple"><b><i>Datos de la tarjeta:</i></b></span>
        <br/>
        - Tarjeta: <b>${data.cardNumber}</b><br/>
        - Titular: <b>${data.cardholderName}</b><br/>
        - Código de seguridad: <b>${data.secCode}</b><br/>
        - Expira: <b>${data.expMonth}/${data.expYear}</b> MES/AÑO<br/>
        - Teléfono: <b>${data.numCel}</b>
        </div>
        `;
  
        this.dialog.open(DialogConfirmComponent,{
          data:{
            message:msg,
            confirm:true}
        }).afterClosed().subscribe(
          (response) => {
            if (response == true) {
              this.createCardPayment(data);
            } else {
              this.service.openDialog(`Compra no realizada`);
            }
          }
        );
      }
    }
  }

  preparePayment(form:any):any {
    let data = form;
    data.concept = this.course?.course;
    data.conceptId = 1;
    data.courseId  = this.course?.id;
    data.promotion = this.form.controls.promotion.value;
    data.course = {
      courseId:this.course?.id,
      course:this.course?.course,
      salePrice:this.course?.salePrice,
      courseLevelId:this.course?.courseLevelId,
      applyPromotion:this.course?.applyPromotion,
      active:this.course?.active,
      instructorId:this.course?.instructorId,
      createdAt:this.course?.createdAt,
      modifiedAt:this.course?.modifiedAt
    };
    data.paymentMethodId = this.paymentMethodId;
    data.applyPromotion = (this.form.controls.promotion.value)?true:false;
    data.cardNumber = String(data.cardNumber).trim().replace(/\s/g,'');
    data.secCode    = String(data.secCode).trim().replace(/\s/g,'');
    let expirationDate:string[] = String(data.expirationDate).trim().replace(/\s/g,'').split('/');
    data.expMonth   = expirationDate[0];
    data.expYear    = expirationDate[1];
    data.cardholderName = String(data.cardholderName).trim();
    data.firstName = String(data.firstName).trim();
    data.paternalSurname = String(data.paternalSurname).trim();
    data.maternalSurname = String(data.maternalSurname).trim();
    data.numCel = String(data.numCel).trim();
    data.concept = String(data.concept).trim();
    data.currency = Number(this.currentCurrency).toFixed(2);
    data.amount   = Number(data.amount).toFixed(2);
    //No mover orden de los montos finales
    data.amount      = this.course?.salePrice;
    data.finalAmount = Number(this.calculateTotal()).toFixed(2);
    //Sólo si el país es Mx se aplica la tasa de cambio
    data.amountConvert      = (this.infoUser.country == 1)?Number(data.amount*this.currentCurrency).toFixed(2):data.amount;
    data.finalAmountConvert = (this.infoUser.country == 1)?Number(data.finalAmount*this.currentCurrency).toFixed(2):data.finalAmount;    
    data.finalAmount        = (this.infoUser.country == 1)?Number(data.finalAmount*this.currentCurrency).toFixed(2):data.finalAmount;
    return data;
  }

  createCardPayment(data):void {
    this.loader.open('Generando compra ...');
    const loader = this.loader.getDialog();
    this.service.createCardPayment(data).subscribe(
      (response:any) => {
        loader.close();
        this.service.openDialog(response.content.message).subscribe(
          () => {
            if (response.content.success == true) {
              this.service.goTo('/dashboard/cursos');
            }
          }
        )
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        switch (http.status) {
          case 401:
            this.service.openDialog(`Error al procesar el servicio: Operación no permitida, intente inicando sesión nuevamente o contacte con el área de soporte. ${this.dateService.getCurrentDateTime()}`);
            break;
          case 404:
            this.service.openDialog(`Error al procesar el servicio: Recurso o servicio no encontrado. Verifique con el área de soporte. ${this.dateService.getCurrentDateTime()}`);
            break;
          case 422:
            this.service.openDialog(`Error al procesar el servicio: Los parámetros enviados en la solicitud son incorrectos. Verifique con el área de soporte. ${this.dateService.getCurrentDateTime()}`);
            break;
          case 500:
            this.service.openDialog(`Error al procesar el servicio: ${http.error?.content}.Intente más tarde o contacte con el área de soporte. ${this.dateService.getCurrentDateTime()}`);
            break;
          default:
            this.service.openDialog(`Error al procesar el servicio: Intente más tarde o contacte con el área de soporte ${this.dateService.getCurrentDateTime()}`);
            break;
        }
      }
    );
  }

  createDirectPayment()
  {
    this.loader.open('Generando compra ...');
    const loader = this.loader.getDialog();

    let data = {
      amount: this.course?.salePrice || 0,
      courseId: this.course?.id,
      conceptId: 1,
      finalAmount: 0,
      paymentMethodId: 0,
      concept: this.course?.course
    };    

    this.service.createDirectPayment(data).subscribe(
      (response:any) => {
        loader.close();
        this.service.openDialog(response.content.message).subscribe(
          () => {
            if (response.content.success == true) {
              this.service.goTo('/dashboard/cursos');
            }
          }
        )
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        console.log("eror", http);
        switch (http.status) {
          case 401:
            this.service.openDialog(`Error al procesar el servicio: Operación no permitida, intente inicando sesión nuevamente o contacte con el área de soporte. ${this.dateService.getCurrentDateTime()}`);
            break;
          case 404:
            this.service.openDialog(`Error al procesar el servicio: Recurso o servicio no encontrado. Verifique con el área de soporte. ${this.dateService.getCurrentDateTime()}`);
            break;
          case 422:
            this.service.openDialog(`Error al procesar el servicio: Los parámetros enviados en la solicitud son incorrectos. Verifique con el área de soporte. ${this.dateService.getCurrentDateTime()}`);
            break;
          case 500:
            this.service.openDialog(`Error al procesar el servicio: ${http.error?.content}.Intente más tarde o contacte con el área de soporte. ${this.dateService.getCurrentDateTime()}`);
            break;
          default:
            this.service.openDialog(`Error al procesar el servicio: Intente más tarde o contacte con el área de soporte ${this.dateService.getCurrentDateTime()}`);
            break;
        }
      }
    );
  }

  handleExpInput(e) {
    let input:string = e.value;
    this.formCreditCard.controls['expirationDate'].markAsTouched();
    if (!/^\d{0,2}\/?\d{0,2}$/.test(input)) {
      e.value = input.slice(0, -1);
    }
    if (/^\d{3,}$/.test(input)) {
      input = input.match(new RegExp('.{1,2}', 'g')).join('/');
      e.value = input;
    }
  }

  setPaymentMethodId(paymentMethodId:number): void {
    this.secondForm.controls['paymentMethodId'].setValue(paymentMethodId);
  }

  resetPaymentMethod(): void {
    this.secondForm.controls['paymentMethodId'].reset('');
  }

  courseToPay:any;
  getCourseToPay(): void {
    this.setPaymentMethodId(this.paymentMethodId);
    let data:any = {
      courseId:this.course.id,
      userId:this.infoUser.id
    };
    this.loader.open();
    this.service.getCourseToPay(data).subscribe(
      (response) => {
        this.loader.close();
        let body:IResponse = response.body;
        if (!body.success) {
          this.openDialog(`${body.message}`);
          return;
        }
        this.courseToPay = body.data.courseToPay;
        this.setPaymentMethodId(this.courseToPay.paymentMethodId);
        this.promotions = body.data.promotions;
      },
      (error:HttpErrorResponse) => {
        this.resetPaymentMethod();
        this.loader.close();
        this.proccessHttpResponse(error);
      }
    );
  }

  createSessionStripeType(){
    if(this.course?.isRenew){
      this.stripeCreateSessionRenew();
    }else{
      this.stripeCreateSession();
    }
  }

  stripeCreateSession(): void {
    this.openConfirm(`¿Seguro que desea continuar con su compra?`).subscribe(
      (response) => {
        if (response) {
          let data:any = {
            payment: {
              courseId:this.course.id,
              userId:this.infoUser.id,
              paymentMethodId:3,
              name:this.course.course,
              productId:this.courseToPay.productId,
              priceId:this.courseToPay.priceId,
              currency:this.courseToPay.currency,
              applyPromotion:false
            }
          };
          //Si se eligió una promoción la sesión de pago se procesará con el priceId de dicha promoción
          if (this.promotion) {
            if (this.promotion.hasOwnProperty('promotionId')) {
              data.payment.promotion = this.promotion;
              data.payment.priceId   = this.promotion.priceId;
              data.payment.applyPromotion = true;
              data.payment.promotionId=this.promotion.promotionId;
            }
          }
          this.loader.open();
          this.service.stripeCreateSession(data).subscribe(
            (response) => {
              this.loader.close();
              let body:IResponse = response.body;
              if (!body.success) {
                this.openDialog(`${body.message}`);
                return;
              }
              this.storage.setCheckoutUrl(body.data.checkoutSession.url);
              this.storage.setUserInfo(this.infoUser);
              window.location.href = body.data.checkoutSession.url;
            },
            (error:HttpErrorResponse) => {
              this.loader.close();
              this.proccessHttpResponse(error);
            }
          );
        }
      }
    );
  }
  
  stripeCreateSessionRenew(): void {
    this.openConfirm(`¿Seguro que desea continuar con su compra y generar una sesión de pago?`).subscribe(
      (response) => {
        if (response) {
          let data:any = {
            payment: {
              courseId:this.course.id,
              userId:this.infoUser.id,
              paymentMethodId:3,
              name:this.course.course,
              productId:this.courseToPay.productIdRenew,
              priceId:this.courseToPay.priceIdRenew,
              currency:this.courseToPay.currency
            }
          };
          //Si se eligió una promoción la sesión de pago se procesará con el priceId de dicha promoción
          if (this.promotion) {
            if (this.promotion.hasOwnProperty('promotionId')) {
              data.payment.promotion = this.promotion;
              data.payment.priceId   = this.promotion.priceId;
              data.payment.applyPromotion = true;
              data.payment.promotionId=this.promotion.promotionId;
            }
          }
          this.loader.open();
          this.service.stripeCreateSessionRenew(data).subscribe(
            (response) => {
              this.loader.close();
              let body:IResponse = response.body;
              if (!body.success) {
                this.openDialog(`${body.message}`);
                return;
              }
              this.storage.setCheckoutUrl(body.data.checkoutSession.url);
              this.storage.setUserInfo(this.infoUser);
              window.location.href = body.data.checkoutSession.url;
            },
            (error:HttpErrorResponse) => {
              this.loader.close();
              this.proccessHttpResponse(error);
            }
          );
        }
      }
    );
  }

  promotion:any;
  setPromotion(promotion): void {
    this.promotion = promotion;
  }

  buyCourseFree(){
    this.openConfirm(`¿Seguro que desea continuar con su compra 'GRATUITA'?`).subscribe(
      (response) => {
        if (response) {
          let data:any = {
            courseId:this.course.id,
            userId:this.infoUser.id,
            concept:this.course.course,
            finalCost:this.course.salePrice
          };
          this.loader.open();
          this.service.buyCourseFree(data)
          .then(
            (response) => {
              this.loader.close();
              let body:IResponse = response.body;
              this.service.openDialog(body.message).subscribe(()=>{
                if (body.success) {
                  this.service.goTo('/dashboard/cursos');
                }
              });
            }
          )
          .catch(
            (error:HttpErrorResponse) => {
              this.loader.close();
              this.service.processHttpResponse(error);
            }
          );
        }
      }
    );
  }
}
