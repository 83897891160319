import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { AdminService } from 'app/views/services/admin.service';
import { BaseService, IResponse } from 'app/views/services/base.service';

@Component({
  selector: 'app-market-access-module3',
  templateUrl: './market-access-module3.component.html',
  styleUrls: ['./market-access-module3.component.css']
})
export class MarketAccessModule3Component implements OnInit {
  baseService:BaseService;
  userInfo:any;
  azuajesAvailable:Array<any> = [];
  azuajesPurchased:Array<any> = [];
  priceBase:Array<any> = [0,190,180,170,160,150,140,125,110,95,80,70];
  productSell:number = 1;
  productMax:number = 11;
  price:number = 0;

  constructor(injector:Injector,@Inject(MAT_DIALOG_DATA) public data:any) {
    this.baseService = new BaseService(injector);
  }

  async ngOnInit() {
    this.userInfo = await this.baseService.storage.getUserInfo();
    let data = {
      game_sessions_id: this.data.game_sessions_id,
      teamId: (this.data.team || 2)
    };
    (await this.baseService.service.getAzuajesByTeam(data)).subscribe({
      next: (response:any)=>{
        console.log('response',response);
        this.azuajesAvailable = response.data.notBought;
        this.azuajesPurchased = response.data.purchased;
        this.calcPriceSell();
      },
      error:(err)=>{
        this.baseService.service.processHttpResponse(err);
      }
    });
  }

  async buyItemAzuaje(azuaje:any){
    if(this.data.infoEquipo != null && azuaje.price > this.data.infoEquipo.gasto){
      await this.baseService.openDialog("No puedes comprar este producto. No tienes suficientes leekels en 'Gastos'");
      return ;
    }
    this.baseService.openConfirm(`¿Desea obtener '${azuaje.nombre}' ${azuaje.price!=0 ? 'con valor de '+azuaje.price+' lekeels' : 'sin costos' }?`).subscribe(
      async (response) => {
        if (response.role == 'confirm') {
          (await this.baseService.service.buyAzuajesByTeam({idAzuaje: azuaje.id, game_sessions_id: this.data.game_sessions_id, teamId: (this.data.team || 2) })).subscribe({
            next: async (response:any)=>{
              let body:IResponse = response.body;
              if(body.success){
                if(azuaje.price > 0){
                  //restando el monto y calculando de nuevo
                  let gastoHastaElMomento = Number(this.data.infoEquipo.gasto) - Number(azuaje.price);
                  this.data.infoEquipo.gasto = gastoHastaElMomento;
                  this.data.infoEquipo.totalLeekels = Number(this.data.infoEquipo.inversion)+Number(this.data.infoEquipo.gasto)+Number(this.data.infoEquipo.ahorro);
                  await this.updateAmountsTeam();
                }
                //Actualizando montos y avisando a todos
                this.azuajesAvailable = body.data.notBought;
                this.azuajesPurchased = body.data.purchased;
                this.calcPriceSell();
              }
              this.baseService.openDialog(body.message);
            },
            error:(err:HttpErrorResponse)=>{
              this.baseService.service.processHttpResponse(err);
            }
          });
        }
      }
    );
  }

  async updateAmountsTeam(){
    (await this.baseService.service.updateMontos(this.data.infoEquipo)).subscribe({
      next: async (response:any)=>{
        localStorage.setItem('infoEquipo',JSON.stringify(this.data.infoEquipo));
        let body:IResponse = response.body;
        this.baseService.openDialog(body.message);
      },
      error: (err:HttpErrorResponse)=>{
        this.baseService.service.processHttpResponse(err);
      }
    });
  }

  async sumProduct(sum:number){
    let count = 0;
    count = this.productSell + (sum);
    if(count <= this.productMax && count >= 1){
      if(count > 18){
        await this.baseService.openDialog(`La cantidad maxima a vender es ${this.productMax}`);
        return
      }
      this.productSell = count;
    } else if(count < 1){
      await this.baseService.openDialog(`La cantidad minima a vender es 1`);
    } else{
      await this.baseService.openDialog(`${this.productMax} es lo maximo que se puede vender`);
    }
    this.calcPriceSell();
  }

  calcPriceSell(){
    let sumValues = this.azuajesPurchased.reduce((prev,curr)=>{ return prev + curr.value },0);
    this.price = this.priceBase[this.productSell] + sumValues;
  }
}