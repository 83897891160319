import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { Appearance, loadStripe, StripeCardElementOptions } from '@stripe/stripe-js';
import { BaseService, IResponse } from 'app/views/services/base.service';

@Component({
  selector: 'app-buy',
  templateUrl: './buy.component.html',
  styleUrls: ['./buy.component.css']
})
export class BuyComponent extends BaseService implements OnInit {
  @ViewChild('subscription-plans-form',{static:true}) formElement:ElementRef<any>;
  userInfo:any;
  subscription:any = {
    name:'',
    description:'',
    currency:'',
    price:'',
  };
  methods:Array<any>;
  paymentSelected:any;
  constructor(injector:Injector) {
    super(injector);
  }

  async ngOnInit() {
    this.loader.open();
    this.userInfo = this.storage.getUserInfo();
    let data = {
      userId: this.userInfo.id
    };
    this.service.validateCustomer(data)
    .then(
      async (response) => {
        this.loader.close();
        let body:IResponse = response.body;
        if (!body.success) {
          this.service.goTo('/dashboard/cursos');
          return;
        }
        // Metodos de pago
        this.methods = body.data.methods;
        // informacion de la suscripción
        this.subscription = body.data.subscription;
        // Configuracion de formulario de pago
        const appearance:Appearance = {
          theme:'stripe'
        };
        const option:StripeCardElementOptions = {
          hidePostalCode: true
        };
        const stripe = await loadStripe(body.data.configs.webKey);
        var elements = stripe.elements({appearance:appearance});
        var cardElement = elements.create('card',option);
        cardElement.mount('#card-element');

        // Agregamos listener al form para obtener el token de la tarjeta
        const form = document.getElementById('subscription-plans-form');
        form.addEventListener('submit',async (e) => {
          e.preventDefault();

          // token crado y se pasa al servicio de api
          const card = await stripe.createToken(cardElement);
          this.loader.open("Procesando suscripción.");
          let params = {
            userId:this.userInfo.id,
            token:card.token.id,
            description:`${card.token.card.brand} ${card.token.card.last4}`
          };
          this.service.buySubscription(params).then(
            (responseBuy)=>{
              this.loader.close();
              let body:IResponse = responseBuy.body;
              this.openDialog(body.message);
              if (body.success) {
                this.service.goTo('/dashboard/cursos');
                return;
              }
            },
            (errBuy:HttpErrorResponse)=>{
              this.loader.close();
              this.service.processHttpResponse(errBuy);
            }
          );
        });
      }
    )
    .catch(
      (err:HttpErrorResponse) => {
        this.loader.close();
        this.service.processHttpResponse(err);
        this.service.goTo('/dashboard/cursos');
      }
    );
  }

  deletePaymentMethod(payment:any){
    this.openConfirm(`¿Desea eliminar el método de pago?`).subscribe(
      (result)=>{
        if(result){
          this.loader.open();
          let data = {
            userId:this.userInfo.id,
            id: payment.relUserPaymentMethodId,
            paymentMethodId: payment.paymentMethodId
          }
          this.service.deletePaymentMethod(data).then(
            (response)=>{
              this.loader.close();
              this.paymentSelected = null;
              let body:IResponse = response.body;
              this.methods = body.data;
              this.openDialog(body.message);
            }
          )
          .catch(
            (err:HttpErrorResponse) => {
              this.loader.close();
              this.service.processHttpResponse(err);
            }
          );
        }
      }
    )
  }

  selectedPayment(payment:any){
    try {
      payment.selected = true;
      this.paymentSelected = payment;
    } catch (error) {
    }
  }

  buySubscriptionWithPaymentSelected(){
    this.openConfirm(`¿Confirma la adquisición de la suscripción?`).subscribe(
      (result)=>{
        if(result){
          this.loader.open("Procesando suscripción.");
          let params = {
            userId:this.userInfo.id,
            paymentMethodId:this.paymentSelected.paymentMethodId
          };
          this.service.buySubscription(params).then(
            (responseBuy)=>{
              this.loader.close();
              let body:IResponse = responseBuy.body;
              this.openDialog(body.message);
              if (body.success) {
                this.service.goTo('/dashboard/cursos');
                return;
              }
            },
            (errBuy:HttpErrorResponse)=>{
              this.loader.close();
              this.service.processHttpResponse(errBuy);
            }
          );
        }
      }
    )
  }
}
