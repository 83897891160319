<div fxFlex="100" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="center center">
        <div fxFlex="80" fxFlex.xs="100" fxLayout="row" fxLayoutAlign="start center" style="margin-left: 10px;">
            <button mat-mini-fab color="purple" focusable="false" (click)="navigateTo(COURSES_AUTH_ROUTE)">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <mat-label style="margin-left: 10px;">Regresar a cursos</mat-label>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
        <div fxFlex="80" fxFlex.xs="100" fxLayout.xs="column" fxLayout="row">
            <div fxFlex="60" fxFlex.xs="100" fxLayout="column">
                <mat-card fxLayout="column">
                    <h3 style="word-break: break-all;">{{subscription?.name}}</h3>
                    <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(subscription?.descriptionLarge)">
                    </div>
                    <div>
                        Los cursos incluidos con la membresía son:
                        <ul *ngIf="subscription?.courses">
                            <li *ngFor="let c of subscription.courses">{{c.course}}</li>
                        </ul>
                    </div>
                </mat-card>
            </div>
            <div fxLayout="column" fxFlex.xs="100" fxFlex="40">
                <mat-card fxLayout="column">
                    <div fxLayout="column" fxLayoutAlign="space-around center" class="m-1">
                        <img 
                            style="width: 320px;height: auto;border-radius: 8px;box-shadow: lightgray 4px 4px 4px 0px;"
                            onerror="this.src='assets/images/entrepeneurs/not-found.png'" [src]="subscription?.imageUrl" alt="" width="auto">
                        <mat-label class="m-3 color-purple"><b>"{{subscription?.name}}"</b></mat-label>
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center center" *ngIf="subscription?.haveMembership==0">
                        <h3>{{subscription?.salePriceLabel}}</h3>
                        <button mat-button class="btn btn-purple" fxFlex="100" (click)="service.navigateTo(SUBSCRIPTION_BUY_ROUTE)">
                            <mat-label>Adquirir membresía anual</mat-label>
                        </button>
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center center" *ngIf="subscription?.haveMembership==1">
                        <h3>Ya cuentas con una membresía</h3>
                        <button mat-button class="btn btn-red" fxFlex="100" (click)="canceledSubscription()">
                            <mat-label>Cancelar suscripción</mat-label>
                        </button>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
    <app-footer fxFlex="100"></app-footer>
</div>
