<!--Start Footer One-->
<footer class="footer-one" [ngStyle]="isLogged ? {'margin-bottom':'13em'} : {}">
  <div class="footer-one__bg" style="background-image: url(assets/new/images/backgrounds/footer-v1-bg.jpg);">
  </div><!-- /.footer-one__bg -->
  <div class="footer-one__top">
      <div class="container">
          <div class="row">
              <!--Start Footer Widget Column-->
              <div class="col-xl-3 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.1s">
                  <div class="footer-widget__column footer-widget__about">
                      <div class="footer-widget__about-logo">
                          <a href="index.html"><img src="assets/new/images/resources/footer-logo.png" alt=""></a>
                      </div>
                  </div>
              </div>
              <!--End Footer Widget Column-->
          

              <!--Start Footer Widget Column-->
              <div class="col-xl-3 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.3s">
                  <div class="footer-widget__column footer-widget__courses">
                      <h3 class="footer-widget__title">Enlaces útiles</h3>
                      <ul class="footer-widget__courses-list list-unstyled">
                          <li><a href="https://www.fundacione.org" target="_blank">Fundación E</a></li>
                          <li><a href="https://4x4emprendedores.com" target="_blank">4x4 Emprendedores</a></li>

                      </ul>
                  </div>
              </div>
              <!--End Footer Widget Column-->

              <!--Start Footer Widget Column-->
              <div class="col-xl-3 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.5s">
                  <div class="footer-widget__column footer-widget__links">
                      <h3 class="footer-widget__title">Legales</h3>
                      <ul class="footer-widget__links-list list-unstyled">
                          <li><small class="footer-link" [routerLink]="['/privacy']">Aviso de privacidad</small></li>
                          <li><small class="footer-link" [routerLink]="['/terms']">Términos y condiciones</small></li>
                      </ul>
                  </div>
              </div>
              <!--End Footer Widget Column-->


              <!--Start Footer Widget Column-->
              <div class="col-xl-3 col-lg-6 col-md-6 col-12 wow animated fadeInUp" data-wow-delay="0.9s">
                  <div class="footer-widget__column footer-widget__social-links">
                      <ul class="footer-widget__social-links-list list-unstyled clearfix">
                          <li><a href="https://www.facebook.com/4x4Emprendedores/" target="_blank"><i class="fab fa-facebook"></i></a></li>
                          <li><a href="https://www.instagram.com/4x4emprendedores/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                          <li><a href="https://www.youtube.com/channel/UCK4nuGlFDZhywzxZdWfNoOw" target="_blank"><i class="fab fa-youtube"></i></a></li>
                          <li><a href="https://www.linkedin.com/company/fundacion-e/" target="_blank"><i class="fab fa-linkedin"></i></a></li>
                      </ul>
                  </div>
              </div>
              <!--End Footer Widget Column-->
          </div>
      </div>
  </div>

  <!--Start Footer One Bottom-->
  <div class="footer-one__bottom">
      <div class="container">
          <div class="row">
              <div class="col-xl-12">
                  <div class="footer-one__bottom-inner">
                      <div class="footer-one__bottom-text text-center">
                          <p class="text-center">
                            &copy; Todos los derechos reservados 2023 - 2033.
                            <br/>Ninguna parte de este material puede ser reproducido, copiado, distribuido o replicado sin el permiso por escrito de Fundación Educación para Emprendedores AC.
                            <br/>My 4x4 Academy es una empresa de Q22 Global SA de CV
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <!--End Footer One Bottom-->
</footer>
<!--End Footer One-->