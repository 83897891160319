import { AfterViewInit, Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatProgressBar } from '@angular/material/progress-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { FacebookLoginProvider, GoogleLoginProvider, SocialUser } from 'angularx-social-login';
import { egretAnimations } from 'app/shared/animations/egret-animations';
import { BaseService } from 'app/views/services/base.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css'],
  animations: [egretAnimations]
})
export class SigninComponent extends BaseService implements OnInit,AfterViewInit {
  layoutConf: any;
  menuItems:any;
  menuItemSub: Subscription;
  egretThemes: any[] = [];
  @Input() notificPanel;
  //
  @ViewChild(MatProgressBar) progressBar: MatProgressBar;
  @ViewChild(MatButton) submitButton: MatButton;
  disableButtonLogin:boolean = false;
  form: FormGroup;
  //social attributes
  user:SocialUser = null;
  loggedIn:boolean = false;
  hide = true;

  constructor(injector:Injector) { super(injector); }

  ngOnInit() {
    this.seoService.setCanonicalURL();
    this.layoutConf = this.layout.layoutConf;
    this.egretThemes = this.themeService.egretThemes;
    this.form = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      password: new FormControl('', Validators.required),
      rememberMe: new FormControl(false)
    });
    this.IsRememberAccount();
    //Social
    this.authService.authState.subscribe(
      (user) => {
        //if (user != null) {
          //this.user = user;
          //this.loggedIn = true;
          //this.signinSocial(user);
        //}
      }
    );
  }

  ngAfterViewInit(){
    let iSLogged = this.authenticationService.isLogged();
    if(iSLogged){
      this.goToHome();
      return;
    }
  }

  signin() {
    //this.submitButton.disabled = true;
    this.disableButtonLogin = true;
    this.progressBar.mode = 'indeterminate';
    const data = this.form.value;
    this.rememberAccount();
    this.service.login(data).subscribe(
      (data:any) => {
        this.progressBar.mode = 'determinate';
        localStorage.removeItem('infoUser');
        localStorage.setItem('infoUser', JSON.stringify(data));
        localStorage.setItem('userInfo', JSON.stringify(data));
        localStorage.setItem('authToken', data.access_token);
        this.storage.setUserInfo(data);
        this.profileInfo.setinfoProfile({ username: data.username, imagenURL: data.imageUrl, userLevelId: data.userLevelId});
        this.authGuard.authToken = data.token;
        this.goToHome();
      },
      (http: HttpErrorResponse) => {
        //console.log(http);
        this.progressBar.mode = 'determinate';
        switch (http.status) {
          case 0:
            this.service.openDialog('No fue posible Iniciar Sesión, servicio no disponible. Intente más tarde o contacte al área de soporte.');
            break;
          case 401:
            this.service.openDialog("No fue posible Iniciar Sesión. "+http.error.message);
            break;
          case 500:
            this.service.openDialog("No fue posible Iniciar Sesión: "+http.error.content);
            break;
        }
        this.disableButtonLogin = false;
        this.form.get('password').reset('');
        localStorage.clear();
      }
    );
  }

  rememberAccount(){
    const signinData = this.form.value;
    localStorage.removeItem('rememberMe');
    if(signinData.rememberMe){
      localStorage.setItem('rememberMe', JSON.stringify({email: signinData.email, password: signinData.password}));
    }
  }

  IsRememberAccount(){
    let account = JSON.parse(localStorage.getItem('rememberMe'));
    if(account && account.hasOwnProperty('email') && account.hasOwnProperty('password')){
      this.form.controls.rememberMe.setValue(true);
      this.form.controls.email.setValue(account.email);
      this.form.controls.password.setValue(account.password);
    }
  }

  public goToHome() {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let url:string = '/dashboard/home';
    switch (userInfo.roleId){
      case 1:
        //this.router.navigate(['/dashboard/home']);
        // window.location.replace(url);
        this.router.navigate([url]).then(()=>window.location.reload());
        break;
      case 2:
      case 3:
      case 4:
        // window.location.replace(url);
        this.router.navigate([url]).then(()=>window.location.reload());
        break;
    }  
  }

  ngOnDestroy() {
    if (this.menuItemSub != undefined)
      this.menuItemSub.unsubscribe();
  }
  
  changeTheme(theme) {
    this.layout.publishLayoutChange({matTheme: theme.name})
  }
  
  toggleNotific() {
    this.notificPanel.toggle();
  }
  
  toggleSidenav() {
    if(this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      })
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    })
  }

  search(){
    
  }

  //Social Methods
  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(
      (user) => {
        if (user != null) {
          this.user = user;
          this.loggedIn = true;
          this.signinSocial(user);
        }
      }
    ).catch(
      () => {
        this.service.openDialog(`Inicio de sesión cancelado por el usuario`);
      }
    );
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(
      (user) => {
        if (user != null) {
          this.user = user;
          this.loggedIn = true;
          this.signinSocial(user);
        }
      }
    ).catch(
      () => {
        this.service.openDialog(`Inicio de sesión cancelado por el usuario`);
      }
    );
  }

  signOutSocial() {
    return this.authService.signOut();
  }

  refreshToken(): void {
    this.authService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
  }

  progressBarOn(): void {
    if (this.submitButton && this.progressBar){
      this.submitButton.disabled = true;
      this.progressBar.mode = 'indeterminate';
    }
  }

  progressBarOff(): void {
    if (this.submitButton && this.progressBar){
      this.submitButton.disabled = false;
      this.progressBar.mode = 'determinate';
    }
  }

  signinSocial(user:SocialUser): void {
    //console.log('INICIAR SESIÓN / REGISTRO CON: ',user);
    this.progressBarOn();
    const data = {
      socialUser:user
    };
    this.rememberAccount();
    this.service.loginSocial(data).subscribe(
      (data:any) => {
        this.progressBarOff();
        let imageProfile:string = data.imageUrl;
        if (this.user) {
          const provider:string = this.user.provider;
          data.provider = provider;
          imageProfile  = this.user.photoUrl;
        }
        localStorage.removeItem('infoUser');
        localStorage.setItem('infoUser', JSON.stringify(data));
        localStorage.setItem('userInfo', JSON.stringify(data));
        localStorage.setItem('authToken', data.access_token);      
        this.profileInfo.setinfoProfile({ 
          username: data.username, 
          imagenURL: imageProfile, 
          userLevelId: data.userLevelId
        });
        this.authGuard.authToken = data.token;
        //para evitar fallos de autenticación de google cerramos la sesion y logueamos en emp44
        this.signOutSocial()
        .then(
          (value) => { 
            this.goToHome();
          }
        )
        .catch(
          (reason) => {
          }
        ).finally(
          () => {
          }
        );
      },
      (http: HttpErrorResponse) => {
        //console.log(http);
        this.progressBarOff();
        switch (http.status) {
          case 0:
            this.service.openDialog('No fue posible Iniciar Sesión, servicio no disponible. Intente más tarde o contacte al área de soporte.');
            break;
          case 401:
            this.service.openDialog("No fue posible Iniciar Sesión. "+http.error.message);
            break;
          case 500:
            this.service.openDialog("No fue posible Iniciar Sesión: "+http.error.message);
            break;
        }
        this.form.get('password').reset('');
        localStorage.clear();
      }
    );
  }
  

}
