import { Component, Injector, OnInit } from '@angular/core';
import { BaseService } from 'app/views/services/base.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent extends BaseService implements OnInit {
  constructor(
    injector: Injector
  ) { 
    super(injector);
  }

  userInfo:any;
  ngOnInit(): void {
    this.userInfo = this.storage.getUserInfo();
    if (this.userInfo) {
      let url:string = this.storage.getCheckoutUrl();
      this.userInfo.checkoutUrl = url;
    }
  }

  openCheckoutUrl(): void {
    window.location.href = this.userInfo.checkoutUrl;
  }
}
