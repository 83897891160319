<div fxFlex="100" fxLayout="row" class="container content-buy">
  <div fxFlex="50" fxLayout="column" fxLayoutAlign="center center" class="card-info-subscription">
    <div class="w-80p" fxLayout="row" fxLayoutAlign="start center">
      <button mat-icon-button (click)="navigateTo(SUBSCRIPTION_DETAILS_ROUTE)" [matTooltip]="'Regresar'">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <mat-label>&nbsp;&nbsp;&nbsp;Regresar</mat-label>
    </div>
    <div class="w-80p mt-3" fxLayout="column" fxLayoutAlign="center start">
      <div class="mt-3 text-info-subscription">Pagar a Fundación E</div>
      <h1 class="mt-3 text-bold title-price">{{subscription.currency}} {{subscription.price}}</h1>
    </div>
    <div class="w-80p mt-3" fxLayout="row" fxLayoutAlign="center start">
      <div fxFlex="50" fxLayout="row">
        {{subscription.name}}
      </div>
      <div fxFlex="50" fxLayout="row" fxLayoutAlign="end center">
        {{subscription.currency}} {{subscription.price}}
      </div>
    </div>
    <hr class="w-80p hr-info-subscription"/>
    <div class="w-80p" fxLayout="row">
      <div fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
        Subtotal
      </div>
      <div fxFlex="50" fxLayout="row" fxLayoutAlign="end center">
        {{subscription.currency}} {{subscription.price}}
      </div>
    </div>
    <hr class="w-80p hr-info-subscription"/>
    <div class="w-80p" fxLayout="row">
      <div fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
        Total adeudado
      </div>
      <div class="text-bold" fxFlex="50" fxLayout="row" fxLayoutAlign="end center">
        {{subscription.currency}} {{subscription.price}}
      </div>
    </div>
    <div class="w-80p text-powered" fxLayout="row" fxLayoutAlign="start center">
      Powered by&nbsp;<b>stripe</b>
    </div>
  </div>
  <div fxFlex="50" fxLayout="column" fxLayoutAlign="center center" class="card-info-payment">
    <h2 class="w-80p text-bold mb-3 text-info-subscription">Tarjetas Guardadas</h2>
    <div *ngFor="let t of methods; let i = index" class="w-80p">
      <mat-card fxLayout="row" fxLayoutAlign="center center" fxLayout="row" (click)="selectedPayment(t)" [ngStyle]="{'background-color':t.selected?'var(--color-light-gray)':'var(--color-white)'}">
        <div fxFlex="80">{{i+1}}.- Tarjeta:  {{t.description}} </div>
        <div fxFlex="20" fxLayoutAlign="end center">
          <button mat-flat-button (click)="deletePaymentMethod(t)" [matTooltip]="'Eliminar'">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-card>
    </div>
    <div class="w-80p" fxLayout="row" [fxShow]="paymentSelected">
      <button fxFlex="50" mat-flat-button class="btn-green" (click)="paymentSelected=null;">Ingresar Tarjeta</button>
      <button fxFlex="50" mat-flat-button class="btn-green" (click)="buySubscriptionWithPaymentSelected()">Suscribirme</button>
    </div>
    <h2 [hidden]="paymentSelected" class="w-80p text-bold mb-3 text-info-subscription">Pago con tarjeta</h2>
    <div [hidden]="paymentSelected" class="w-80p mt-3 text-info-subscription">Información de la tarjeta</div>
    <form [hidden]="paymentSelected" class="w-80p" id="subscription-plans-form" fxLayout="column" style="height: 120px;">
      <div id="card-element" fxFlex="100" fxLayout="column" fxLayoutAlign="stretch space-between">
      </div>
      <button type="submit" mat-flat-button class="btn-green" id="submit">Suscribirme</button>
      <div id="error-message">
      </div>
    </form>
    <div id="messages" role="alert" style="display: none;"></div>
  </div>
</div>