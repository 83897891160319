<p class="f-18" mat-dialog-title class="text-purple text-bold">Carga masiva de participantes:</p>
<mat-divider style="margin: 10px 0 10px 0;" [inset]="true"></mat-divider>
<mat-dialog-content class="dialog-content">
  <div fxFlex="100" fxLayout="column">
    <input #fileInput style="display: none;" type="file" (change)="validateFileContent($event.target.files[0])" accept=".xlsx, .csv" />
    <div fxFlex="100" fxLayout="column" fxLayoutAlign="end center" fxLayoutGap="10px">
      <div fxFlex="100">
        <div *ngIf="dataSource.data.length > 0" fxLayout="column" fxFlex="100" fxLayoutAlign="center center">
          <div fxFlex="100" fxLayoutAlign="space-between center">
            <div fxFlex="30" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Seleccione curso a asignar:</mat-label>
                <mat-select fxFlex matInput [(ngModel)]="courseId">
                    <mat-option *ngFor="let course of courses" [value]="course.id">{{course?.course}}
                    </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxFlex="10" fxLayoutAlign="end center" fxLayoutGap="10px">
              <button mat-mini-fab color="red" matTooltip="Cancelar" (click)="onCancel()">
                <mat-icon>close</mat-icon>
              </button>
              <button mat-mini-fab color="purple" matTooltip="Cargar archivo" (click)="fileInput.click()">
                <mat-icon>file_upload</mat-icon>
              </button>
              <button mat-mini-fab color="green" matTooltip="Crear cuentas" (click)="onConfirm()">
                <mat-icon>check_outline</mat-icon>
              </button>
            </div>
          </div>
          <mat-table #table [dataSource]="dataSource" class="mat-elevation-z2">
            <ng-container matColumnDef="id">
              <mat-header-cell class="centrado" *matHeaderCellDef>#</mat-header-cell>
              <mat-cell class="centrado" *matCellDef="let row;let i = index;"> 
                {{i+1}} 
              </mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef>Nombre</mat-header-cell>
              <mat-cell *matCellDef="let row"> 
                {{row.firstName}} 
              </mat-cell>
            </ng-container>
  
            <ng-container matColumnDef="lastname">
              <mat-header-cell *matHeaderCellDef>Apellidos</mat-header-cell>
              <mat-cell *matCellDef="let row"> 
                {{row.lastName}} 
              </mat-cell>
            </ng-container>
  
            <ng-container matColumnDef="email">
              <mat-header-cell *matHeaderCellDef>Correo electrónico</mat-header-cell>
              <mat-cell *matCellDef="let row"> 
                {{row.email}} 
              </mat-cell>
            </ng-container>
  
            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef>Acciones</mat-header-cell>
              <mat-cell *matCellDef="let row" fxLayoutGap="10px">
                <button mat-mini-fab color="red" matTooltip="Eliminar" (click)="onDelete(row)">
                  <mat-icon>delete</mat-icon>
                </button>
              </mat-cell>
            </ng-container>
            
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
          <!--<mat-paginator [length]="5" [pageSize]="8" showFirstLastButtons></mat-paginator>-->
        </div>
        <div *ngIf="dataSource.data.length <= 0" fxFlex="100" fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="10pxs">
          <mat-label class="text-purple">Seleccione un archivo por favor.</mat-label>
          <button mat-mini-fab color="purple" matTooltip="Cargar archivo" (click)="fileInput.click()">
            <mat-icon>file_upload</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-divider style="margin: 10px 0 10px 0;" [inset]="true"></mat-divider>
<mat-dialog-actions align="center">
  <button mat-button [mat-dialog-close]="true" class="btn-green">
    <mat-label class="m-1">Aceptar</mat-label>
    <mat-icon aria-hidden="false">check_circle</mat-icon>
  </button>
</mat-dialog-actions>