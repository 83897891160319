<app-header-top-sessions></app-header-top-sessions>
<!---->
<div 
  class="container-sessions"
  fxFlex="100"
  fxFlex.xs="98"
  fxLayout="column"
  [ngStyle.xs]="'background:none;'"
  >
  <div 
    style="background-color:none !important;"
    fxFlex="100"
    fxLayout="row"
    fxLayout.sm="column"
    fxLayout.xs="column"
    >
    <div 
      style="background-color:none !important;"
      fxFlex="20"
      fxFlex.sm="100"
      fxLayoutAlign.sm="end center"
      fxFlex.xs="30"
      fxLayoutAlign.xs="center center"
      fxLayoutAlign="center center"
      fxLayout="column"
      [ngClass.xs]="'bg-image-sessions'"
      class="text-white-shadow"
      >
      <h3 style="text-align: left; margin-left:40%; width: 100%;"  [ngStyle.sm]="{'margin-top': '15%', 'width': '50%', 'margin-left': '0%' }"  [ngStyle.xs]="{'margin-top': '15%', 'width': '50%', 'margin-left': '0%' }" ><span [ngClass.xs]="'ts-light'">Bienvenido(a)</span></h3>
      <br>
      <h3 [ngStyle.xs]="{'margin-left': '0%', 'width': '50%' }" [ngStyle.sm]="{'margin-top': '15%', 'width': '50%', 'margin-left': '0%' }"  style="font-size:1em; font-weight: light; text-align: left; margin-left:40%;width: 100%;" ><span [ngClass.xs]="'ts-light text-justify'"></span>Regístrate para utilizar nuestra plataforma de aprendizaje.</h3>
      <br>
    </div>
    <!---->
    <div 
      fxFlex="80"
      class="page-wrap"
      style="height: 100vh;"
      >
      <!---->
      <div class="session-form-hold"> 
        <div class="registration-one__right wow slideInRight" data-wow-delay="100ms"
          data-wow-duration="2500ms">
          <div class="registration-one__right-form">
            <div class="title-box">
              <h4>¿Olvidaste tu contraseña?</h4>
            </div>
            <div class="form-box">
              <form [formGroup]="form" (ngSubmit)="openConfirm()">
                <div class="form-group">
                  <input (input)="form.controls['email'].markAsTouched()" matInput type="text"  [formControl]="form.controls['email']" placeholder="Ingresa tu correo electrónico" value="">
                </div>
                <div class="form-group">
                  <small *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched" 
                    class="form-error-msg"> * Requerido</small>
                  <small *ngIf="form.controls['email'].hasError('email') && form.controls['email'].touched" 
                    class="form-error-msg"> * Correo Electrónico incorrecto</small>
                </div>
                <button mat-raised-button class="btn btn-purple full-width mb-1 rounded" [disabled]="form.invalid">Enviar código a mi E-mail</button>
                <div class="text-center">
                  <a [routerLink]="'/signin'" class="m-1 hyper mat-primary text-center full-width">Cancelar</a>
                  <span fxFlex></span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!---->
      <!--
      <div class="session-form-hold">
        <mat-progress-bar mode="determinate" class="session-progress"></mat-progress-bar>
        <mat-card style="padding: 0px;">
          <mat-card-title class="text-left bg-purple text-light p-2">
            <h3 style="font-size: 1.5em; text-align: center; width: 100%; padding-top:8px;">¿Olvidaste tu contraseña?</h3>
          </mat-card-title>
          
          <mat-card-content class="p-3">
            <div class="text-center pt-8 pb-16">
              <small>Enviaremos un código temporal para que puedas restablecer tu contraseña</small>
            </div>
            <form [formGroup]="form" (ngSubmit)="openConfirm()">
              <div class="m-2">
                <mat-form-field class="full-width">
                  <mat-icon matPrefix class="m-1 color-purple">email</mat-icon>
                  <input matInput [formControl]="form.controls['email']" placeholder="Ingresa tu correo electrónico" value="">
                </mat-form-field>
                <small *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched" 
                  class="form-error-msg"> 
                    * Requerido
                </small>
                <small *ngIf="form.controls['email'].hasError('email') && form.controls['email'].touched" 
                  class="form-error-msg"> 
                    * Correo Electrónico incorrecto
                </small>
              </div>
              <button mat-raised-button class="btn btn-purple full-width mb-1 rounded" [disabled]="form.invalid">Enviar código a mi E-mail</button>
              <div class="text-center">
                <a [routerLink]="'/signin'" class="m-1 hyper mat-primary text-center full-width">Cancelar</a>
                <span fxFlex></span>
              </div>
            </form>
          </mat-card-content>
        </mat-card>
      </div>
      -->
    </div>
  </div>
  <div fxFlex="100" fxFlexFill>
    <app-footer></app-footer>  
  </div>
</div>