import { Component, Injector, OnInit } from '@angular/core';
import { BaseService } from 'app/views/services/base.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent extends BaseService implements OnInit {

  constructor(injector:Injector) { super(injector); }

  ngOnInit(): void {
  }

  goToHome():void {
    this.router.navigate([this.HOME_ROUTE]).then(()=>window.location.reload());
  }

}
