import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit,EventEmitter, Injector } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { AdminService } from 'app/views/services/admin.service';
import { BaseService } from 'app/views/services/base.service';
import { CatCourse } from "app/views/shared/interfaces";
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-dialog-course',
  templateUrl: './dialog-course.component.html',
  styleUrls: ['./dialog-course.component.css']
})
export class DialogCourseComponent implements OnInit {
  
  public form: FormGroup;
  title:string;
  contentTypes:any;
  niveles:any;
  instructores:[];
  arrayObjetFile:any[]=[];
  contentTypeId:number = null;
  courseLevelId:number = null;
  showFromDB:boolean = true;
  baseService:BaseService;

  constructor( public dialogo: MatDialogRef<DialogCourseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CatCourse,
    private loader:AppLoaderService,
    private service: AdminService,
    private dialog:MatDialog,
    injector:Injector
  ) { this.baseService = new BaseService(injector); }
    
  public uploader: FileUploader = new FileUploader({
    url: "",
    disableMultipart : false,
    autoUpload: false,
    method: 'post',
    itemAlias: 'attachment'
  });

  ngOnInit(): void {
    if(this.data.id==0){
      this.title = "Crear Curso"
    }else{
      this.title="Actualizar Curso";
    }
    this.form = new FormGroup({
      course: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100),
      ]),
      description: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(500),
      ]),
      contentTypeId: new FormControl('', [
        Validators.required
      ]),
      salePrice: new FormControl('', [
        Validators.required,
        Validators.pattern("^[0-9]+(.[0-9]{0,2})?$"),
      ]),
      IsCheckedPromo: new FormControl('', [
        Validators.required
      ]),
      IsCheckedActivo: new FormControl('', [
        Validators.required
      ]),
      imageUrl: new FormControl('')
    });
    this.form.controls.course.setValue(this.data.course);
    this.form.controls.description.setValue(this.data.description);
    this.form.controls.salePrice.setValue(this.data.salePrice);
    this.form.controls.IsCheckedPromo.setValue((this.data.active==0?false:true));
    this.form.controls.IsCheckedActivo.setValue((this.data.active==0?false:true));
    this.form.controls.imageUrl.setValue(this.data.imageUrl);
    this.form.controls.contentTypeId.setValue(this.data.contentTypeId);
    this.contentTypeId = this.data.contentTypeId;
    this.arrayObjetFile=[];
    if(this.data.imageUrl!=""){ 
      let objet = {
        id:0,
        fileName : this.data.imageUrl,
        fileSize : 0,
        file:null,
      };
      this.arrayObjetFile.push(objet);
    }
    this.getCourseLevels();
  }

  cerrarDialogo(): void {
    this.dialogo.close(null);
  }

  confirmado(): void {
    var formulario = this.form.value;
    let dataSave = {
      id:this.data.id,
      contentTypeId: formulario.contentTypeId,
      applyPromotion : formulario.IsCheckedPromo?1:0,
      active : formulario.IsCheckedActivo?1:0,
      course : formulario.course,
      salePrice : formulario.salePrice,
      description:formulario.description,
      imageUrl:formulario.imageUrl,
      file:(this.arrayObjetFile.length>0? this.arrayObjetFile[0]:null)
    }
    //
    let message:string =  (this.data.id)?`actualizar el curso`:`crear el curso`; 
    this.service.openConfirm(`¿Seguro(a) que desea ${message}?`)
    .then(
      (response) => {
        if (response) {
          this.dialogo.close(dataSave);
        }
      }
    )
    .catch(
      (error) => {
        this.service.openDialog(`Error al: ${message}`)
      }
    ); 
  }

  instructorId:number = null;
  getCourseLevels(){
    this.loader.open();
    this.service.getCourseLevels({courseId: this.data.id}).subscribe(
      (dataResult:any) => {
        this.loader.close();
        this.contentTypes = dataResult.body.content.contentTypes;
        this.form.controls.contentTypeId.setValue(this.contentTypeId);
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.baseService.proccessHttpResponse(http);
      }
    );
  }

  public onFileSelected(event: EventEmitter<File[]>) {
    const file: File = event[0];    
    if(file.name.split('.').pop() == "png" || file.name.split('.').pop() == "jpg"){
      const fileSizeInKB = Math.round(file.size / 1024);
      if(fileSizeInKB <= (50 * 1024) ){ //Validar archivo no mayor a 50MB
        let objet = {
          id:0,
          fileName : file.name,
          fileSize : file.size,
          file:file,
        };
        this.arrayObjetFile[0]=objet;
        this.showFromDB = false;
      }else{
        this.service.openDialog("El archivo que desea subir supera los 50 MB intente con otro por favor.");
      }
    } else {
      this.service.openDialog("Solo se aceptan archivos tipo 'jpg' o 'png'.");    
    }
  }

  public deleteFile() {
    this.form.controls.imageUrl.setValue("");
    this.arrayObjetFile.splice(0, 1); 
  }
}