<div class="container-fluid">
  <div fxFlex="100" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
    <div class="header-section" fxLayout="row" fxFlex="100">
      <div fxFlex="5" fxLayoutAlign="center center">
        <button mat-mini-fab color="purple" [routerLink]="CMS_ADMIN_USERS_ROUTE">
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div>
      <div fxFlex="95" fxLayoutAlign="start center">
        <div fxFlex="22"><h4>Administración de Usuarios</h4></div>
        <div fxFlex="33">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Curso</mat-label>
            <mat-icon matPrefix>description</mat-icon>
            <mat-select [(ngModel)]="filter" (selectionChange)="onFilterChange($event?.value)">
              <mat-option *ngFor="let f of filters?.courses" [value]="f">{{ f.filter }}</mat-option>
            </mat-select>
            <mat-hint>Filtrar por curso.</mat-hint>
          </mat-form-field>
        </div>
      </div>
    </div>
    <!---->
    <div fxFlexAlign="center" fxLayout="column" fxLayout.md="row">
      <div fxLayout="row" fxLayoutAlign="space-around strech" fxLayout.lt-md="column" fxLayout.md="column" fxFlex.md="100">
        <div class="bg-light-gray" fxLayout.md="column" fxFlex="100">
          <div class="example-container mat-elevation-z2">
            <mat-paginator #paginatorP [pageIndex]="page" [length]="totalRows" [pageSize]="pageSize" (page)="onPaginationChange($event)" showFirstLastButtons></mat-paginator>
            <mat-table #table [dataSource]="dataSource">
              <ng-container matColumnDef="id">
                <mat-header-cell class="align-center" *matHeaderCellDef>#</mat-header-cell>
                <mat-cell class="align-center" *matCellDef="let row;let i = index;"> 
                  <span class="mobile-label">#</span>
                  {{i+1}} 
                </mat-cell>
              </ng-container>
              
              <ng-container matColumnDef="name">
                <mat-header-cell class="align-center" *matHeaderCellDef>Nombre completo</mat-header-cell>
                <mat-cell *matCellDef="let row"> 
                  <span class="mobile-label">Nombre:</span>
                  {{row.name}} 
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="email">
                <mat-header-cell class="align-center" *matHeaderCellDef>Correo electrónico</mat-header-cell>
                <mat-cell *matCellDef="let row"> 
                  <span class="mobile-label">Correo electrónico:</span>
                  {{row.email}} 
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="course">
                <mat-header-cell class="align-center" *matHeaderCellDef>Curso</mat-header-cell>
                <mat-cell *matCellDef="let row"> 
                  <span class="mobile-label">Curso:</span>
                  {{row.course}} 
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="topics">
                <mat-header-cell class="align-center" *matHeaderCellDef>Temas completados:</mat-header-cell>
                <mat-cell class="align-center" *matCellDef="let row"> 
                  <span class="mobile-label">Temas completados:</span>
                  {{row.topics +' / '+row.topicsCount}}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="progress">
                <mat-header-cell class="align-center" *matHeaderCellDef>Progreso</mat-header-cell>
                <mat-cell class="align-center" *matCellDef="let row">
                  <span class="mobile-label">Progreso:</span>
                  <div fxFlex="100" fxLayour="row">
                    <div fxFlex="10" fxLayoutAlign="center center">
                      <span>{{ row?.progress + '%'}}</span>
                    </div>
                    <div fxFlex="90" fxLayoutAlign="center center">
                      <mat-progress-bar color="primary" mode="determinate" value="{{row.progress}}"></mat-progress-bar>
                    </div>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="lastLogin">
                <mat-header-cell class="align-center" *matHeaderCellDef>Último acceso</mat-header-cell>
                <mat-cell class="align-center" *matCellDef="let row">
                  <span class="mobile-label">Último acceso:</span>
                  {{row?.lastLogin}}
                </mat-cell>
              </ng-container>
              
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>