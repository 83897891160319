import { Component, Inject, Injector, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseService, IUserFilterTypes } from 'app/views/services/base.service';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { AdminService } from 'app/views/services/admin.service';
import { HttpErrorResponse } from '@angular/common/http';

interface IFormActions {
  UPDATE:number;
  CREATE:number;
};

@Component({
  selector: 'app-dialog-edit-user',
  templateUrl: './dialog-edit-user.component.html',
  styleUrls: ['./dialog-edit-user.component.css']
})
export class DialogEditUserComponent implements OnInit {

  baseService:BaseService;
  user:any;
  roleIdUser:any;
  userId:any;
  userEmail:any;
  form:FormGroup;
  actionId:number = 1;// 1=create / 2=update
  fActions:IFormActions = {
    CREATE: 1,
    UPDATE: 2,
  };
  errorMessages:any = {};
  filters:any = {};
  filterTypes:IUserFilterTypes = {
    roleId:'roleId'
  };

  constructor(
    injector:Injector,
    private loader: AppLoaderService,
    private service: AdminService,
    public dialogRef: MatDialogRef<DialogEditUserComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.baseService = new BaseService(injector);
    this.user = this.data.user;
    this.roleIdUser = this.data.roleId;
    this.actionId = this.data.actionId;
    this.createForm();
  }

  ngOnInit(): void {
    this.getUserFilters();
    if (this.actionId == this.fActions.UPDATE) {
      this.getUser();
    }
  }

  createForm(): void {
    let formControls:any = {
      name: new FormControl('',[
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(45)
      ]),
      lastname: new FormControl('',[
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(45)
      ]),
      email: new FormControl('',[
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(45),
        Validators.email
      ]),
      roleId: new FormControl('',[
        Validators.required
      ]),
    };
    switch (this.actionId) {
      case this.fActions.CREATE:
        formControls.confirmEmail = new FormControl('',[
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(45),
          Validators.email
        ]);
        formControls.password = new FormControl({value:this.baseService.generatePassword(),disabled:true},[
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(15),
        ]);
        break;
      case this.fActions.UPDATE:
        formControls.createdAt = new FormControl({value:'',disabled:true},[
          Validators.required,
        ]);
        break;
    }
    this.form = new FormGroup(formControls);
    this.form.controls["roleId"].setValue(this.roleIdUser);
    this.errorMessages = {
      name:[
        { type: 'required',  message: '* Este campo es requerido.' },
        { type: 'minlength', message: '* Al menos 2 caracteres.' },
        { type: 'maxlength', message: '* No más de 45 caracteres.' },
      ],
      lastname:[
        { type: 'required',  message: '* Este campo es requerido.' },
        { type: 'minlength', message: '* Al menos 2 caracteres.' },
        { type: 'maxlength', message: '* No más de 45 caracteres.' },
      ],
      email:[
        { type: 'required',  message: '* Este campo es requerido.' },
        { type: 'minlength', message: '* Al menos 5 caracteres.' },
        { type: 'maxlength', message: '* No más de 45 caracteres.' },
        { type: 'email', message: '* Formato de correo incorrecto.' },
      ],
      confirmEmail:[
        { type: 'required',  message: '* Este campo es requerido.' },
        { type: 'minlength', message: '* Al menos 5 caracteres.' },
        { type: 'maxlength', message: '* No más de 45 caracteres.' },
        { type: 'email', message: '* Formato de correo incorrecto.' },
      ],
      password:[
        { type: 'required',  message: '* Este campo es requerido.' },
        { type: 'minlength', message: '* Al menos 5 caracteres.' },
        { type: 'maxlength', message: '* No más de 45 caracteres.' },
        { type: 'pattern', message: '* Formato de contraseña incorrecto.' },
      ],
      roleId:[
        { type: 'required',  message: '* Este campo es requerido.' },
      ],
    };
  }

  get name(): AbstractControl {
    return this.form.controls['name'];
  }

  get lastname(): AbstractControl {
    return this.form.controls['lastname'];
  }

  get email(): AbstractControl {
    return this.form.controls['email'];
  }

  get confirmEmail(): AbstractControl {
    return this.form.controls['confirmEmail'];
  }

  get password(): AbstractControl {
    return this.form.controls['password'];
  }

  get roleId(): AbstractControl {
    return this.form.controls['roleId'];
  }

  get createdAt(): AbstractControl {
    return this.form.controls['createdAt'];
  }

  getUser(): void {
    this.loader.open('Obteniendo datos ...');
    this.service.getUserById(this.user.id).subscribe(
      async (data:any) => {
        let resp = data.data;
        this.loader.close();
        if(!resp){
          this.service.openDialog('Error al obtener los datos del usuario');
          this.dialogRef.close(false);
          return;
        }
        this.form.controls['name'].setValue(resp.name);
        this.form.controls['lastname'].setValue(resp.last_name);
        this.form.controls['email'].setValue(resp.email);
        this.form.controls['createdAt'].setValue(resp.created_at);        
        this.form.controls['roleId'].setValue(resp.role_id);
        this.roleIdUser = resp.role_id;
        this.userId = resp.id;
        this.userEmail = resp.email;
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.service.openDialog("Error en el Servicio");
      }
    );
  }

  getUserFilters(): void {
    setTimeout(
      () => {
        this.filters.roles = [
          {id:1,filter:'PARTICIPANTE',filterType:this.filterTypes.roleId},
          {id:2,filter:'FACILITADOR(A)',filterType:this.filterTypes.roleId},
          {id:4,filter:'LICENCIATARIO(A)',filterType:this.filterTypes.roleId},
        ];
      },500
    );
  }

  onFilterChange(filter:any): void {
    switch (filter.filterType) {
      case this.filterTypes.roleId:
        this.roleId.setValue(filter.id);
        break;
    }
  }

  saveUser(){
    if(this.actionId == this.fActions.CREATE){
      this.create();
    }else{
      this.update();
    }
  }

  create(){
    this.loader.open('Guardando datos ...');
    let dataSave = this.form.value;
    dataSave.password = this.form.controls["password"].value;

    if(dataSave.confirmEmail != dataSave.email){
      this.loader.close();
      this.service.openDialog("Los correos ingresados no coinciden. Por favor verifique.");
      return;
    }

    this.service.createUser(dataSave).subscribe(
    async (data:any) => {
        this.loader.close();
        this.service.openDialog(data.message);        
        if(data.success){
          this.dialogRef.close(true);
        }        
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.service.openDialog(http.error.message);
      }
    );
  }

  update(){
    this.loader.open('Actualizando datos ...');
    let dataSave = this.form.value;
    dataSave.userId = this.userId;
    dataSave.sameEmail = this.userEmail == dataSave.email ? 1:0;

    this.service.updateUser(dataSave).subscribe(
    async (data:any) => {
        this.loader.close();
        this.service.openDialog(data.message);        
        if(data.success){
          this.dialogRef.close(true);
        }        
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.service.openDialog(http.error.message);
      }
    );
  }
}
