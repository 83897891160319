import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DialogEditUserComponent } from 'app/views/dialogs/dialog-edit-user/dialog-edit-user.component';
import { BaseService, IResponse } from 'app/views/services/base.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.css']
})
export class AdminUsersComponent extends BaseService implements OnInit,AfterViewInit {
  
  pageSize:number = 10;
  // participants
  displayedColumnsP: string[] = ["id","name","lastname","email","role","active","actions"];
  dataSourceP = new MatTableDataSource<any>();
  @ViewChild('paginatorP') paginatorP: MatPaginator;
  totalRowsP:number = 0;
  paginatorIndexP:number = 0;
  // facilitator
  displayedColumnsF: string[] = ["id","name","lastname","email","role","active","actions"];
  dataSourceF = new MatTableDataSource<any>();
  @ViewChild('paginatorF') paginatorF: MatPaginator;
  totalRowsF:number = 0;
  paginatorIndexF:number = 0;
  // licenciatario
  displayedColumnsL: string[] = ["id","name","lastname","email","role","active","actions"];
  dataSourceL = new MatTableDataSource<any>();
  @ViewChild('paginatorL') paginatorL: MatPaginator;
  totalRowsL:number = 0;
  paginatorIndexL:number = 0;
  //
  roleId:number = 1;

  constructor(injector:Injector) { super(injector); }

  ngOnInit(): void {
    this.getUsers();
  }

  ngAfterViewInit() {
    this.validatePages();
  }

  getUsers(roleId:number=1,page:number=0,pageSize:number=10): void {
    this.loader.open();
    let data = {
      page:page,
      pageSize:pageSize,
      roleId:roleId
    };
    this.clearTablesByRole(roleId);
    this.service.getUserByRole(data).then(
      (response) => {
        this.loader.close();
        let body:IResponse = response.body;
        if (!body.success) {
          this.service.openDialog(`No se encontraron datos.`);
          return;
        }
        if(roleId==1){
          this.dataSourceP.data = body.data.data;
          this.totalRowsP = body.data.totalRows;
          this.paginatorIndexP = page;
          this.dataSourceP.paginator = this.paginatorP;
        } else if(roleId==2){
          this.dataSourceF.data = body.data.data;
          this.totalRowsF = body.data.totalRows;
          this.paginatorIndexF = page;
          this.dataSourceF.paginator = this.paginatorF;
        } else if(roleId==4){
          this.dataSourceL.data = body.data.data;
          this.totalRowsL = body.data.totalRows;
          this.paginatorIndexL = page;
          this.dataSourceL.paginator = this.paginatorL;
        }
      }
    ).catch(
      (error:HttpErrorResponse) => {
        this.loader.close();
        this.service.processHttpResponse(error);
      }
    );
  }
  
  activate(user:any): void {
    let active = user.active == 1 ? 0 : 1;
    let msg = active == 1 ? 'activar' : 'desactivar';
    this.openConfirm(`¿Seguro(a) que desea ${msg} al usuario '${user?.name}'?`).subscribe(
      (response) => {
        if (response) {
          this.loader.open();
          let data = {
            userId:user.id,
            active:active
          };
          this.service.activeUser(data).then(
            (response) => {
              this.loader.close();
              let body:IResponse = response.body;
              this.service.openDialog(body.message).subscribe(()=>{
                this.getUsers(this.roleId);
              });
            }
          ).catch(
            (error:HttpErrorResponse) => {
              this.loader.close();
              this.service.processHttpResponse(error);
            }
          );
        }
      }
    );
  }

  onOpenDialogMassUpload(): void {
    this.openDialogMassUpload().subscribe(
      (response) => {
        if (response) this.getUsers();
      }
    )
  }

  onTabChanged(event:MatTabChangeEvent): void {
    this.validatePages();
    this.roleId = 1;
    switch (event.index) {
      case 0: //Participantes
        this.getUsers(this.roleId);
        break;
      case 1:
        this.roleId = 2;
        this.getUsers(this.roleId);
        break;
      case 2:
        this.roleId = 4;
        this.getUsers(this.roleId);
        break;
    }
  }
  
  onChangePaginatorP(event:any){
    this.getUsers(1,event.pageIndex,event.pageSize);
  }
  
  onChangePaginatorF(event:any){
    this.getUsers(2,event.pageIndex,event.pageSize);
  }

  onChangePaginatorL(event:any){
    this.getUsers(4,event.pageIndex,event.pageSize);
  }

  openDialogUser(roleId:any=null,user:any=null){
    this.dialog.open(DialogEditUserComponent, {
      data: { 
        user:user,
        roleId:roleId,
        actionId: !user ? 1 : 2
      },
      autoFocus: false,
      disableClose: true,
      width:'720px',
      height:'480px'
    }).afterClosed().subscribe(
      (response) => {
        if(response){
          this.getUsers(this.roleId);
        }
      }
    );
  }

  validatePages(){
    if(this.paginatorP != undefined){
      this.paginatorP._intl = this.paginatorService.translatePaginator(this.paginatorP._intl);
      this.dataSourceP.paginator = this.paginatorP;
    }
    
    if(this.paginatorF != undefined){
      this.paginatorF._intl = this.paginatorService.translatePaginator(this.paginatorF._intl);
      this.dataSourceF.paginator = this.paginatorF;
    }

    if(this.paginatorL != undefined){
      this.paginatorL._intl = this.paginatorService.translatePaginator(this.paginatorL._intl);
      this.dataSourceL.paginator = this.paginatorL;
    }
  }

  clearTablesByRole(roleId:number){
    if(roleId==1){
      this.dataSourceP.data = [];
      this.totalRowsP = 0;
      this.paginatorIndexP = 0;
      this.dataSourceP.paginator = this.paginatorP;
    } else if(roleId==2){
      this.dataSourceF.data = [];
      this.totalRowsF = 0;
      this.paginatorIndexF = 0;
      this.dataSourceF.paginator = this.paginatorF;
    } else if(roleId==4){
      this.dataSourceL.data = [];
      this.totalRowsL = 0;
      this.paginatorIndexL = 0;
      this.dataSourceL.paginator = this.paginatorL;
    }
  }
}
