<div fxLayout="row" fxLayoutAlign="space-between center" >
    <p mat-dialog-title class="text-purple text-bold" style="margin: 0px;">Crear código de inscripción a simulador</p>
    <button mat-icon-button aria-label="close icon" [mat-dialog-close]="true"  >
        <mat-icon>close</mat-icon>
    </button>
</div>

<div mat-dialog-content fxLayout="column">
    <mat-horizontal-stepper [linear]="true">
        <mat-step [stepControl]="firstFormGroup">
            <form fxLayout="column" [formGroup]="firstFormGroup">
                <ng-template matStepLabel>Datos generales</ng-template>
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Facilitador:</mat-label>
                    <input matInput type="text" disabled [value]="name">
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Seleccione un curso:</mat-label>
                    <input matInput type="text" disabled [value]="data.course?.course">
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Seleccione módulo:</mat-label>
                    <mat-select fxFlex matInput formControlName="moduleId">
                        <mat-option *ngFor="let module of modules" [value]="module.moduleId">{{module?.topic}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Código de acceso:</mat-label>
                    <input matInput placeholder="Codigo de acceso" formControlName="codeSession" (keyup)="baseService.noWhiteSpaceInputKeyUp($event)">
                    <mat-hint>
                        <ng-container *ngFor="let error of errorMessages.codeSession">
                          <ng-container *ngIf="baseService.formControlHasError(codeSession,error)">
                            <small class="form-error">{{error.message}}</small>
                          </ng-container>
                        </ng-container>
                    </mat-hint>
                </mat-form-field>
            </form>
            <div mat-dialog-actions fxLayoutAlign="center center">
                <button class="btn-purple" mat-button matStepperNext [disabled]="firstFormGroup.invalid">
                    <mat-label class="m-1">Siguiente</mat-label>
                    <mat-icon class="m-1">arrow_forward</mat-icon>
                </button>
            </div>
        </mat-step>
        <mat-step [stepControl]="secondFormGroup" fxLayout="column" fxLayoutAlign="space-between center">
            <form fxLayout="column" [formGroup]="secondFormGroup">
                <ng-template matStepLabel>Datos del grupo</ng-template>
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Nombre del grupo:</mat-label>
                    <input matInput type="text" formControlName="groupName">
                    <mat-hint>
                        <ng-container *ngFor="let error of errorMessages.groupName">
                          <ng-container *ngIf="baseService.formControlHasError(groupName,error)">
                            <small class="form-error">{{error.message}}</small>
                          </ng-container>
                        </ng-container>
                    </mat-hint>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Límite de alumnos:</mat-label>
                    <input matInput type="text" formControlName="maxCapacity">
                    <mat-hint>
                        <ng-container *ngFor="let error of errorMessages.maxCapacity">
                          <ng-container *ngIf="baseService.formControlHasError(maxCapacity,error)">
                            <small class="form-error">{{error.message}}</small>
                          </ng-container>
                        </ng-container>
                    </mat-hint>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Seleccione país:</mat-label>
                    <mat-select fxFlex matInput formControlName="countryId">
                        <mat-option *ngFor="let country of countries" [value]="country.id">{{country?.country}}
                        </mat-option>
                    </mat-select>
                    <mat-hint>
                        <ng-container *ngFor="let error of errorMessages.countryId">
                          <ng-container *ngIf="baseService.formControlHasError(countryId,error)">
                            <small class="form-error">{{error.message}}</small>
                          </ng-container>
                        </ng-container>
                    </mat-hint>
                </mat-form-field>
                <div style="min-height: 52px; height: 52px;"> </div>
            </form>
            <div mat-dialog-actions fxLayoutAlign="center center">
                <button class="btn-purple" mat-button matStepperPrevious>
                    <mat-label class="m-1">Anterior</mat-label>
                    <mat-icon aria-hidden="false">arrow_back</mat-icon>
                </button>
                <button class="btn-green" *ngIf="!this.data.isEdit" mat-button (click)="save()" [disabled]="secondFormGroup.invalid">
                    <mat-label class="m-1">Guardar</mat-label>
                    <mat-icon class="m-1">check_circle_outline</mat-icon>
                </button>
                <button class="btn-green" *ngIf="this.data.isEdit" mat-button (click)="edit()" [disabled]="secondFormGroup.invalid">
                    <mat-label class="m-1">Guardar</mat-label>
                    <mat-icon class="m-1">check_circle_outline</mat-icon>
                </button>
            </div>
        </mat-step>
    </mat-horizontal-stepper>
</div>