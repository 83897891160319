<div fxLayout="row" fxLayoutAlign="space-between center" >
    <p mat-dialog-title class="text-purple text-bold" style="margin: 0px;">Confirmar código de inscripción a simulador</p>
    <button mat-icon-button aria-label="close icon" [mat-dialog-close]="true"  >
        <mat-icon>close</mat-icon>
    </button>
</div>

<div mat-dialog-content fxLayout="column">
    <form fxLayout="column" [formGroup]="firstFormGroup">
        <mat-form-field appearance="outline" fxFlex>
            <mat-label>Facilitador:</mat-label>
            <input matInput type="text" disabled [value]="data?.facilitatorName">
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex>
            <mat-label>Curso:</mat-label>
            <input matInput type="text" disabled [value]="data?.course">
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex>
            <mat-label>Módulo:</mat-label>
            <input matInput type="text" disabled [value]="data?.module">
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex>
            <mat-label>Código de Inscripción:</mat-label>
            <input matInput type="text" disabled [value]="data?.codeSession">
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex>
            <mat-label>Url/Enlace para reunión:</mat-label>
            <input matInput type="text" disabled formControlName="meetUrl">
            <mat-hint>
                <ng-container *ngFor="let error of errorMessages.meetUrl">
                  <ng-container *ngIf="baseService.formControlHasError(meetUrl,error)">
                    <small class="form-error">{{error.message}}</small>
                  </ng-container>
                </ng-container>
            </mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex>
            <mat-label>Inicio de vigencia:</mat-label>
            <input matInput [matDatetimepicker]="startsAtPicker" (click)="startsAtPicker.open()" formControlName="startsAt" required autocomplete="false">
            <mat-datetimepicker-toggle [for]="startsAtPicker" matSuffix></mat-datetimepicker-toggle>
            <mat-datetimepicker #startsAtPicker type="datetime" openOnFocus="true" timeInterval="5"></mat-datetimepicker>
            <mat-hint>
                <ng-container *ngFor="let error of errorMessages.startsAt">
                  <ng-container *ngIf="baseService.formControlHasError(startsAt,error)">
                    <small class="form-error">{{error.message}}</small>
                  </ng-container>
                </ng-container>
            </mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex>
            <mat-label>Fin de vigencia:</mat-label>
            <input matInput [matDatetimepicker]="endsAtPicker" (click)="endsAtPicker.open()" formControlName="endsAt" required autocomplete="false" [min]="startsAt.value">
            <mat-datetimepicker-toggle [for]="endsAtPicker" matSuffix></mat-datetimepicker-toggle>
            <mat-datetimepicker #endsAtPicker type="datetime" openOnFocus="true" timeInterval="5"></mat-datetimepicker>
            <mat-hint>
                <ng-container *ngFor="let error of errorMessages.endsAt">
                  <ng-container *ngIf="baseService.formControlHasError(endsAt,error)">
                    <small class="form-error">{{error.message}}</small>
                  </ng-container>
                </ng-container>
            </mat-hint>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions fxLayoutAlign="center center" align="end">
    <button class="btn-purple" mat-button (click)="closeDialog()">
        <mat-label class="m-1">Cancelar</mat-label>
        <mat-icon aria-hidden="false">cancel_outline</mat-icon>
    </button>
    <button class="btn-green" mat-button [disabled]="firstFormGroup.invalid" (click)="confirmCode()">
        <mat-label class="m-1">Confirmar código</mat-label>
        <mat-icon class="m-1">check_circle_outline</mat-icon>
    </button>
</div>