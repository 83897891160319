<app-header-top-sessions *ngIf="!isLogged"></app-header-top-sessions>
<div class="scrolling-ever-top"
	[style.margin-top]="(!isLogged)?'14em':''"
	fxFlex="100"
	fxLayout="column" 
	fxLayout.md="column"
	fxLayout.sm="column"
	fxLayout.xs="column"
	>
	<!--banner-->
	<div fxFlex="100" class="container m-container">
		<div class="container">
			<div class="col-sm-12">
			 	<div class="caption-2 rounded"  style="width: 100%">
						<img src="assets/images/entrepeneurs/banner.png" class="rounded" style="width: 100% !important; margin: 0px; border-radius: 9px !important;" class="img-full-width" alt="{{seoService.getBannerOfferAltText()}}" />
				 </div>
			</div>
		</div>
	</div>
	<!--/-->
	<!--Cursos-Adquiridos-->
	<div class="container">
		<div id="adquired-courses" class="m-lr-10" *ngIf="acquiredCourses.length > 0">
			<hr/>
			<h3>Mis Cursos</h3>
			<!--Pagination-->
			<div class="text-center">
				<pagination-controls id="pagination2" class="ngx-pagination" (pageChange)="changePagePaginator2($event)" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
			</div>
			<!-- courses-container -->
			<div class="product-container" [ngClass]="{'list-view': viewMode === 'list-view'}">
				<!-- Course Box -->
				<div class="product-wrap product-wrap-details m-1 course-container rounded"
					*ngFor="let course of acquiredCourses | paginate: { id:'pagination2',itemsPerPage: itemsPerPageP2, currentPage: currentPageP2, totalItems: rowsNumberP2 }; let i = index;"
					[@animate]="{value:'*',params:{delay: (i*100)+'ms', y:'50px'}}">
					<div fxLayout="column" fxFlex="100">
						<div 
							fxFlex="100" 
							fxLayout="column" 
							fxLayoutAlign="center center" 
							class="courses-one__single wow fadeInLeft"
							[routerLink]="[COURSES_AUTH_ROUTE,course.courseSlug]"
							>
							<div class="courses-one__single-img" style="width: 100%;height: 100%;">
								<img [src]="course.imageUrl" [alt]="course.course" />
								<div class="overlay-text" style="cursor: pointer;">
									<p [routerLink]="[COURSES_AUTH_ROUTE,course.courseSlug]">Ver detalles</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- end course box -->
			</div>
			<!-- end courses-container -->
		</div>
	</div>	
	<!--Cursos-Vencidos-->
	<div class="container">
		<div id="expired-courses" class="m-lr-10" *ngIf="expiredCourses.length > 0">
			<hr/>
			<h3>Mis Cursos Vencidos</h3>
			<!--Pagination-->
			<div class="text-center">
				<pagination-controls id="pagination1" class="ngx-pagination" (pageChange)="changePagePaginator1($event)" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
			</div>
			<!-- courses-container -->
			<div class="product-container" [ngClass]="{'list-view': viewMode === 'list-view'}">
				<!-- Course Box -->
				<div class="product-wrap product-wrap-details m-1 course-container"
					*ngFor="let course of expiredCourses | paginate: { id:'pagination1',itemsPerPage: itemsPerPageP1, currentPage: currentPageP1, totalItems: rowsNumberP1 }; let i = index;"
					[@animate]="{value:'*',params:{delay: (i*100)+'ms', y:'50px'}}">
					<div fxLayout="column" fxFlex="100">
						<div 
							fxFlex="100" 
							fxLayout="column" 
							fxLayoutAlign="center center" 
							class="courses-one__single wow fadeInLeft"
							[routerLink]="[STUDY_PLAN_ROUTE,course.courseSlug]"
							>
							<div class="courses-one__single-img" style="width: 100%;height: 100%;">
								<img [src]="course.imageUrl" [alt]="course.course" />
								<div class="overlay-text" style="cursor: pointer;">
									<p [routerLink]="[STUDY_PLAN_ROUTE,course.courseSlug]">Renovar</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- end course box -->
			</div>
			<!-- end courses-container -->
		</div>
	</div>
	<!--Cursos-Disponibles-->
	<div class="container">
		<div id="courses-available" class="m-lr-10" *ngIf="availableCourses.length > 0">
			<hr/>
			<h3>Cursos Disponibles</h3>
			<!--Pagination-->
			<div class="text-center">
				<pagination-controls id="pagination3" class="ngx-pagination" (pageChange)="changePagePaginator3($event)" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
			</div>
			<!-- courses-container -->
			<div class="product-container" [ngClass]="{'list-view': viewMode === 'list-view'}">
				<!-- Course Box -->
				<div class="product-wrap product-wrap-details m-1 course-container"
					*ngFor="let course of availableCourses | paginate: { id:'pagination3',itemsPerPage: itemsPerPageP3, currentPage: currentPageP3, totalItems: rowsNumberP3 }; let i = index;"
					[@animate]="{value:'*',params:{delay: (i*100)+'ms', y:'50px'}}"
					>
					<div fxLayout="column" fxFlex="100">
						<div 
							fxFlex="100" 
							fxLayout="column" 
							fxLayoutAlign="center center" 
							class="courses-one__single wow fadeInLeft"
							[routerLink]="[STUDY_PLAN_ROUTE,course.courseSlug]"
							>
							<div class="courses-one__single-img" style="width: 100%;height: 100%;">
								<img [src]="course.imageUrl" [alt]="course.course" />
								<div *ngIf="isLogged" class="overlay-text" style="cursor: pointer;">
									<p matTooltip="Comprar" [routerLink]="[STUDY_PLAN_ROUTE,course.courseSlug]">{{course?.salePriceLabel}}</p>
								</div>
								<div *ngIf="!isLogged" class="overlay-text" style="cursor: pointer;">
									<p matTooltip="Ver plan de estudios" [routerLink]="[STUDY_PLAN_ROUTE,course.courseSlug]">{{course?.salePriceLabel}}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- end course box -->
			</div>
		</div>
	</div>
	<!--Membresias-->
	<div class="container">
		<div id="courses-available" class="m-lr-10" *ngIf="memberships.length > 0">
			<hr/>
			<div fxLayout="row">
				<h3>Membresías</h3>
			</div>
			<!-- courses-container -->
			<div class="product-container" [ngClass]="{'list-view': viewMode === 'list-view'}">
				<!-- Course Box -->
				<div class="product-wrap product-wrap-details m-1 course-container" *ngFor="let m of memberships" [@animate]="{value:'*',params:{delay: (i*100)+'ms', y:'50px'}}">
					<div fxLayout="column" fxFlex="100">
						<div fxFlex="100" fxLayout="column" fxLayoutAlign="center center"
							class="courses-one__single wow fadeInLeft" (click)="membershipRedirect(m)">
							<div class="courses-one__single-img" style="width: 100%;height: 100%;">
								<img [src]="m.imageUrl" [alt]="m.name" />
								<div *ngIf="isLogged" class="overlay-text" style="cursor: pointer;">
									<p matTooltip="Comprar" (click)="membershipRedirect(m)">{{haveMembership==1 ? 'Ver detalles' : m?.salePriceLabel}}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- end course -->
			</div>
			<!-- end courses-container -->
			<hr/>
		</div>
	</div>
	<app-footer *ngIf="!isLogged" fxFlex="100"></app-footer>
</div>
