import { Component, Inject, Injector, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseService, IUserFilterTypes } from 'app/views/services/base.service';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { AdminService } from 'app/views/services/admin.service';
import { HttpErrorResponse } from '@angular/common/http';
import { RoleService } from 'app/views/services/role.service';

interface IFormActions {
  UPDATE:number;
  CREATE:number;
};

@Component({
  selector: 'app-dialog-promotion-codes',
  templateUrl: './dialog-promotion-codes.component.html',
  styleUrls: ['./dialog-promotion-codes.component.css']
})
export class DialogPromotionCodesComponent implements OnInit {

  baseService:BaseService;
  stripeId:string;
  couponId:string;

  coupon:any;
  promotionCodes:any = [];

  constructor(
    injector:Injector,
    public role: RoleService,
    private loader: AppLoaderService,
    private service: AdminService,
    public dialogRef: MatDialogRef<DialogPromotionCodesComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.baseService = new BaseService(injector);
    this.couponId = this.data.couponId;
    this.stripeId = this.data.stripeId;
  }

  async ngOnInit() {
    this.getCoupon();
  }

  getCoupon(): void {
    if(!this.stripeId){
      this.service.openDialog('Este cupón no está registrado en la plataforma de Stripe. Favor de verificar.'); 
      this.dialogRef.close(true);
      return;
    }

    let data = {
      couponId: this.couponId,
      stripeId: this.stripeId
    }
    this.loader.open();
    this.service.getCoupon(data).then(
      async (data:any) => {
        let resp = data.body;
        if(!resp.success){
          this.service.openDialog(resp.message);
          this.dialogRef.close(false);
          this.loader.close(); 
          return;
        }

        let dataCoupon = resp.data;
        this.coupon = dataCoupon;
        this.getPromotionCode();
      },
      (http: HttpErrorResponse) => {
        console.log(http);
        this.loader.close();
        this.service.openDialog("Error en el Servicio");
      }
    );
  }

  getPromotionCode(): void {
    let data = {
      coupon: this.stripeId
    }

    this.promotionCodes = [];

    this.service.getPromotionCodes(data).then(
      async (data:any) => {
        this.loader.close(); 
        let resp = data.body;
        if(!resp.success){
          this.service.openDialog(resp.message);
          this.dialogRef.close(false);
          return;
        }

        let dataPromotionCode = resp.data;
        this.promotionCodes = dataPromotionCode.data;
      },
      (http: HttpErrorResponse) => {
        console.log(http);
        this.loader.close();
        this.service.openDialog("Error en el Servicio");
      }
    );
  }

  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  activate(code:any,checkbox): void {
    let active = !code.active;
    checkbox._checked = (checkbox._checked)?true:false;
    let msg = active ? 'activar' : 'desactivar';

    this.baseService.openConfirm(`¿Seguro(a) que desea ${msg} el código '${code?.code}'?`).subscribe(
      (response) => {
        if (response) {
          this.loader.open();
          let data = {
            promo:code.id,
            active:active,
            licensee:this.coupon.licenseeId,
            couponName:this.coupon.stripeData.name,
            promoName:code.code
          };
          this.service.activePromotionCode(data).then(
            (response) => {
              this.loader.close();
              let body = response.body;
              this.service.openDialog(body.message).subscribe(()=>{
                this.getCoupon();
              });
            }
          ).catch(
            (error:HttpErrorResponse) => {
              this.loader.close();
              this.service.processHttpResponse(error);
            }
          );
        }else{
          checkbox._checked = (checkbox._checked)?false:true;
        }
      }
    );
  }

  create(){
    if(this.coupon.stripeData.duration == 'once' && this.promotionCodes.length >= 1){
      this.service.openDialog('Solo se puede generar un código por el tipo de duración del cupón.');
      return;
    }
    let data = {
      coupon: this.stripeId,
      active:true,
      licensee: this.coupon.licenseeId,
      couponName: this.coupon.stripeData.name
    }

    this.baseService.openConfirm(`¿Seguro(a) que desea crear un código de promoción?`).subscribe(
      (response) => {
        if (response) {
          this.loader.open();
          this.service.createPromotionCode(data).then(
            async (data:any) => {
              this.loader.close(); 
              let resp = data.body;
              this.service.openDialog(resp.message);
              if(!resp.success){
                this.dialogRef.close(false);
                return;
              }

              this.getCoupon();
            },
            (http: HttpErrorResponse) => {
              console.log(http);
              this.loader.close();
              this.service.processHttpResponse(http);
            }
          );
        }
      }
    );
  }
}