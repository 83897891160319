import { Component, OnInit, Input, OnDestroy, Renderer2, Injector } from '@angular/core';
import { NavigationService } from "../../../shared/services/navigation.service";
import { Subscription } from 'rxjs';
import { ThemeService } from '../../../shared/services/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../../services/layout.service';
import { HttpErrorResponse } from '@angular/common/http';
import { API } from 'app/views/shared/api';
import { Profile, profileInfo } from 'app/views/dashboard/users/profile';
import { ComunicationService } from 'app/comunication.service';
import { InitGameDialogComponent } from 'app/views/dialogs/init-game-dialog/init-game-dialog.component';
import moment from 'moment';
import { BaseService } from 'app/views/services/base.service';

@Component({
  selector: 'app-header-top',
  templateUrl: './header-top.component.html'
})
export class HeaderTopComponent extends BaseService implements OnInit, OnDestroy {
  public user:any;
  countNotification:number=0;
  public imagenProfile:string;
  dataProfileSubscription:Subscription;
  layoutConf: any;
  menuItems:any;
  menuItemSub: Subscription;
  egretThemes: any[] = [];
  currentLang = 'en';
  availableLangs = [{
    name: 'English',
    code: 'en',
  }, {
    name: 'Spanish',
    code: 'es',
  }]
  @Input() notificPanel;
  userLevelId:number;
  currentDate:string = '';
  userFullName:string = '';

  constructor(
    injector:Injector
  ) { 
    super(injector);
    this.navService.getMenuItems();
  }
  ngOnInit() {
    setInterval(this.update, 1000);
    this.notificacionC.getNotifications().subscribe(
      async (dataNotification)=>{
        //console.log("sillegue");
        
         this.countNotification = await dataNotification.length;
     });
    this.layoutConf = this.layout.layoutConf;
    this.egretThemes = this.themeService.egretThemes;
    
    this.dataProfileSubscription = this.profileInfo.profileInfoUser$.subscribe(
      (data:profileInfo)=>{
        let rutaimagen = '';
        let user = JSON.parse(localStorage.getItem('userInfo'));
        if(data.imagenURL){
          rutaimagen = data.imagenURL;
        }else{
          rutaimagen = user.imageUrl;
        }
        this.userLevelId = data.userLevelId;
        this.imagenProfile = (rutaimagen) ? API.URL_BASE_STORAGE + rutaimagen : 'assets/images/entrepeneurs/not-found.png';
        
        if (user.provider){
          switch (user.provider) {
            case "GOOGLE":
              this.imagenProfile = user.googleImageProfile;
              break;
            case "FACEBOOK":
              this.imagenProfile = user.facebookImageProfile;
              break;
          }
        }
        this.user = user;
        this.userFullName = (user.name)?`${user.name}`:'';
    });
    this.currentDate = this.date.getCurrentDate(`EEEE d MMMM y, h:mm:ss a`);
    this.initNavigation();
  }
  changeSidenav(data) {
    this.navService.publishNavigationChange(data.value);
  }
  ngOnDestroy() {
    if (this.menuItemSub != undefined)
      this.menuItemSub.unsubscribe();      
  }
  
  setLang() {
    this.translate.use(this.currentLang)
  }
  
  changeTheme(theme) {
    this.layout.publishLayoutChange({matTheme: theme.name})
  }
  
  toggleNotific() {
    
    this.notificPanel.toggle();
  }
  
  toggleSidenav() {
    if(this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      })
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    })
  }

  getCurrentDateAndTime(){
    moment.locale('es');
    return moment().format('dddd D MMMM YYYY, h:mm:ss a');
  }
  
  public update () {
    moment.locale('es');
    let element = document.getElementById("lblFecha");
    if (element) {
      element.innerHTML = moment().format('dddd D MMMM YYYY, h:mm:ss a');
    }
}

  
  logout(){
    this.loader.open('Cerrando Sesión');
    const loader = this.loader.getDialog();
    this.service.logout().subscribe(
      (data) => {
        loader.close();
        //console.log(data);
        if (data.status == 200){
          this.service.clearUserInfo();
          this.service.openSnackBar('Sesión cerrada correctamente, ¡Vuelva pronto!');
          if (this.layoutConf.isMobile){
            this.router.navigate(['/signin'])
            .then(() => {
              window.location.reload();
            });
          } else {
            this.service.navigateTo('/signin');
          }
        }
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        switch (http.status) {
          case 401:
            this.service.openDialog('Operación no permitida');
            this.service.clearUserInfo();
            this.service.navigateTo('/signin');
            break;
          default:
            this.service.openDialog('Error con el servicio.');
        }
      }
    );
    
  }

  search(){
    
  }

  getUserLevel(){
    return (this.user.userLevelId)?this.user.userLevel:"No disponible.";
  }
  
  goTo(url:string){
    if (url.includes('home')) {
      window.location.replace(url);
    } else {
      this.router.navigateByUrl(url,{replaceUrl:true});
    } 
  }

  issetUserSession():boolean{
    return this.service.issetUserInfo();
  }

  validateCode(){
    if(window.innerWidth < 1024 || window.innerHeight < 768){
      this.service.openDialog(`<div style="text-align: justify;">Para garantizar el correcto uso de nuestra herramienta digital te recomendamos utilizar un dispositivo de mayor resolución.</div>`);
      return;
    }

    let rol = this.role.getRoleBySession();
    return this.dialog.open(InitGameDialogComponent,{
      width: '600px',
      data:{rol:rol},
      autoFocus: false,
      disableClose: true
    }).afterClosed().toPromise();
  }

  initNavigation() {
    this.layoutConf = this.layout.layoutConf;
    this.egretThemes = this.themeService.egretThemes;
    this.menuItemSub = this.navService.getMenuItems()
      .subscribe(res => {
        res = res.filter(item => item.type !== 'icon' && item.type !== 'separator');
        let limit = 5
        let mainItems: any[] = res.slice(0, limit)
        if (res.length <= limit) {
          return this.menuItems = mainItems
        }
        let subItems: any[] = res.slice(limit, res.length - 1)
        mainItems.push({
          name: 'More',
          type: 'dropDown',
          tooltip: 'More',
          icon: 'more_horiz',
          sub: subItems
        })
        this.menuItems = mainItems
      });
  }

  getActiveRouteClass(url): string {
    let activeClass:string = '';
    if (this.router.url === url) {
      activeClass = 'active-route';
    }
    return activeClass;
  }

}
