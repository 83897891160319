<div fxLayout="row" fxLayoutAlign="space-between center" >
    <p mat-dialog-title class="text-purple text-bold" style="margin: 0px;">{{title}}</p>
    <button mat-icon-button aria-label="close icon"  (click)="cerrarDialogo()"  >
        <mat-icon>close</mat-icon>
    </button>
</div>

<form [formGroup]="form" (ngSubmit)="confirmado()">
<div mat-dialog-content class="example-form">
    <div layout="row" layout-xs="column">
        <div flex="">
            <mat-form-field class="example-full-width">
                <mat-label>Tema</mat-label>
                <input matInput placeholder="Tema" formControlName="topic">
            </mat-form-field>
            <small *ngIf="form.controls['topic'].hasError('required') && form.controls['topic'].touched" class="form-error-msg">
              * Requerido.
            </small>
            <small *ngIf="form.controls['topic'].hasError('maxlength') && form.controls['topic'].touched" class="form-error-msg">
              * Máximo 500 caracteres.
            </small>
        </div>
    </div>
    <div layout="row" layout-xs="column">
        <div flex="">
            <mat-form-field class="example-full-width">
                <mat-label>Descripción</mat-label>
                <input matInput placeholder="Descripción" formControlName="description">
            </mat-form-field>
            <small *ngIf="form.controls['description'].hasError('required') && form.controls['description'].touched" class="form-error-msg">
              * Requerido.
            </small>
        </div>
    </div>
    
    <div class="m-1" flex="100" fxLayout="row" fxLayoutAlign="space-around center">
        <div flex="50">
            <mat-checkbox 
                type="button"
                class="example-margin" 
                formControlName="IsCheckedActivo"
                >Activo</mat-checkbox>
        </div>
        <div flex="50">
            <mat-checkbox 
                type="button"
                [matTooltip]="'Este tema'+((form.controls.simulator.value)?' ':' no ')+'corresponde a un Simulador'"
                class="example-margin" 
                formControlName="simulator"
                >
                Modelo de Simulación</mat-checkbox>
        </div>
    </div>
    <br/>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <div class="row">
                        <div class="col-sm-6" color="primary">
                            <mat-form-field class="example-full-width">
                                <mat-label>Título</mat-label>
                                <input matInput placeholder="Título" formControlName="titulo">
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6" color="primary">
                            <span style="display:none ;">
                            <input type="file" #fileInput name="fileInput" id="fileInput" ng2FileSelect [uploader]="uploader" (onFileSelected)="onFileSelected($event)" />
                            </span>
                            <div class="btn-group" (click)="fileInput.click()">
                            <button type="button" mat-button color="primary">
                                <mat-icon>drive_folder_upload</mat-icon>  
                                Agregar archivo</button>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
            <label class="col-sm-3 col-form-label" for="files">Archivos:</label>
            <div class="col-md-12">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Archivo</th>
                            <th>Titulo</th>
                            <th>Tamaño</th>
                            <th>Acción</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of arrayObjetFile">
                            <td >
                                {{ item.fileName }}
                            </td>
                            <td >
                                {{ item.titulo }}
                            </td>
                            <td>
                                    {{ item?.fileSize/1024/1024 | number:'.2' }} MB
                            </td>
                            <td>
                                <button mat-mini-fab type="button" color="primary"  (click)="deleteFile(item)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-button type="button" (click)="cerrarDialogo()">Cancelar</button>
  <button mat-button type="submit" [disabled]="form.invalid">Guardar</button>
</div>
</form>
  