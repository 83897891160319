import { HttpErrorResponse } from '@angular/common/http';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { BaseService, IResponse } from 'app/views/services/base.service';

@Component({
  selector: 'app-facilitator-registration-codes',
  templateUrl: './facilitator-registration-codes.component.html',
  styleUrls: ['./facilitator-registration-codes.component.css']
})
export class FacilitatorRegistrationCodesComponent extends BaseService implements OnInit {
  displayedColumns: string[] = ["id","course","module","groupName","codeSession","sessionStatus","createdAt","actions"];
  dataSource = new MatTableDataSource<any>();
  totalRows:number = 0;
  pageSize:number = 10;
  @ViewChild('paginator') paginator:MatPaginator;
  paginatorIndex:number = 0;
  userInfo:any;

  constructor(injector:Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.userInfo = this.storage.getUserInfo();
    this.getListCodesByFacilitator();
  }

  getListCodesByFacilitator(page:number=0,pageSize:number=this.pageSize): void {
    this.loader.open();
    let data = {
      page:page,
      pageSize:pageSize,
      facilitatorId:this.userInfo.id
    };
    this.dataSource.data = [];
    this.totalRows = 0;
    this.service.getListCodesByFacilitator(data).then(
      (response) => {
        this.loader.close();
        let body:IResponse = response.body;
        if (!body.success) {
          this.service.openDialog(body.message);
          return;
        }
        this.dataSource.data = body.data.data;
        this.totalRows = body.data.totalRows;
        this.paginatorIndex = page;
        this.dataSource.paginator = this.paginator;
      }
    ).catch(
      (error:HttpErrorResponse) => {
        this.loader.close();
        this.service.processHttpResponse(error);
      }
    );
  }

  onChangePaginator(event:any){
    this.getListCodesByFacilitator(event.pageIndex,event.pageSize);
  }

  confirmCode(code){
    this.openConfirmRegistrationCode(code).then(()=>{
      this.getListCodesByFacilitator();
    });
  }

  shareCode(code){}
}
