import { HttpErrorResponse } from '@angular/common/http';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { BaseService, IResponse } from 'app/views/services/base.service';
import * as XLSX from 'xlsx'; // Para archivos XLSX

interface User {
  firstName: string;
  lastName: string;
  email: string;
  // Agrega otros campos si es necesario
}

@Component({
  selector: 'app-dialog-mass-upload',
  templateUrl: './dialog-mass-upload.component.html',
  styleUrls: ['./dialog-mass-upload.component.css']
})
export class DialogMassUploadComponent implements OnInit {

  displayedColumns: string[] = ["id","name","lastname","email","actions"];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  baseService:BaseService;
  courses:Array<any> = [];
  courseId:number = 0;

  constructor(
    injector:Injector,
    public dialogRef: MatDialogRef<DialogMassUploadComponent>,
  ) {
    this.baseService = new BaseService(injector);
  }

  ngOnInit(): void {
    this.getCourses();
  }

  validateFileContent(file: File) {
    if (!file || !file.name) {
      console.error('Archivo no válido.');
      return;
    }

    const reader: FileReader = new FileReader();

    reader.onload = (e: any) => {
      const content = e.target.result;
      const users: User[] = [];

      if (file.name.endsWith('.xlsx')) {
        users.push(...this.parseXLSX(content));
      } else if (file.name.endsWith('.csv')) {
        users.push(...this.parseCSV(content));
      } else {
        this.baseService.openDialog('Tipo de archivo no soportado.');
        return;
      }

      // Verifica si todos los usuarios tienen los campos requeridos
      const isValid = users.every(user => {
        return !!user.firstName && !!user.lastName && !!user.email;
        // Agrega otras validaciones si es necesario
      });

      if (!isValid || !this.fileContentError) {
        this.baseService.openDialog('El contenido del archivo no es válido.');
        return;
      }

      // Verifica si los correos electrónicos son únicos
      const uniqueEmails = new Set();
      const duplicateEmails = new Set();

      users.forEach(user => {
        if (uniqueEmails.has(user.email)) {
          duplicateEmails.add(user.email);
        } else {
          uniqueEmails.add(user.email);
        }
      });

      if (duplicateEmails.size > 0) {
        const errorMessage = `El contenido del archivo no es válido. ${
          duplicateEmails.size > 0
            ? `Los siguientes correos electrónicos están duplicados: ${Array.from(
                duplicateEmails
              ).join(', ')}`
            : ''
        }`;
        this.baseService.openDialog(errorMessage);
        return;
      }

      this.dataSource.data = users;
    };

    if (file.name.endsWith('.xlsx')) {
      reader.readAsBinaryString(file);
    } else if (file.name.endsWith('.csv')) {
      reader.readAsText(file);
    } else {
      this.baseService.openDialog('Tipo de archivo no soportado.');
    }
  }

  private parseXLSX(content: any): User[] {
    const workbook = XLSX.read(content, { type: 'binary' });
    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];
    return XLSX.utils.sheet_to_json(worksheet) as User[];
  }

  fileContentError:boolean = true;
  private parseCSV(content: any): User[] {
    const lines = content.split(/\r\n|\n/);
    const users: User[] = [];

    for (let i = 1; i < lines.length; i++) { // Empezamos desde 1 para omitir la fila de encabezados
      const line = lines[i].trim();
      const fields = line.split(',');

      if (fields.length >= 3) { // Verifica si hay al menos 3 campos: firstName, lastName, email
        const user: User = {
          firstName: fields[0].trim(),
          lastName: fields[1].trim(),
          email: fields[2].trim(),
          // Puedes agregar otros campos aquí según sea necesario
        };

        users.push(user);
      } else {
        console.error('La fila', i + 1, 'no contiene suficientes campos.');
        this.fileContentError = false;
      }
    }
    return users;
  }

  onDelete(user): void {
    const index = this.dataSource.data.findIndex(u => u.email === user.email);
    if (index !== -1) {
      this.baseService.openConfirm(`¿Seguro(a) que desea eliminar la cuenta ${user.email}?`).subscribe(
        (response) => {
          if (response) {
            let data:any[] = this.dataSource.data;
            data.splice(index, 1);
            this.dataSource.data = data;
          }
        }
      );
    }
  }

  onCancel(): void {
    this.baseService.openConfirm(`¿Seguro(a) que desea cancelar la carga de usuarios?`).subscribe(
      (response) => {
        if (response) {
          this.dataSource.data = [];
        }
      }
    );
  }

  onConfirm(): void {
    if(!this.courseId){
      this.baseService.service.openDialog("Seleccione un curso a asignar");
      return;
    }
    this.baseService.openConfirm(`¿Seguro(a) que desea crear las cuentas de participantes?`).subscribe(
      (response) => {
        if (response) {
          this.baseService.loader.open();
          this.baseService.service.usersMassUpload({users: this.dataSource.data, courseId: this.courseId}).then(
            (response) => {
              this.baseService.loader.close();
              let body:IResponse = response.body;
              this.baseService.service.openDialog(body.message).subscribe(()=>{
                this.dialogRef.close(true);
              });
            }
          ).catch(
            (error:HttpErrorResponse) => {
              this.baseService.loader.close();
              this.baseService.service.processHttpResponse(error);
            }
          );
        }
      }
    )
  }

  getCourses(){
    this.baseService.loader.open();
    let params = {
      page:0,
      pageSize:100
    };
    this.baseService.service.getCoursesNoLoggedIn(params).subscribe(
      (response:any) => {
        let body:IResponse = response;
        if (body.success){
          const availableCourses = body.data;
          if (availableCourses?.data instanceof Array && availableCourses?.data.length > 0){
            this.courses = availableCourses.data;
          }
        }else{
          this.baseService.service.openDialog('No se encontraron cursos disponibles.')
        }
        this.baseService.loader.close();
      }, 
      (http:HttpErrorResponse) => {
        this.baseService.loader.close();
        this.baseService.service.processHttpResponse(http);
      }

    );
  }


}
