import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseService } from 'app/views/services/base.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent extends BaseService implements OnInit {

  constructor(injector:Injector) { super(injector); }

  ngOnInit(): void {
    this.seoService.init(this.seoService.getAboutMetaTags());
    this.setExperience();
  }
  
  form:FormGroup = new FormGroup({
    name:new FormControl('',[
      Validators.required
    ]),
    lastname:new FormControl('',[
      Validators.required
    ]),
    email:new FormControl('',[
      Validators.required,
      Validators.email
    ]),
  });
  onSubmit(): void {
    if (this.form.valid) {
      let data:any = this.form.value;
      let values:string[] = [];
      Object.keys(data).forEach(
        (k) => {
          values.push(`${k}=${data[k]}`);
        }
      );
      let url:string = '/signup?'+values.join('&');
      this.router.navigateByUrl(url,{replaceUrl:true});
    } else {
      this.service.openDialog(`Complete el formulario por favor.`);
    }
  }
  
  yearsExperience:number = 22;
  setExperience():void {
    let date:Date = new Date();
    let currentYear:number = date.getFullYear();
    let founded:number = 1999;
    this.yearsExperience = currentYear - founded;
  }
}
