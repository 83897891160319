<app-header-top-sessions></app-header-top-sessions>
<!---->
<div 
  class="container-sessions"
  fxFlex="100"
  fxFlex.xs="98"
  fxLayout="column"
  [ngStyle.xs]="'background:none;'"
  >
  <div 
    style="background-color:none !important;"
    fxFlex="100"
    fxLayout="row"
    fxLayout.sm="column"
    fxLayout.xs="column"
    >
    <div 
      style="background-color:none !important;"
      fxFlex="20"
      fxFlex.sm="100"
      fxLayoutAlign.sm="end center"
      fxFlex.xs="30"
      fxLayoutAlign.xs="center center"
      fxLayoutAlign="center center"
      fxLayout="column"
      [ngClass.xs]="'bg-image-sessions'"
      class="text-white-shadow"
      >
      <h3 style="text-align: left; margin-left:40%; width: 100%;"  [ngStyle.sm]="{'margin-top': '15%', 'width': '50%', 'margin-left': '0%' }"  [ngStyle.xs]="{'margin-top': '15%', 'width': '50%', 'margin-left': '0%' }" ><span [ngClass.xs]="'ts-light'">Bienvenido(a)</span></h3>
      <br>
      <h3 [ngStyle.xs]="{'margin-left': '0%', 'width': '50%' }" [ngStyle.sm]="{'margin-top': '15%', 'width': '50%', 'margin-left': '0%' }"  style="font-size:1em; font-weight: light; text-align: left; margin-left:40%;width: 100%;" ><span [ngClass.xs]="'ts-light text-justify'"></span>Regístrate para utilizar nuestra plataforma de aprendizaje.</h3>
      <br>
    </div>
    <!---->
    <div 
      fxFlex="80"
      class="page-wrap"
      style="height: 100vh;"
      >
      <div class="session-form-hold"> 
        <div class="registration-one__right wow slideInRight" data-wow-delay="100ms"
          data-wow-duration="2500ms">
          <div class="registration-one__right-form">
            <div class="title-box">
              <h4>Restablecer contraseña</h4>
            </div>
            <div class="form-box">
              <form [formGroup]="form" (ngSubmit)="openConfirm()">
                <div class="form-group">
                  <input #passBefore (input)="form.controls['temporalPassword'].markAsTouched()" type="password" matInput formControlName="temporalPassword" placeholder="Código vía correo electrónico" value="">
                  <button class="icon-input-password" type="button" mat-button matSuffix (click)="passBefore.type = (passBefore.type == 'password' ? 'text' : 'password')" [matTooltip]="passBefore.type == 'text' ? 'Mostrar' : 'Ocultar'">
                    <mat-icon style="width: auto !important;">{{passBefore.type == 'password' ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                </div>
                <div class="form-group">
                  <small *ngIf="form.controls['temporalPassword'].hasError('required') && form.controls['temporalPassword'].touched" 
                  class="form-error-msg"> * Requerido</small>
                </div>
                <!---->
                <div class="form-group">
                  <input #passNew (input)="form.controls['newPassword'].markAsTouched()" matInput formControlName="newPassword" placeholder="Nueva contraseña" value="" type="password">
                  <button class="icon-input-password" type="button" mat-button matSuffix (click)="passNew.type = (passNew.type == 'password' ? 'text' : 'password')" [matTooltip]="passNew.type == 'text' ? 'Mostrar' : 'Ocultar'">
                    <mat-icon style="width: auto !important;">{{passNew.type == 'password' ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                </div>
                <div class="form-group">
                  <small *ngIf="form.controls['newPassword'].hasError('required') && form.controls['newPassword'].touched" 
                  class="form-error-msg"> * Requerido</small>
                  <small *ngIf="form.controls['newPassword'].hasError('pattern') && form.controls['newPassword'].touched" 
						      class="form-error-msg"><br> * Ingrese 8 caracteres, 1 letra mayúscula, sin espacios. </small>
                </div>
                <!---->
                <div class="form-group">
                  <input #passConfirm (input)="form.controls['confirmPassword'].markAsTouched()" matInput formControlName="confirmPassword" placeholder="Confirmar contraseña" value="" type="password" >
                  <button class="icon-input-password" type="button" mat-button matSuffix (click)="passConfirm.type = (passConfirm.type == 'password' ? 'text' : 'password')" [matTooltip]="passConfirm.type == 'text' ? 'Mostrar' : 'Ocultar'">
                    <mat-icon style="width: auto !important;">{{passConfirm.type == 'password' ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                </div>
                <div class="form-group">
                  <small *ngIf="form.controls['confirmPassword'].hasError('required') && form.controls['confirmPassword'].touched" 
                    class="form-error-msg"> * Requerido</small>
                  <small *ngIf="form.errors?.mismatch && (form.controls['confirmPassword'].dirty || form.controls['confirmPassword'].touched)" 
                      class="form-error-msg"> * Las contraseñas no coinciden</small>
                  <small *ngIf="form.controls['confirmPassword'].hasError('pattern') && form.controls['confirmPassword'].touched" 
                  class="form-error-msg"><br> * Ingrese 8 caracteres, 1 letra mayúscula, sin espacios. </small>
                </div>
                <button mat-raised-button class="btn btn-purple full-width mb-1 rounded" [disabled]="form.invalid">Cambiar contraseña</button>
                <div class="text-center">
                  <a [routerLink]="'/signin'" class="m-1 hyper mat-primary text-center full-width">Cancelar</a>
                  <span fxFlex></span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxFlex="100" fxFlexFill>
    <app-footer></app-footer>  
  </div>
</div>