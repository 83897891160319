import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseService, ICourse, IResponse, IStripePrice } from 'app/views/services/base.service';
import { Observable } from 'rxjs';
import { DialogCourseComponent } from '../dialog-course/dialog-course.component';

@Component({
  selector: 'app-dialog-promotion',
  templateUrl: './dialog-promotion.component.html',
  styleUrls: ['./dialog-promotion.component.css']
})
export class DialogPromotionComponent extends BaseService implements OnInit {

  public form: FormGroup;
  title:string;
  tipoPromocines:[];
  coursesSelect:FormControl= new FormControl([]);
  courses:any=[];

  constructor(
    public dialogo: MatDialogRef<DialogCourseComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    injector:Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    if(this.data.id==0){
      this.title = "Alta Promoción"
    }else{
      this.title="Editar Promoción";
    }
    this.form = new FormGroup({
      promotion: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
      ]),
      description: new FormControl('', [
        Validators.required,
        Validators.maxLength(2000),
      ]),
      allCoursesApply: new FormControl('', [
        Validators.required
      ]),
      vigencia: new FormControl({ value: '', disabled: true }, Validators.required),
      valor: new FormControl('', [
        Validators.required,
        Validators.pattern("^[0-9]+(.[0-9]{0,2})?$"),
      ]),
      promotionTypeId: new FormControl('', [
        Validators.required
      ]),
      IsCheckedActivo: new FormControl('', [
        Validators.required
      ]),
      course: new FormControl('',[]),
      price: new FormControl('',[])
    });
    this.form.controls.promotion.setValue(this.data.promotion);
    this.form.controls.description.setValue(this.data.description);
    this.form.controls.allCoursesApply.setValue((this.data.allCoursesApply==0?false:true));
    this.form.controls.vigencia.setValue(this.data.vigencia);
    this.form.controls.vigencia.enable();
    if (this.data.id > 0){
      this.form.controls.valor.setValue(this.data.valor);
    }
    this.form.controls.IsCheckedActivo.setValue((this.data.active==0?false:true));
    this.getTypePromotion();
  }

  cerrarDialogo(): void {
    this.dialogo.close(null);
  }

  confirmado(): void {
    let data:any = {};
    let formulario:any;
    try {
      this.form.controls['valor'].enable();
      this.form.controls['promotionTypeId'].enable();
      formulario    = this.form.value;
      if (this.promotionTypeId == 3) {
        data.courseId = formulario.course.courseId;
        data.priceId  = formulario.price.priceId;
      }
      let latest_date = this.datepipe.transform(formulario.vigencia, 'yyyy-MM-dd hh:mm:ss');
      data.id = this.data.id;
      data.promotion = formulario.promotion;
      data.description = formulario.description;
      data.allCoursesApply = formulario.allCoursesApply?1:0;
      data.vigencia = latest_date;
      data.promotionTypeId = formulario.promotionTypeId;
      data.active = formulario.IsCheckedActivo?1:0;
      data.valor = formulario.valor;
      data.exclutionsCourses = formulario.allCoursesApply?[]:this.coursesSelect.value;
      if (this.data.id > 0 && this.data.promotionTypeId == 3) {
        data.promotionId = this.data.id;
        data.rspId = this.data.rspId; 
      }
      //
      let message:string =  (this.data.id)?`actualizar la promoción`:`crear la promoción`; 
      this.service.openConfirm(`¿Seguro(a) que desea ${message}?`)
      .then(
        (response) => {
          if (response) {
            this.dialogo.close(data);
          }
        }
      ); 
    } catch (error) {
      this.openDialog(`Ocurrió un error generar la información de la promoción: ${error.message}`);
    }
  }

  getTypePromotion(){
    this.loader.open();
    this.service.getAllTypePromotion().subscribe(
      (dataResult:any) => {
        if(dataResult.status==200){
          this.tipoPromocines = dataResult.body.content;
          this.loader.close();
          if(this.data.id > 0)
            this.form.controls.promotionTypeId.setValue(this.data.promotionTypeId);

          this.typePromotion(this.data.promotionTypeId);
          this.getAllCourseActive();
        }else{
          this.loader.close();
          this.service.openDialog("No hay tipo de promociones");
        }
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.service.openDialog("Error en el Servicio1204");
      }
    );
  }

  getAllCourseActive(){
    this.loader.open();
    this.service.geetAllCourseActive().subscribe(
      (dataResult:any) => {
        this.loader.close();
        if(dataResult.status==200){
          this.courses = dataResult.body.content;
          this.setExclutionCourses();
          if (this.data.id > 0) {
            this.getPromotionalCourses();
          }
        }else{
          this.service.openDialog("No hay tipo de promociones");
        }
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.service.openDialog("Error en el Servicio1204");
      }
    );
  }

  showOptions(event){
    if(!event.checked){
      this.coursesSelect = new FormControl([],Validators.required);
    }else{
      this.coursesSelect = new FormControl([]);
    }
  }

  typePromotion(type){
    switch(type){
      case 1:
        document.getElementById("moneySign").style.display = "block";
        break;
      case 2:
        document.getElementById("moneySign").style.display = "none";
        break;
      default:
        document.getElementById("moneySign").style.display = "block";
        break;
    }
  }

  setExclutionCourses(): void {
    const arrayString = this.data?.exclutionsCourses?.split(',') || [];
    var arrayInt = arrayString.map(function(x) {
      return parseInt(x);
    });
    this.coursesSelect.setValue(arrayInt);
  }

  setPromotionTypeId(promotionTypeId:number) {
    this.promotionTypeId = promotionTypeId;
    this.form.setControl('course',new FormControl('',[]));
    this.form.setControl('price',new FormControl('',[]));
    if (promotionTypeId == 3) {
      if (this.data.id > 0) this.service.openSnackBar(`Solo es posible editar promociones del mismo tipo`);
      let control:AbstractControl = this.form.controls['valor'];
      control.setValue('');
      control.disable();
      this.form.setControl('course',new FormControl('',[Validators.required]));
      this.form.setControl('price',new FormControl('',[Validators.required]));
      this.getPromotionalCourses();
    }
  }

  setCourse(course:ICourse,prices = true): void {
    this.course = course;
    if (this.data.id <= 0) {
      if (prices) this.getCoursePrices();
    }
    if (this.data.id > 0 && this.data.promotionTypeId == 3) {
      this.form.controls['course'].setValue(course);
      if (prices) this.getCoursePrices();
    }
  }

  setPrice(price:IStripePrice): void {
    this.price = price;
    let control:AbstractControl = this.form.controls['valor'];
    control.setValue(price.promotionAmount);
    control.disable();
    if (this.data.id > 0 && this.data.promotionTypeId == 3) {
      this.form.controls['price'].setValue(price);
      this.promotionTypeId = 3;
      this.form.controls['promotionTypeId'].disable();
    }
  }

  promotionTypeId:any;
  price:IStripePrice;
  coursePrices:IStripePrice[] = [];
  course:ICourse;
  promotionalCourses:ICourse[] = [];
  getPromotionalCourses() {
    let data:any = {
      page:0,
      pageSize:50,
    };
    this.loader.open();
    return this.service.getPromotionalCourses(data).subscribe(
      (response) => {
        this.loader.close();
        const body:IResponse = response.body;
        if (!body.success) {
          this.openDialog(`${body.message}`);
          return;
        }
        this.promotionalCourses = body.data.data;
        if (this.data.id > 0 && this.data.promotionTypeId == 3) {
          let course:ICourse = this.promotionalCourses.find(c => c.courseId === this.data.courseId);
          if (course) {
            this.setCourse(course,false);
          }
          this.getCoursePrices();
        }
      },
      (error:HttpErrorResponse) => {
        this.loader.close();
        this.proccessHttpResponse(error);
      }
    );
  }

  getCoursePrices(): void {
    let data:any = {
      courseId:this.course.courseId,
      page:0,
      pageSize:50,
      priceTypeId:2,//Precios tipo promoción
    };
    this.loader.open();
    this.service.getCoursePrices(data).subscribe(
      (response) => {
        this.loader.close();
        const body:IResponse = response.body;
        if (!body.success) {
          this.openDialog(`${body.message}`);
          return;
        }
        this.coursePrices = body.data.coursePrices.data;
        if (this.data.id > 0 && this.data.promotionTypeId == 3) {
          let price:IStripePrice = this.coursePrices.find(p => p.priceId == this.data.priceId);
          if (price) {
            this.setPrice(price);
          }
        }
      },
      (error:HttpErrorResponse) => {
        this.loader.close();
        this.proccessHttpResponse(error);
      }
    );
  }

}
