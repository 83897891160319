<div fxLayout="row" fxLayoutAlign="space-between center" >
  <p mat-dialog-title class="text-purple text-bold" style="margin: 0px;">{{data.title}}</p>
  <button mat-icon-button aria-label="close icon" (click)="cerrarDialogo()"  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content style="max-width: 780px;">
  <hr>
  <div class="f-18" [innerHTML]="sanitizeText(data.content)"></div>
  <hr>
  <span style="color:gray;">{{data.at}}</span>
</div>
<div mat-dialog-actions align="end" *ngIf="viewButton">
  <button class="btn-purple" mat-button (click)="cerrarDialogo()">
    <mat-label class="m-1">Cancelar</mat-label>
    <mat-icon aria-hidden="false">cancel</mat-icon>
  </button>
  <button class="btn-green" mat-button (click)="confirmado()">
    <mat-label class="m-1">Confirmar</mat-label>
    <mat-icon aria-hidden="false">check_circle</mat-icon>
  </button>
</div>
<div fxLayout="row" fxLayoutAlign="center center" mat-dialog-actions *ngIf="!viewButton">
  <button class="btn-green" mat-button [mat-dialog-close]="true">
    Aceptar
    <mat-icon class="m-1">check_circle_outline</mat-icon>
  </button>
</div>
