<app-header-top-sessions></app-header-top-sessions>
<div fxFlex="100" fxLayout="column" fxLayoutAlign="center center" class="wh-100" style="height: 80vh;">
  <div fxFlex="50" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="2%" class="wh-100 centrado">
    <div>
      <h2 class="text-purple text-center">¡Lo sentimos! Compra cancelada o no procesada.</h2>
    </div>
    <div>
      <span class="text-bold text-purple">Regresa al app o expora los cursos desde la web ¿Qué desea realizar?</span>
    </div>
    <button *ngIf="userInfo?.checkoutUrl != null" mat-flat-button class="btn-green w-280px" (click)="openCheckoutUrl()">Intentar compra nuevamente</button> 
    <button [routerLink]="[COURSES_AUTH_ROUTE]" mat-flat-button class="btn-purple w-280px">Explorar cursos</button>
  </div>
  <footer fxFlex="30" fxLayout="column" style="left: 0%;
  bottom: 0%;
  position: fixed;
  width: 100%;">
    <app-footer></app-footer>
  </footer>
</div>