import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { DialogConfirmComponent } from 'app/views/dialogs/dialog-confirm/dialog-confirm.component';
import { AdminService } from 'app/views/services/admin.service';
import { CertificatesInstructor } from 'app/views/shared/classes';
import { Profile } from '../profile';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { API } from '../../../shared/api';
import { CertificationDialogComponent } from 'app/views/dialogs/certification-dialog/certification-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  public regexPswd:RegExp = /^(?!.* )(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,20}$/;
  public form: FormGroup;
  public imageUrl:any = 'assets/images/entrepeneurs/not-found.png';
  public imagePath:any;
  public message:any = 'Seleccione una foto.';
  public infoUser:any;
  public imageProfileFile:any;
  public certifications:Array<CertificatesInstructor>;
  public countriesArray:any;
  public statesArray:any;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  public isMentory:boolean=false;
  public courseId_:any= -1;
  public fromCompraCursos:boolean = false;

  constructor(
    private service: AdminService,
    private dialog: MatDialog,
    private loader: AppLoaderService,
    private profileInfo: Profile,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.createForm();
    let userSession = JSON.parse(localStorage.getItem('userInfo'));
    this.infoUser = {
      id: userSession.id,
      name: userSession.name,
      lastName: userSession.lastName,
      username: userSession.username,
      email: userSession.email,
      telephone: userSession.telephone,
      description: userSession.description,
      genderId: userSession.genderId,
      birthdate: userSession.birthdate,
      rfc: userSession.rfc,
      postalCode: userSession.postalCode,
      settlement: userSession.settlement,
      settlementType: userSession.settlementType,
      municipality: userSession.municipality,
      state_id: userSession.state_id,
      city: userSession.city,
      country: userSession.country,
      address: userSession.address,
      exteriorNumber: userSession.exteriorNumber,
      interiorNumber: userSession.interiorNumber,
      interest: userSession.interest,
      password:'',
      newPassword:'',
      roleId: userSession.roleId,
      title: userSession.title,
      imageUrl: userSession.imageUrl,
      profile_public: userSession.profile_public
    };
    this.imageUrl = API.URL_BASE_STORAGE+this.infoUser.imageUrl;
    this.form.controls.name.setValue(this.infoUser.name);
    this.form.controls.last_name.setValue(this.infoUser.lastName);
    // this.form.controls.username.setValue(this.infoUser.username);
    this.form.controls.description.setValue(this.infoUser.description);
    // this.form.controls.email.setValue(this.infoUser.email);
    // this.form.controls.telephone.setValue(this.infoUser.telephone);
    // this.form.controls.genderId.setValue(this.infoUser.genderId);
    // this.form.controls.birthdate.setValue(this.infoUser.birthdate);
    // this.form.controls.rfc.setValue(this.infoUser.rfc);
    // this.form.controls.postalCode.setValue(this.infoUser.postalCode);
    // this.form.controls.settlement.setValue(this.infoUser.settlement);
    // this.form.controls.settlementType.setValue(this.infoUser.settlementType);
    // this.form.controls.municipality.setValue(this.infoUser.municipality);
    // this.form.controls.state.setValue(this.infoUser.state);
    // this.form.controls.city.setValue(this.infoUser.city);
    this.form.controls.country.setValue(this.infoUser.country);
    this.form.controls.state_id.setValue(this.infoUser.state_id);
    this.form.controls.profile_public.setValue((this.infoUser.profile_public==1));
    // this.form.controls.address.setValue(this.infoUser.address);
    // this.form.controls.exteriorNumber.setValue(this.infoUser.exteriorNumber);
    // this.form.controls.interiorNumber.setValue(this.infoUser.interiorNumber);
    this.form.controls.interests.setValue(this.infoUser.interest);
    this.form.controls.title.setValue(this.infoUser.title);
    this.courseId_ = this.route.snapshot.queryParamMap.get('courseId');
    this.isMentory =  this.route.snapshot.queryParamMap.get('isMentory') != null ? JSON.parse(this.route.snapshot.queryParamMap.get('isMentory'))  : false ;
    if(this.infoUser.roleId==2){
      this.getinfoInstructor(userSession.id);
    }else{
      this.getCountries();
    }
  }

  createForm(){
    this.form = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(50)
      ]),
      last_name: new FormControl('', [
        Validators.required,
        Validators.maxLength(50)
      ]),
      mothers_last_name: new FormControl('', [
        // Validators.required,
      ]),
      description: new FormControl('', [
        Validators.required,
        Validators.maxLength(2000)
      ]),
      country: new FormControl('', [
        Validators.required,
      ]),
      state_id: new FormControl('', [
        Validators.required,
      ]),
      profile_public: new FormControl('', [
        Validators.required,
      ]),
      password: new FormControl('', [
        Validators.maxLength(50)
        // Validators.required,
      ]),
      password_new: new FormControl('', [
        Validators.maxLength(50),
        // Validators.pattern(this.regexPswd)
      ]),
      password_confirm: new FormControl('', [
        Validators.maxLength(50),
        // Validators.pattern(this.regexPswd)
      ]),
      image: new FormControl('', [
        // Validators.required,
      ]),
      interests: new FormControl('', [
        Validators.maxLength(2000)
        // Validators.required,
      ]),
      title: new FormControl('', [
        Validators.maxLength(500)
        // Validators.required,
      ]),
    },{validators: this.passwordMatchValidator});
  }

  passwordMatchValidator(frm: FormGroup) {
    return frm.controls['password_new'].value === frm.controls['password_confirm'].value ? null : {'mismatch': true};
  }

  onFileChanged(event) {
    const files = event.target.files;
    if (files.length === 0)
      return;

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.service.openDialog('Archivo incorrecto, sube una foto por favor');
      return;
    }
    this.imageProfileFile = files[0];
    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
      this.imageUrl = reader.result; 
      this.message = 'Foto seleccionada.'
      this.form.controls['image'].setValue(this.imageUrl);
    }
  }

  fileUploaded():boolean{
    return (this.imagePath != null && this.imagePath.length > 0)?true:false;
  }

  removeFile(){
    this.imagePath = null;
    this.imageUrl = 'assets/images/entrepeneurs/not-found.png';
    this.message = 'Seleccione una foto.'
    this.imageProfileFile = null;
  }

  uploadImageProfile(){
    this.loader.open('Guardando imagen..');
    let formData = new FormData();
    formData.append('imagen',this.imageProfileFile);
    formData.append('idUser',this.infoUser.id);
    this.service.updateImageProfile(formData).subscribe(
      (data:any)=>{
        this.loader.close();
        this.service.openDialog('Se actualizo la imagen correctamente.');
        this.imageProfileFile = null;
        this.imagePath = null;
        if(data.data){
          let userSession = JSON.parse(localStorage.getItem('userInfo'));
          userSession.imageUrl = data.data;
          this.infoUser.imageUrl = data.data;
          this.imageUrl = API.URL_BASE_STORAGE + userSession.imageUrl;
          this.profileInfo.setinfoProfile({ username: this.infoUser.username, imagenURL: data.data });
          localStorage.setItem('userInfo',JSON.stringify(userSession));
        }
      },
      (error)=>{
        this.loader.close();
        this.service.openDialog('Error al subir la imagen intente de nuevo.');
      }
    )
  }

  openConfirmDialog(){
    if (this.form.valid){
      
      const dialog = this.dialog.open(DialogConfirmComponent, {
        data: {
          title: '',
          message: '¿Seguro(a) que desea guardar los cambios?',
          confirm: true
        }
      }).afterClosed().subscribe(result => {
        console.log('subscribe(result',result);
        if (result == true){
          this.loader.open('Guardando informacion del usuario..');
          this.infoUser.name = this.form.controls.name.value;
          this.infoUser.lastName = this.form.controls.last_name.value;
          this.infoUser.description = this.form.controls.description.value;
          this.infoUser.country = this.form.controls.country.value;
          this.infoUser.state_id = this.form.controls.state_id.value;
          this.infoUser.password = this.form.controls.password.value;
          this.infoUser.newPassword = this.form.controls.password_new.value;
          this.infoUser.interest = this.form.controls.interests.value;
          this.infoUser.title = this.form.controls.title.value;
          this.infoUser.certifications = this.certifications;
          this.infoUser.profile_public = this.form.controls.profile_public.value;
          this.service.updateProfile(this.infoUser).subscribe(
            (data:any)=>{
              this.loader.close();
              if(data.error==1){
                this.service.openDialog('Hubo un error al actualizar la informacion, intente de nuevo.');
              }else{
                let userSession = JSON.parse(localStorage.getItem('userInfo'));
                userSession.name = this.infoUser.name;
                userSession.lastName = this.infoUser.lastName;
                userSession.description = this.infoUser.description;
                userSession.country = this.infoUser.country;
                userSession.state_id = this.infoUser.state_id;
                userSession.interest = this.infoUser.interest;
                userSession.title = this.infoUser.title;
                userSession.profile_public = (this.infoUser.profile_public ? 1 : 0);
                localStorage.setItem('userInfo',JSON.stringify(userSession));
                this.service.openDialog('Se actualizaron los datos correctamente.').subscribe(
                  () => {
                    if(this.courseId_ != null){
                      this.router.navigate(['/dashboard/course/buy/',this.courseId_,{isMentoria: this.isMentory}]);
                    }else{
                      window.location.reload();
                    }
                    
                    
                  }
                )
              }
            },
            (error)=>{
              this.loader.close();
              this.service.openDialog('Hubo un error al actualizar la informacion, intente de nuevo.');
            }
          );
        }
      });
    }
  }

  getinfoInstructor(id:number)
  {
    this.loader.open('Obteniendo informacion del instructor..');
    this.service.getInfoInstructor(id).subscribe(
      (data:any)=>{
        this.certifications = data.data.certifications;
        // this.infoInstructor = data.data.infoInstructor;
        this.loader.close();
        this.getCountries();
      },
      (error)=>{
        this.service.openDialog('No se pudo obtener informacion del instructor, intente de nuevo');
        this.loader.close();
      },
    )
  }

  addCertification(): void {
    const dialogRef = this.dialog.open(CertificationDialogComponent, {
      disableClose: true,
      width: '350px',
      data: null
    });

    dialogRef.afterClosed().subscribe(certification => {
      if (certification) {
        this.certifications.push(certification);
      }
    });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.certifications.push({certification: value.trim(), id: 0, user_id: this.infoUser.id, image_id:1});
    }
    if (input) {
      input.value = '';
    }
  }

  remove(certifications: CertificatesInstructor): void {
    const index = this.certifications.indexOf(certifications);
    if (index >= 0) {
      this.certifications.splice(index, 1);
    }
  }

  getCountries(){
    this.loader.open('Obteniendo informacion del perfil..');
    this.service.getCountries().subscribe(
      (data:any)=>{
        this.countriesArray = (data.success==1) ? data.data : [];
        let countryId = Number(this.infoUser.country);
        if(countryId>0){
          this.getStates(countryId);
        }else{
          this.loader.close();
        }
      },
      (error)=>{
        // this.service.openDialog('recarge la pagina nuevamente');
        this.loader.close();
      }
    );
  }

  public getStates(countryId:any,loader:boolean=false){
    if(loader) this.loader.open('Buscando estados...');
    this.service.getStates(countryId).subscribe(
      (data:any)=>{
        this.statesArray = (data.success==1) ? data.data : [];
      },
      (error)=>{},
      ()=>{
        this.loader.close();
      }
    );
  }

}
