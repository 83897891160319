import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseService, IResponse } from 'app/views/services/base.service';
import moment from 'moment';
moment.locale('es-MX');

@Component({
  selector: 'app-dialog-confirm-registration-code',
  templateUrl: './dialog-confirm-registration-code.component.html',
  styleUrls: ['./dialog-confirm-registration-code.component.css']
})
export class DialogConfirmRegistrationCodeComponent implements OnInit {
  baseService:BaseService;
  firstFormGroup:FormGroup;
  errorMessages:any;

  constructor(
    injector:Injector,
    public dialog: MatDialogRef<DialogConfirmRegistrationCodeComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) {
    this.baseService = new BaseService(injector);
  }

  ngOnInit(): void {
    this.createForms();
    this.firstFormGroup.controls.codeSessionId.setValue(this.data.id);
    this.firstFormGroup.controls.facilitatorId.setValue(this.data.facilitatorId);
    this.firstFormGroup.controls.courseId.setValue(this.data.courseId);
    this.firstFormGroup.controls.moduleId.setValue(this.data.moduleId);
    this.firstFormGroup.controls.codeSession.setValue(this.data.codeSession);
    if(this.data.meetUrl){
      this.firstFormGroup.controls.meetUrl.setValue(this.data.meetUrl);
    }
    if(this.data.startsAt){
      this.firstFormGroup.controls.startsAt.setValue(moment(this.data.startsAt));
    }
    if(this.data.endsAt){
      this.firstFormGroup.controls.endsAt.setValue(moment(this.data.endsAt));
    }
  }

  createForms(){
    this.firstFormGroup = this.baseService.formBuilder.group({
      codeSessionId: ['', [
        Validators.required
      ]],
      facilitatorId: ['', [
        Validators.required
      ]],
      courseId: ['', [
        Validators.required
      ]],
      moduleId: ['', [
        Validators.required
      ]],
      codeSession: ['', [
        Validators.required
      ]],
      meetUrl: ['', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(455)
      ]],
      startsAt: ['', [
        Validators.required
      ]],
      endsAt: ['', [
        Validators.required
      ]],
    });
    this.errorMessages = {
      meetUrl:[
        { type: 'required',  message: '* Seleccione una fecha inicio.' },
        { type: 'minlength', message: '* Al menos 3 caracteres.' },
        { type: 'maxlength', message: '* No más de 455 caracteres.' },
      ],
      startsAt:[
        { type: 'required',  message: '* Seleccione una fecha inicio.' }
      ],
      endsAt:[
        { type: 'required',  message: '* Seleccione una fecha de finalización.' }
      ],
    };
  }

  get meetUrl(): AbstractControl {
    return this.firstFormGroup.controls['meetUrl'];
  }

  get startsAt(): AbstractControl {
    return this.firstFormGroup.controls['startsAt'];
  }

  get endsAt(): AbstractControl {
    return this.firstFormGroup.controls['endsAt'];
  }

  confirmCode(){
    this.baseService.openConfirm(`¿Seguro(a) que desea guardar los cambios y activar el código de inscripción?`).subscribe(
      (response)=>{
        if(response){
          this.baseService.loader.open();
          let user = this.baseService.storage.getUserInfo();
          let data = {
            id: this.firstFormGroup.value.codeSessionId,
            meetUrl: this.firstFormGroup.value.meetUrl,
            modifiedBy: user.id,
            startsAt: moment(this.firstFormGroup.value.startsAt).format('yyyy-MM-DD HH:mm:ss'),
            endsAt: moment(this.firstFormGroup.value.endsAt).format('yyyy-MM-DD HH:mm:ss'),
          };
          this.baseService.service.confirmCodeSession(data).then(
            (result:any)=>{
              let body:IResponse = result.body;
              this.baseService.loader.close();
              this.baseService.service.openDialog(body.message);
              if(body.success){
                this.closeDialog();
              }
            }
          ).catch(
            (error:HttpErrorResponse)=>{
              this.baseService.loader.close();
              this.baseService.proccessHttpResponse(error);
            }
          );
        }
      },
      (error)=>{
        console.log(error);
      }
    )
  }

  closeDialog(){
    this.dialog.close();
  }

}
