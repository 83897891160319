<div fxLayout="row" fxLayoutAlign="space-between center" >
  <p mat-dialog-title class="text-purple text-bold" style="margin: 0px;">{{title}}</p>
  <button mat-icon-button aria-label="close icon" (click)="cerrarDialogo()"  >
      <mat-icon>close</mat-icon>
  </button>
</div>

<form [formGroup]="form" (ngSubmit)="confirmado()">
<div mat-dialog-content style="overflow-x: clip;">
  <div class="w-100" fxFlex="100" fxLayout="column" fxLayoutGap="1%">
    <!---->
    <div class="w-100" fxFlex="100" fxLayout="column" fxLayoutGap="1%">
      <div fxFlex="100">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Tipo Promoción</mat-label>
          <mat-select formControlName="promotionTypeId" (selectionChange)="setPromotionTypeId($event.value)">
            <mat-option *ngFor="let tp of tipoPromocines" [value]="tp.id">
              {{ tp.promotion_type}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <small *ngIf="form.controls['promotionTypeId'].hasError('required') && form.controls['promotionTypeId'].touched" class="form-error-msg">
          * Requerido.
        </small>
      </div>
      <!---->
      <div fxFlex="100" [style.display]="promotionTypeId == 3 || data.promotionTypeId == 3 ? 'flex' : 'none'">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Cursos elegibles para promoción</mat-label>
          <mat-select formControlName="course" (selectionChange)="setCourse($event.value)">
            <mat-option *ngFor="let pc of promotionalCourses" [value]="pc">
              {{ pc.course }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!---->
      <div fxFlex="100" [style.display]="promotionTypeId == 3 || data.promotionTypeId == 3 ? 'flex' : 'none'">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Precios elegibles para promoción</mat-label>
          <mat-select formControlName="price" (selectionChange)="setPrice($event.value)">
            <mat-option *ngFor="let cp of coursePrices" [value]="cp">
              Precio original: $ {{cp.salePrice}} | Precio con promoción: $ {{cp.unitAmount}} <span style="text-transform:uppercase;">{{cp.currency}}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <!---->
    <div fxFlex="100" fxLayout="column" fxLayoutGap="1%">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Nombre de la promoción</mat-label>
        <input matInput placeholder="Promoción" formControlName="promotion">
      </mat-form-field>
      <small *ngIf="form.controls['promotion'].hasError('required') && form.controls['promotion'].touched" class="form-error-msg">
        * Requerido.
      </small>
      <small *ngIf="form.controls['promotion'].hasError('maxlength') && form.controls['promotion'].touched" class="form-error-msg">
        * Máximo 100 caracteres.
      </small>
    </div>
    <!---->
    <div fxFlex="100" fxLayout="column" fxLayoutGap="1%">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Descripción</mat-label>
        <input matInput placeholder="Descripción" formControlName="description">
      </mat-form-field>
      <small *ngIf="form.controls['description'].hasError('required') && form.controls['description'].touched" class="form-error-msg">
        * Requerido.
      </small>
      <small *ngIf="form.controls['description'].hasError('maxlength') && form.controls['description'].touched" class="form-error-msg">
        * Máximo 2000 caracteres.
      </small>
    </div>      
    <!---->
    <div [style.display]="promotionTypeId == 3 ? 'none' : 'flex'" class="w-100" fxFlex="100" fxLayout="row" fxLayoutGap="1%" fxLayoutAlign="space-between center">
      <div fxFlex="50">
        <mat-checkbox id="checkbox-all" class="example-margin" (change)="showOptions($event)" formControlName="allCoursesApply">Aplica a todos los cursos</mat-checkbox>
      </div>
      <div fxFlex="50">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Cursos que no aplican</mat-label>
          <mat-select [formControl]="coursesSelect" multiple [disabled]="(this.form.value).allCoursesApply">
            <mat-option *ngFor="let item of courses" [value]="item.id">{{item.lavelCourse}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <!---->
    <div class="w-100" fxFlex="100" fxLayout="row" fxLayoutGap="1%">
      <div fxFlex="50">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Vigencia</mat-label>
          <input matInput [matDatepicker]="dp3" (click)="dp3.open()" formControlName="vigencia" disabled>
          <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
          <mat-datepicker #dp3 disabled="false"></mat-datepicker>
        </mat-form-field>
        <small *ngIf="form.controls['vigencia'].hasError('required') && form.controls['vigencia'].touched" class="form-error-msg">
          * Requerido.
        </small>
      </div>
      <!---->
      <div fxFlex="50">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Valor</mat-label>
          <mat-icon id="moneySign" matPrefix>attach_money</mat-icon>
          <input matInput placeholder="300.00" formControlName="valor" type="number">
        </mat-form-field>
        <small *ngIf="form.controls['valor'].hasError('required') && form.controls['valor'].touched" class="form-error-msg">
          * Requerido.
        </small>
        <small *ngIf="form.controls['valor'].hasError('pattern') && form.controls['valor'].touched" class="form-error-msg">
          * Solo número.
        </small>
      </div>
    </div>
    <!---->
    <div class="w-100" fxFlex="100" fxLayout="row" fxLayoutGap="1%">
      <div flex="">
        <mat-checkbox style="display:none;" class="example-margin" formControlName="IsCheckedActivo">Activo</mat-checkbox>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-button type="button" class="btn-purple" (click)="cerrarDialogo()">
    <mat-label class="m-1">Cancelar</mat-label>
    <mat-icon aria-hidden="false">cancel_outline</mat-icon>
  </button>
  <button mat-button type="submit" class="btn-green" [disabled]="!form.valid">
    <mat-label class="m-1">Confirmar</mat-label>
    <mat-icon aria-hidden="false">check_circle_outline</mat-icon>
  </button>
</div>
</form>
  