import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { AdminService } from 'app/views/services/admin.service';
import { RoleService } from 'app/views/services/role.service';
import { FormGroup, FormControl, Validators, FormsModule } from '@angular/forms';
import Echo from 'laravel-echo';
import { API, ECHO_PROD, ECHO_TEST } from 'app/views/shared/api';
import { modalMultimedia } from 'app/views/shared/classes';
import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';

@Component({
  selector: 'app-game-instructor',
  templateUrl: './game-instructor.component.html',
  styleUrls: ['./game-instructor.component.css']
})
export class GameInstructorComponent implements OnInit, AfterViewInit {

  totalDias: any = 30;
  dia: any;
  contDias: any;
  semana: any;
  limiteDia: any;
  charUrl: string;
  montosXEquipo: Array<any> = [];
  textoDia:String = "";
  slides: any[];

  echoEvent: any;
  users: Array<any> = [];
  colors: Array<any> = [];
  myInfoUserSession: any;
  products: Array<any>;
  teamSelected: number;

  productPrice: number;
  teamInputValue: number;

  //Tiempo
  calendarDay: any;
  dates: Array<any> = new Array();
  seconds: number = 0;
  time: any;
  interval;
  week: number;

  //Cantidades del usuario
  leekels: number;
  inversion: number;
  gasto: number;
  ahorro: number;
  deuda: number;
  materiaPrima: number;
  montosForm: FormGroup;

  totalLeekels: number = 200;
  mondayDays: number[] = [5, 12, 19, 26];  //Días que son Lunes
  tuesdayDays: number[] = [6, 13, 20, 27];  //Días que son Martes
  wednesdayDays: number[] = [7, 14, 21, 28];  //Días que son Miercoles
  thursdayDays: number[] = [8, 15, 22, 29];  //Días que son Jueves
  fridayDays: number[] = [2, 9, 16, 23, 30];//Días que son Viernes
  saturdayDays: number[] = [3, 10, 17, 24];  //Días que son Sábado
  sundayDays: number[] = [4, 11, 18, 25];  //Días que son Domingo

  constructor(public services: AdminService, public role: RoleService, private router: Router, private loader: AppLoaderService) {
    // Configuracion general de pusher
    let e = (API.PROD)?ECHO_PROD:ECHO_TEST;
    this.echoEvent = new Echo(e);
    this.inversion = 0;
    this.gasto = 0;
    this.ahorro = 0;
    //this.slides= new Array();
    this.montosForm = new FormGroup({
      inversion: new FormControl('0', [Validators.required]),
      gasto: new FormControl('0', [Validators.required]),
      ahorro: new FormControl('0', [Validators.required]),
      team: new FormControl('')
    });
  }

  ngOnInit(): void {
    //agregar el remove solo testing
    /* localStorage.removeItem('roulettePrize');
    localStorage.removeItem('currentCard'); */
    
    localStorage.removeItem('isSignature');
    let codigoAcceso = localStorage.getItem('codigoAcceso');
    this.notesInput = `Notas:\nSesión: ${codigoAcceso}`;
    this.dia = "Jueves";
    this.contDias = 1;
    this.semana = 1;
    this.updatedDayEvent();
    this.getCalendarDay();
    this.startTimer();
  }

  ngAfterViewInit(){
    //this.setInstrucciones(1);
  }

  calendario() {
    switch (this.dia) {
      case "Lunes":
        this.contDias++;
        return "Martes";
      case "Martes":
        this.contDias++;
        return "Miercoles";
      case "Miercoles":
        this.contDias++;
        return "Jueves";
      case "Jueves":
        this.contDias++;
        return "Viernes";
      case "Viernes":
        this.contDias++;
        return "Sabado";
      case "Sabado":
        this.contDias++;
        return "Domingo";
      case "Domingo":
        this.contDias++;
        this.semana++;
        return "Lunes";
    }
  }

  public getColorValue(eq:any){
    var colorBg:string = 'white';

    if(this.colors == null){
      return colorBg;
    }

    for (let index = 0; index < this.colors.length; index++) {
      const color = this.colors[index];
      if(color.team == eq.team){
        colorBg = color.color;
      }
      
    }
    
    return colorBg;
  }

  dayName(id) {
    if (id == 1 || id == 8 || id == 15 || id == 22 || id == 29) {
      return "Jueves"
    }
    if (id == 2 || id == 9 || id == 16 || id == 23 || id == 30) {
      return "Viernes"
    }
    if (id == 3 || id == 10 || id == 17 || id == 24) {
      return "Sábado"
    }
    if (id == 4 || id == 11 || id == 18 || id == 25) {
      return "Domingo"
    }
    if (id == 5 || id == 12 || id == 19 || id == 26) {
      return "Lunes";
    }
    if (id == 6 || id == 13 || id == 20 || id == 27) {
      return "Martes";
    }
    if (id == 7 || id == 14 || id == 21 || id == 28) {
      return "Miércoles"
    }
  }

  public setInstrucciones(id:any = 1){
    var urlBase:string = "./assets/images/instrucciones/";
    switch (id) {
      case 1:
        this.slides = [
          { imageUrl : urlBase+"dia1.1.png", textoDia: ["Asignar equipos a cada uno de los alumnos conectados (rosa, azul, rojo, etc.)","Asignar un líder a cada equipos","Los alumnos pueden ver el 1er video","Los alumnos pueden darle al botón 'Siguiente' para ver el 2do video"] },
          { imageUrl : urlBase+"dia1.2.png", textoDia: ["En esta pantalla los alumnos pueden ver el 2do video", "Al terminar el video, deben dar al botón 'Siguiente' "]   },
          { imageUrl : urlBase+"dia1.3.png", textoDia: ["Se les recuerda a los alumnos que sólo el alumno líder puede firmar el contrato en pantalla","Una vez que el alumno líder firme, a sus compañeros de su mismo equipo verán el documento firmado","Los alumnos pueden darle al botón 'Siguiente' para ver la presentación de Ricardo, Vanesa, etc."]},
          { imageUrl : urlBase+"dia1.4.png", textoDia: ["Los alumnos tienen que darle 'Siguiente' para ver el siguiente personaje:","Ricardo Riqueza","Alberto El Ahorrador","Susana Sensata","Vanesa Voraz","También se tiene la posibilidad de retroceder con el botón 'Anterior'"]},
          { imageUrl : urlBase+"dia1.5.png", textoDia: ["Al termino de los personajes, se menciona: 'Espera a que el instructor cambie al día siguiente'"]}
        ];
        this.words = this.slides[0].textoDia;
        break;
      case 2:
        this.slides = [];
        this.maxSlides = 0;
          this.slides = [
            { imageUrl : urlBase+"dia2.png", textoDia: ["El alumno líder debe establecer las cantidades Inversión - Gasto - Ahorro"] }
          ];
          this.words = this.slides[0].textoDia;
          break;
      case 3:
            this.slides = [
              { imageUrl : urlBase+"dia3.png", textoDia: ["Los alumnos visualizan los productos disponibles en la tienda","Hora de vender los productos a cada equipo, resta la cantidad que hay en GASTOS","Si no le alcanza, el alumno líder puede volver a establecer las cantidades IGA"] }
            ];
            this.words = this.slides[0].textoDia;
            break;
      case 4:
            this.slides = [
              { imageUrl : urlBase+"dia4.png", textoDia: ["Los alumnos visualizan la imagen del domingo (una persona descansando)"] }
            ];
            this.words = this.slides[0].textoDia;
            break;
      case 5:
            this.slides = [
              { imageUrl : urlBase+"dia5.png", textoDia: ["Los alumnos visualizan la imagen de 'Proveedores de Luis'","Se le vende materia prima al alumno, resta la cantidad que hay en INVERSIÓN","Si no le alcanza, el alumno líder puede volver a establecer las cantidades IGA"] }
            ];
            this.words = this.slides[0].textoDia;
            break;
      case 6:
            this.slides = [
              { imageUrl : urlBase+"dia6.png", textoDia: ["Los alumnos visualizan la imagen de 'Una fábrica'","Se menciona a los alumnos que deben fabricar su producto a vender"] }
            ];
            this.words = this.slides[0].textoDia;
            break;
      case 7:
            this.slides = [
              { imageUrl : urlBase+"dia7.png", textoDia: ["Los alumnos visualizan la imagen de 'La empresa de María'","Se menciona a los alumnos que vender su producto","Los leekels que se paguen al alumno, se suman a los LEEKELS totales del equipo"] }
            ];
            this.words = this.slides[0].textoDia;
            break;
      case 8:
            this.slides = [
              { imageUrl : urlBase+"dia8.1.png", textoDia: ["Los alumnos visualizan la imagen de 'Un banco' y el botón de un video a la derecha",
              "En este día puedes pedir prestado LEEKELS al banco estos se suman a los LEEKELS totales y aumentar su deuda"] },
              { imageUrl : urlBase+"dia8.2.png", textoDia: ["Si presionan el botón de 'Video', los alumnos pueden ver el video"] }
            ];
            this.words = this.slides[0].textoDia;
            break;
      case 9:
            this.slides = [
              { imageUrl : urlBase+"dia9.png", textoDia: ["El alumno líder debe establecer las cantidades Inversión - Gasto - Ahorro"] }
            ];
            this.words = this.slides[0].textoDia;
            break;
      case 10:
            this.slides = [
              { imageUrl : urlBase+"dia10.png", textoDia: ["Los alumnos visualizan los productos disponibles en la tienda",
              "Hora de vender los productos a cada equipo, resta la cantidad que hay en GASTOS",
              "Si no le alcanza, el alumno líder puede volver a establecer las cantidades IGA"] }
            ];
            this.words = this.slides[0].textoDia;
            break;
      case 11:
            this.slides = [
              { imageUrl : urlBase+"dia11.png", textoDia: ["Los alumnos visualizan la imagen del domingo (una persona descansando)"] }
            ];
            this.words = this.slides[0].textoDia;
            break;
      case 12:
            this.slides = [
              { imageUrl : urlBase+"dia12.png", textoDia: ["Los alumnos visualizan la imagen de 'Proveedores de Luis'",
              "Se le vende materia prima al alumno, resta la cantidad que hay en INVERSIÓN",
              "Si no le alcanza, el alumno líder puede volver a establecer las cantidades IGA"] }
            ];
            this.words = this.slides[0].textoDia;
            break;
      case 13:
            this.slides = [
              { imageUrl : urlBase+"dia13.png", textoDia: ["Los alumnos visualizan la imagen de 'Una fábrica'",
              "Se menciona a los alumnos que deben fabricar su producto a vender"] }
            ];
            this.words = this.slides[0].textoDia;
            break;
      case 14:
            this.slides = [
              { imageUrl : urlBase+"dia14.1.png", textoDia: ["Los alumnos visualizan la imagen de 'La empresa de María' y al alumno líder el botón 'Girar'",
              "Se menciona a los alumnos que vender su producto a María o a Sam",
              "Los leekels que se paguen al alumno, se suman a los LEEKELS totales del equipo"] },
              { imageUrl : urlBase+"dia14.2.png", textoDia: ["Si quieren vender a Sam, el alumno líder debe presionar el botón 'Girar' para que les aparezca esta pantalla",
              "Cuando el alumno líder presione el botón 'Girar', le va a salir un resultado de la ruleta y en el tablero del equipo podrás ver el resultado",
              "Sus compañeros también podrán ver dicho resultado"] }
            ];
            this.words = this.slides[0].textoDia;
            break;
      case 15:
            this.slides = [
              { imageUrl : urlBase+"dia15.1.png", textoDia: ["Los alumnos visualizan la imagen de 'Un banco' y el botón de un video a la derecha",
              "En este día puedes pedir prestado LEEKELS al banco estos se suman a los LEEKELS totales y aumentar su deuda"] },
              { imageUrl : urlBase+"dia15.2.png", textoDia: ["Si presionan el botón de 'Video', los alumnos pueden ver el video"] }
            ];
            this.words = this.slides[0].textoDia;
            break;
      case 16:
            this.slides = [
              { imageUrl : urlBase+"dia16.png", textoDia: ["El alumno líder debe establecer las cantidades Inversión - Gasto - Ahorro"] }
            ];
            this.words = this.slides[0].textoDia;
            break;
      case 17:
            this.slides = [
              { imageUrl : urlBase+"dia17.png", textoDia: ["Los alumnos visualizan los productos disponibles en la tienda",
              "Hora de vender los productos a cada equipo, resta la cantidad que hay en GASTOS",
              "Si no le alcanza, el alumno líder puede volver a establecer las cantidades IGA"] }
            ];
            this.words = this.slides[0].textoDia;
            break;
      case 18:
            this.slides = [
              { imageUrl : urlBase+"dia18.png", textoDia: ["Los alumnos visualizan la imagen del domingo (una persona descansando)"] }
            ];
            this.words = this.slides[0].textoDia;
            break;
      case 19:
            this.slides = [
              { imageUrl : urlBase+"dia19.png", textoDia: ["Los alumnos visualizan la imagen de 'Proveedores de Luis'",
              "Se le vende materia prima al alumno, resta la cantidad que hay en INVERSIÓN",
              "Si no le alcanza, el alumno líder puede volver a establecer las cantidades IGA"] }
            ];
            this.words = this.slides[0].textoDia;
            break;
      case 20:
            this.slides = [
              { imageUrl : urlBase+"dia20.1.png", textoDia: ["Los alumnos visualizan la imagen de 'Una fábrica' y al alumno líder el botón de 'Elegir tarjeta'",
              "Se menciona a los alumnos que deben fabricar su producto a vender"] },
              { imageUrl : urlBase+"dia20.2.png", textoDia: ["El alumno líder debe seleccionar una tarjeta en esta pantalla con el botón 'Elegir'",
              "El resultado se reflejará a sus compañeros y a ti"] }
            ];
            this.words = this.slides[0].textoDia;
            break;
      case 21:
            this.slides = [
              { imageUrl : urlBase+"dia21.1.png", textoDia: ["Los alumnos visualizan la imagen de 'La empresa de María' y al alumno líder el botón 'Girar'",
              "Se menciona a los alumnos que vender su producto a María o a Sam",
              "Los leekels que se paguen al alumno, se suman a los LEEKELS totales del equipo"] },
              { imageUrl : urlBase+"dia21.2.png", textoDia: ["Si quieren vender a Sam, el alumno líder debe presionar el botón 'Girar' para que les aparezca esta pantalla",
              "Cuando el alumno líder presione el botón 'Girar', le va a salir un resultado de la ruleta y en el tablero del equipo podrás ver el resultado",
              "Sus compañeros también podrán ver dicho resultado"] }
            ];
            this.words = this.slides[0].textoDia;
            break;
      case 22:
            this.slides = [
              { imageUrl : urlBase+"dia22.1.png", textoDia: ["Los alumnos visualizan la imagen de 'Un banco' y el botón de un video a la derecha",
              "En este día puedes pedir prestado LEEKELS al banco estos se suman a los LEEKELS totales y aumentar su deuda"] },
              { imageUrl : urlBase+"dia22.2.png", textoDia: ["Si presionan el botón de 'Video', los alumnos pueden ver el video"] }
            ];
            this.words = this.slides[0].textoDia;
            break;
      case 23:
            this.slides = [
              { imageUrl : urlBase+"dia23.png", textoDia: ["El alumno líder debe establecer las cantidades Inversión - Gasto - Ahorro"] }
            ];
            this.words = this.slides[0].textoDia;
            break;
      case 24:
            this.slides = [
              { imageUrl : urlBase+"dia24.png", textoDia: ["Los alumnos visualizan los productos disponibles en la tienda",
              "Hora de vender los productos a cada equipo, resta la cantidad que hay en GASTOS",
              "Si no le alcanza, el alumno líder puede volver a establecer las cantidades IGA"] }
            ];
            this.words = this.slides[0].textoDia;
            break;
      case 25:
            this.slides = [
              { imageUrl : urlBase+"dia25.png", textoDia: ["Los alumnos visualizan la imagen del domingo (una persona descansando)"] }
            ];
            this.words = this.slides[0].textoDia;
            break;
      case 26:
            this.slides = [
              { imageUrl : urlBase+"dia26.png", textoDia: ["Los alumnos visualizan la imagen de 'Proveedores de Luis'",
              "Se le vende materia prima al alumno, resta la cantidad que hay en INVERSIÓN",
              "Si no le alcanza, el alumno líder puede volver a establecer las cantidades IGA"] }
            ];
            this.words = this.slides[0].textoDia;
            break;
      case 27:
            this.slides = [
              { imageUrl : urlBase+"dia27.1.png", textoDia: ["Los alumnos visualizan la imagen de 'Una fábrica' y al alumno líder el botón de 'Elegir tarjeta'",
              "Se menciona a los alumnos que deben fabricar su producto a vender"] },
              { imageUrl : urlBase+"dia27.2.png", textoDia: ["El alumno líder debe seleccionar una tarjeta en esta pantalla con el botón 'Elegir'",
              "El resultado se reflejará a sus compañeros y a ti"] }
            ];
            this.words = this.slides[0].textoDia;
            break;
      case 28:
            this.slides = [
              { imageUrl : urlBase+"dia28.1.png", textoDia: ["Los alumnos visualizan la imagen de 'La empresa de María' y al alumno líder el botón 'Girar'",
              "Se menciona a los alumnos que vender su producto a María o a Sam",
              "Los leekels que se paguen al alumno, se suman a los LEEKELS totales del equipo"] },
              { imageUrl : urlBase+"dia28.2.png", textoDia: ["Si quieren vender a Sam, el alumno líder debe presionar el botón 'Girar' para que les aparezca esta pantalla",
              "Cuando el alumno líder presione el botón 'Girar', le va a salir un resultado de la ruleta y en el tablero del equipo podrás ver el resultado",
              "Sus compañeros también podrán ver dicho resultado"] }
            ];
            this.words = this.slides[0].textoDia;
            break;
      case 29:
            this.slides = [
              { imageUrl : urlBase+"dia29.png", textoDia: ["Los alumnos visualizan la imagen de 'Un banco' nada más",
              "En este día puedes pedir prestado LEEKELS al banco estos se suman a los LEEKELS totales y aumentar su deuda"] }
            ];
            this.words = this.slides[0].textoDia;
            break;
      case 30:
            this.slides = [
              { imageUrl : urlBase+"dia30.1.png", textoDia: ["Los alumnnos visualizan el siguiente mensaje '¡Felicidades¡ haz llegado al final del simaulador de empresas'"] },
              { imageUrl : urlBase+"dia30.2.png", textoDia: ["Cuando presionen el botón 'Aceptar del mensaje anterior', visualizarán la imagen de 'Conclusiones' (2 personas chocando los punos) y un botón 'Video' a la derecha"] },
              { imageUrl : urlBase+"dia30.3.png", textoDia: ["Si presionan el botón de 'Video', los alumnos pueden ver el video final","¡MUY IMPORTANTE! No olvides presionar el botón de 'Guardar el estado de la partida' para que se guarden los leekels de cada alumno y así puedan continuar con el curso"] }
            ];
            this.words = this.slides[0].textoDia;
            break;
      default:
        break;
    }
    this.maxSlides = this.slides.length;
  }


  words:any[];
  maxSlides:number = 0;
@ViewChild('matCarouselSlide') matCarouselSlide;

  public onChangePage($event){
    this.words = this.slides[$event].textoDia;
    this.matCarouselSlide.value = 0;
  }

  change(){
    this.matCarouselSlide.value = 0;
}

  changeDay(btn:any = undefined) {
    if (!this.calendarDay) return;
    //let codigoAcceso = JSON.parse(localStorage.getItem('codigoAcceso'));
    let codigoAcceso = localStorage.getItem('codigoAcceso');
    let courseIdAcceso = JSON.parse(localStorage.getItem('courseIdAcceso'));
    let copyDay = JSON.parse(JSON.stringify(this.calendarDay));
    if (btn) btn.disabled = true;
    this.services.changeDay({ ...this.calendarDay, codigo: codigoAcceso, courseId: courseIdAcceso }).subscribe(
      (response: any) => {
        if (btn) btn.disabled = false;
        if (response.success) {
          this.calendarDay = (response.data && response.data.length > 0) ? response.data[0] : this.calendarDay;
          this.calendarDay.game_sessions_id = copyDay.game_sessions_id;
          this.checkDay(this.calendarDay?.id);
          this.setInstrucciones(this.calendarDay?.id);
          if (this.calendarDay?.id >= 30 && btn){
            this.services.openDialog('Has llegado al final del simulador, no olvide guardar la sesión para que los usuarios puedan avanzar al siguiente tema.');
            btn.disabled = true;
          }
        }
      },
      (error:HttpErrorResponse) => {
        if (btn) btn.disabled = false;
        console.log(error);
      }
    )
  }

  backDay(btn:any = undefined){
    if (!this.calendarDay) return;
    //let codigoAcceso = JSON.parse(localStorage.getItem('codigoAcceso'));
    let codigoAcceso = localStorage.getItem('codigoAcceso');
    let courseIdAcceso = JSON.parse(localStorage.getItem('courseIdAcceso'));
    let copyDay = JSON.parse(JSON.stringify(this.calendarDay));
    if (btn) btn.disabled = true;
    this.services.changeDay({ ...this.calendarDay, codigo: codigoAcceso, courseId: courseIdAcceso, goBackDay: true }).subscribe(
      (response: any) => {
        if (btn) btn.disabled = false;
        if (response.success) {
          this.calendarDay = (response.data && response.data.length > 0) ? response.data[0] : this.calendarDay;
          this.calendarDay.game_sessions_id = copyDay.game_sessions_id;
          this.checkDay(this.calendarDay?.id);
          this.setInstrucciones(this.calendarDay?.id);
          if (this.calendarDay?.id <= 1 && btn) btn.disabled = true;
        }
      },
      (error:HttpErrorResponse) => {
        if (btn) btn.disabled = false;
        console.log(error);
      }
    );

  }

  public checkDay(dayOfMonth: any) {
    let day = Number(dayOfMonth);
    if (day == 1) {
      this.leekels = 200;
      this.inversion = 0;
      this.gasto = 0;
      this.ahorro = 0;
      this.deuda = 250;
    }

    if (day >= 1 && day <= 4) {
      this.week = 1;
    }
    else if (day >= 5 && day <= 11) {
      this.week = 2;
    }
    else if (day >= 12 && day <= 18) {
      this.week = 3;
    }
    else if (day >= 19 && day <= 25) {
      this.week = 4;
    }
    else if (day >= 26 && day <= 30) {
      this.week = 5;
    }

    for (let index = 1; index < 31; index++) {
      document.getElementById("day" + index).removeAttribute('style');
    }
    document.getElementById("day" + dayOfMonth).setAttribute('style', 'outline: 1px dashed black;');

  }

  createTeams() {
    if (this.users.length > 0) {
      for (let index = 0; index < this.montosXEquipo.length; index++) {
        const element = this.montosXEquipo[index];
        if(Number(element.team)<=0){
          this.montosXEquipo.splice(index,1);
        }
      }
      this.users.forEach(
        (user: any) => {
          let teamId: number = user.team;
          let exists = this.montosXEquipo.find((t) => t.team === teamId);
          if (!exists) {
            let team: any = {
              team: teamId,
              inversion: 0,
              gasto: 0,
              ahorro: 0,
              totalLeekels: 200,
              materiaPrima: 0,
              deuda: 250,
              codigo: localStorage.getItem('codigoAcceso'),
              isUpdateMontos: false
            }
            team.updateAmount = true;
            team.onlyOne = true;
            this.montosXEquipo.push(team);
            this.updateLeekelsByTeam(team, false);
          }else{
            exists.updateAmount = true;
            exists.onlyOne = true;
            this.updateLeekelsByTeam(exists, false);
          }
        }
      );
      this.initTeams();
      localStorage.setItem('arrayMontosXEquipo', JSON.stringify(this.montosXEquipo));
    }
  }

  notesInput: string = "";
  updatedDayEvent() {
    //let codigoAcceso = JSON.parse(localStorage.getItem('codigoAcceso'));
    let codigoAcceso = localStorage.getItem('codigoAcceso').toLocaleLowerCase();
    this.echoEvent.channel('change-calendar-day-' + codigoAcceso)
      .listen('CalendarDaysEvent', (response) => {
        // datos ejecutado 
        if (response.data && response.data.length > 0) {
          this.calendarDay = response.data[0];
          this.checkDay(this.calendarDay?.id);
        }
      }).listen('GameSessionUserEvent', (response) => {
        // datos ejecutado
        let res_ = response.data;
        if (!res_.hasOwnProperty('isUpdateMontos')) {
          this.users = response.data;
          this.createTeams();
        } else if (res_.hasOwnProperty('isUpdateMontos') && codigoAcceso == res_?.codigo) {
          this.setupMontos(res_);
        } else {
          let data = response.data;
          if (data.updateAmount) {
            this.setupMontos(data);
          } else {
            this.filterUserByTeam(response.data);
            this.getNotesInstructor();
          }
        }
      }).listen('ProductSaleEvent', (response) => {
        this.products = response.data.products;
        // datos ejecutado
        if (this.role.isInstructor()) {
        } else {
          this.products.map((p) => {
            if (p.id == response.data.id && this.myInfoUserSession.team == response.data.team) {
              p.isSelected = 1;
            } else {
              p.isSelected = 0;
            }
          });
        }
      }).listen('RouletteEvent', (response) => {
        // datos ejecutado
        this.montosXEquipo.map((montoEquipo) => {
          if (response.data.team == montoEquipo.team && this.week == response.data.week) {
            montoEquipo.roulette = response.data.roulettePrize;
            montoEquipo.week = response.data.week;
            if(this.week == 3 && response.data.roulettePrize){
              montoEquipo.roulette_sam_1 = response.data.roulettePrize;
            }
            if(this.week == 4 && response.data.roulettePrize){
              montoEquipo.roulette_sam_2 = response.data.roulettePrize;
            }
            if(this.week == 5 && response.data.roulettePrize){
              montoEquipo.roulette_sam_3 = response.data.roulettePrize;
            }
          }
        });
        this.getNotesInstructor();
        localStorage.setItem('arrayMontosXEquipo', JSON.stringify(this.montosXEquipo));
      }).error((error) => { })
  }

  getCalendarDay() {
    this.loader.open('Obteniendo informacion del juego..');
    //let codigoAcceso = JSON.parse(localStorage.getItem('codigoAcceso'));
    let codigoAcceso = localStorage.getItem('codigoAcceso');
    let courseIdAcceso = JSON.parse(localStorage.getItem('courseIdAcceso'));
    this.services.getCalendarDay({ codigo: codigoAcceso, courseId: courseIdAcceso }).subscribe(
      (response: any) => {
        this.loader.close();
        if (response.success) {
          this.calendarDay = response.data.day;
          this.products = response.data.products;
          this.colors = response.data.allTeams.filter((team)=>team.team_type_id == 1);
          this.users = response.data.users;
          this.montosXEquipo = response.data.amountTeams;
          this.montosXEquipo.map((amounts)=>{
            amounts.isUpdateMontos = false;
            amounts.onlyOne = true;
            amounts.updateAmount = true;
          });
          localStorage.setItem('arrayMontosXEquipo',JSON.stringify(this.montosXEquipo));
          this.checkDay(this.calendarDay?.id);
          this.setInstrucciones(this.calendarDay?.id);
          this.validateSession();
        }
      },
      (error) => {
        this.loader.close();
        this.validateSession();
      }
    )
  }

  public setupMontos(data) {
    var equipos = localStorage.getItem('arrayMontosXEquipo');
    if (!equipos && this.montosXEquipo.length==0) {
      //Primer equipo registrado
      this.montosXEquipo = new Array();
      this.montosXEquipo.push(data);
      this.getNotesInstructor();
      localStorage.setItem('arrayMontosXEquipo', JSON.stringify(this.montosXEquipo));
    } else {
      // this.montosXEquipo = new Array();
      // this.montosXEquipo = JSON.parse(equipos);
      if (data.onlyOne) {
        this.montosXEquipo.forEach(
          (team) => {
            if (team.team === data.team) {
              team.inversion = data.inversion;
              team.gasto = data.gasto;
              team.ahorro = data.ahorro;
              team.totalLeekels = data.totalLeekels;
              team.deuda = data.deuda;
              team.materiaPrima = data.materiaPrima;
              if(data?.card){
                team.card = data.card;
                if(this.week == 4){
                  team.cards_of_life_1 = data.card.text;
                }
                if(this.week > 4){
                  team.cards_of_life_2 = data.card.text;
                }
              }
            }
          }
        );
      } else {
        let wasAdd: boolean = false;
        let posAdd: number = -1;
        for (let index = 0; index < this.montosXEquipo.length; index++) {
          const eq = this.montosXEquipo[index];
          if (eq.team == data?.team) {
            wasAdd = true;
            //Actualizando montos ya insertados
            this.montosXEquipo[index] = data;
            break;
          }

        }

        if (wasAdd == false) {
          this.montosXEquipo.push(data);
        }
      }
      this.initTeams();
      localStorage.setItem('arrayMontosXEquipo', JSON.stringify(this.montosXEquipo));
    }
  }

  setMontos(data: any) {
    this.montosForm.get('inversion').setValue(data?.inversion);
    this.montosForm.get('gasto').setValue(data?.gasto);
    this.montosForm.get('ahorro').setValue(data?.ahorro);
    this.montosForm.get('team').setValue(data?.team);
    this.inversion = data?.inversion;
    this.gasto = data?.gasto;
    this.ahorro = data?.ahorro;
    this.totalLeekels = data?.totalLeekels;
  }

  myTeam: any = undefined;
  filterUserByTeam(user: Array<any>) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.myInfoUserSession = [...user].find((user) => user.id == userInfo.id);
    if (this.myInfoUserSession) {
      this.users = [...user].filter((user) => user.team == this.myInfoUserSession.team && user.id != this.myInfoUserSession.id);
      this.myTeam = this.colors.find((t) => t.team === this.myInfoUserSession.team);
    } else {
      this.users = [];
    }
  }

  startTimer() {
    this.interval = setInterval(() => {
      this.seconds++;
      this.time = new Date(this.seconds * 1000).toISOString().substr(11, 8);
    }, 1000)
  }

  avanzarDia() {
    document.getElementById("day" + this.contDias).removeAttribute("style");
    this.dia = this.calendario();
    if (this.contDias < 31) {
      document.getElementById("day" + this.contDias).setAttribute("style", "outline: 1px dashed black;");
    }
    else {
      //this.limiteDia = true;
    }
  }

  changeColorUsers() {
    let usersSave = this.users.filter(element => element.team != null);
    if (usersSave.length > 0) {
      this.services.openConfirm(`¿Seguro(a) que desea guardar los cambios?`)
        .then(
          (response) => {
            if (response) {
              //let codigoAcceso = JSON.parse(localStorage.getItem('codigoAcceso'));
              this.updateColorUsersGameSessions();
            }
          }
        )
        .catch(
          (error: HttpErrorResponse) => {
            this.services.openDialog(`Error al: aceptar ${error.message}`)
          }
        );
    }
  }

  updateColorUsersGameSessions(){
    let usersSave = this.users.filter(element => element.team != null);
    let usersToSave = [...usersSave].map((user) => { return { game_sessions_id: user.game_sessions_id, users_id: user.id, team: user.team, leader: user.leader, id: user.gsId } });
    if(usersToSave.length==0){
      return;
    }
    this.loader.open('Actualizando equipos..');
    let codigoAcceso = localStorage.getItem('codigoAcceso');
    this.services.updateColorUsersGameSessions({ codigo: codigoAcceso, users: usersToSave }).subscribe(
      (response: any) => {
        this.loader.close();
        this.services.openDialog(response.message);
      },
      (error: HttpErrorResponse) => {
        this.loader.close();
        this.services.openDialog(`Error al: aceptar ${error.message}`)
      }
    )
  }

  selectedLider(event, userSelected) {
    this.users.map((user) => {
      if (user.id == userSelected.id && user.team == userSelected.team) {
        user.leader = true;
      } else if (user.team == userSelected.team) {
        user.leader = false;
      }
    })
  }

  selectedProduct(product: any) {
    if (Number(this.teamSelected) > 0) {
      this.products.map((p) => {
        if (p.id == product.id) {
          p.isSelected = 1;
        } else {
          p.isSelected = 0;
        }
      });
      //let codigoAcceso = JSON.parse(localStorage.getItem('codigoAcceso'));
      let codigoAcceso = localStorage.getItem('codigoAcceso');
      this.productPrice = product.price;
      this.services.changeProductSelected({ ...product, codigo: codigoAcceso, team: this.teamSelected, products: this.products }).subscribe(
        (result) => {

        },
        (result: HttpErrorResponse) => {
          this.products.map((p) => {
            p.isSelected = 0;
          });
          this.services.openDialog('No se pudo actualizar productos en alumnos');
        },
      )
    } else {
      this.services.openDialog('Seleccione un equipo.');
    }
  }

  buyProduct() {
    let equipo = this.montosXEquipo.find((team: any) => team.team === this.teamSelected);
    if (equipo) {
      let gastosTeam = equipo.gasto;

      if (gastosTeam < this.productPrice) {
        this.services.openDialog("No puedes comprar este producto. No tienes suficientes 'Gastos'");
      }
      else {
        let product = this.products.find((p) => p.isSelected == 1);
        if (product) {
          this.services.openConfirm(`¿Seguro(a) que desea vender el producto '${product?.virtual_product}'?`)
            .then((response)=>{
              if (response) {
                this.loader.open('Comprando producto...');
                let codigoAcceso = localStorage.getItem('codigoAcceso');
                this.services.buyProductSelected({ ...product, codigo: codigoAcceso, game_sessions_id: this.calendarDay.game_sessions_id, team_id: this.teamSelected }).subscribe(
                  (response: any) => {
                    this.loader.close();
                    if(response.success == false){
                      this.services.openDialog('Ocurrió un error al comprar el producto, por favor intente de nuevo.');
                    }else{

                      equipo.gasto = gastosTeam - this.productPrice;
                      equipo.totalLeekels -= this.productPrice;
                      //Actualizar datos del equipo 
                      for (let index = 0; index < this.montosXEquipo.length; index++) {
                        const element = this.montosXEquipo[index];

                        if (element.team == equipo.team) {
                          this.montosXEquipo[index].gasto = equipo.gasto;
                          this.montosXEquipo[index].totalLeekels = equipo.totalLeekels;
                          localStorage.setItem('arrayMontosXEquipo', JSON.stringify(this.montosXEquipo));
                          break;
                        }
                      }
                      // copiamos objeto para activar bandera updateAmount y dejar objeto actual tal como esta
                      let equipo2 = JSON.parse(JSON.stringify(equipo));
                      equipo2.updateAmount = true;
                      this.updateLeekelsByTeam(equipo2);
                    }
                  },
                  (error) => {
                    this.loader.close();
                  },
                )
              }
            })
            .catch((error)=>{});
        }
        else {
          this.services.openDialog('Seleccione un producto.');
        }
      }
    }
    else {
      this.services.openDialog('Seleccione un equipo.');
    }
  }

  public updateLeekelsByTeamAutomatic(team: any, investment: any, spend: any, saving: any, totalLeekels: any, debt: any, rawMaterial: any, showLoader: boolean = true) {
    team.inversion = Number(investment);
    team.gasto = Number(spend);
    team.ahorro = Number(saving);
    team.deuda = Number(debt);
    team.materiaPrima = Number(rawMaterial);
    team.updateAmount = true;
    team.onlyOne = true; //Bandera para saber si actualiza a todos o sólo a un equipo
    team.totalLeekels = Number(totalLeekels);

    if (showLoader)
      this.loader.open(`Actualizando montos al equipo: ${team?.team}`);
    this.services.updateMontos(team).toPromise()
      .then((response: any) => {
        this.loader.close();
        if (showLoader)
          this.getCalendarDay();
      }).catch((error: any) => {
        this.loader.close();
        this.services.openDialog(`Error al actualizar los leekels al equipo ${team?.team}`);
      });
  }

  dayIn(day: number, daysArray: number[]) {
    let dayExists: number = daysArray.find((d) => d === day);
    return (dayExists) ? true : false;
  }

  confirmUpdateLeekelsByTeam(team: any, investment: any, spend: any, saving: any, totalLeekels: any, debt: any, rawMaterial: any) {
    this.services.openConfirm(`¿Seguro(a) que desea actualizar los leekels al equipo ${team?.team}?`)
      .then(
        (response) => {
          if (response) {
            team.inversion = Number(investment);
            team.gasto = Number(spend);
            team.ahorro = Number(saving);
            team.deuda = Number(debt);
            team.materiaPrima = Number(rawMaterial);
            team.updateAmount = true;
            team.isUpdateMontos = true;
            team.onlyOne = true; //Bandera para saber si actualiza a todos o sólo a un equipo
            team.totalLeekels = Number(totalLeekels);
            this.updateLeekelsByTeam(team);
            let montos = JSON.parse(localStorage.getItem('arrayMontosXEquipo') || '[]');
            if (montos.length > 0) {
              this.montosXEquipo.map((monto) => {
                let m = montos.find((m) => m.team == monto.team);
                if (m.materiaPrima && m.deuda) {
                  monto.materiaPrima = m.materiaPrima;
                  monto.deuda = m.deuda;
                }
              })
            }
            localStorage.setItem('arrayMontosXEquipo', JSON.stringify(this.montosXEquipo));
          }
        }
      )
      .catch(
        (error) => {
          this.services.openDialog(`Error al actualizar los leekels del equipo ${team?.team}`);
        }
      )
  }

  public updateLeekelsByTeam(team: any, showLoader: boolean = true) {
    if (showLoader)
      this.loader.open(`Actualizando montos al equipo: ${team?.team}`);
    this.services.updateMontos(team).toPromise()
      .then((response: any) => {
        this.loader.close();
        if (showLoader)
          this.services.openDialog(response.message);
      }).catch((error: any) => {
        this.loader.close();
        this.services.openDialog(`Error al actualizar los leekels al equipo ${team?.team}`);
      });
  }

  initTeams() {
    this.montosXEquipo.forEach(
      (team) => {
        let color: any = this.colors.find((c) => c.team === team.team);
        if (color) {
          team.name = color.name;
        }
      }
    );
    this.getNotesInstructor();
  }

  validateSession(): boolean {
    let equipos = localStorage.getItem('arrayMontosXEquipo');
    let accessCode: string = localStorage.getItem('codigoAcceso');
    let teams: any[] = JSON.parse(equipos);
    let sameSession: boolean = false;
    if (teams) {
      teams.forEach((team: any) => {
        sameSession = (team.codigo == accessCode) ? true : false;
      });
    }
    if (sameSession) {//Si la sesión de juego es la misma que en los equipos, obtenemos los equipos
      this.montosXEquipo = teams;
    } else {//Si es otra sesión, inicializamos los equipos
      this.montosXEquipo = [];
    }
    this.initTeams();
    localStorage.setItem('arrayMontosXEquipo', JSON.stringify(this.montosXEquipo));
    return sameSession;
  }

  saveGameSession() {
    // validamos que todos los usuarios tengan un equipo en caso contrario mostrar alerta
    let usersWithOutTeam = this.montosXEquipo.some((team) => !team.team);
    if(usersWithOutTeam){
      this.services.openDialog('Hay usuarios sin equipos, favor de agregar a un equipo antes de guardar.');
      return;
    }

    let moduleId = localStorage.getItem('moduleId');
    let userToSave = [];
    this.users.map((user) => {
      let team = this.montosXEquipo.find((team) => team.team == user.team);
      if (team) {
        userToSave.push({ ...user, leekels_total: team.totalLeekels, moduleId: moduleId });
      }
    })
    this.services.openConfirm(`¿Seguro(a) que desea guardar los cambios?`)
      .then(
        (response) => {
          if (response) {
            this.loader.open('Guardando sesión..');
            this.services.saveGameSession({ users: userToSave }).subscribe(
              (response: any) => {
                this.loader.close();
                this.services.openDialog(response.message);
              },
              (error: HttpErrorResponse) => {
                this.loader.close();
                this.services.openDialog(`Error al: aceptar ${error.message}`)
              }
            )
          }
        }
      )
      .catch(
        (error: HttpErrorResponse) => {
          this.services.openDialog(`Error al: aceptar ${error.message}`)
        }
      );
  }

  totalLeekelsInput: number = 200;
  public getTotalesLeekels(total:any, inv: any, gasto: any, ahorro: any, index: number = 0) {
    if (this.montosXEquipo != null) {
      // this.montosXEquipo[index].totalLeekels = Number(inv) + Number(gasto) + Number(ahorro);
      this.montosXEquipo[index].totalIsOk = (Number(total || 0) == (Number(inv || 0) + Number(gasto || 0) + Number(ahorro || 0)));
    }
  }

  validateTotalLeekels(totalLeekels:any,inversion:any,gasto:any,ahorro:any){
    return Number(totalLeekels || 0) != Number(inversion || 0) + Number(gasto || 0) + Number(ahorro || 0);
  }

  openCalendarInfoDialog(){
    let data:modalMultimedia = new modalMultimedia('','assets/images/game/01 Cartel Ciclo de Negocios.jpg',1,'');
    this.services.openCalendarInfoDialog(data)
    .then((response) => {

    }).catch((error)=> {
    });
  }

  getUrlImage(url:string){
    if(url){
      return API.URL_BASE_STORAGE+url;
    }
    return '';
  }

  haveUsersInTeam(team:any){
    return this.users.some((user)=>Number(team) == Number(user.team));
  }

  getNotesInstructor(){
    let codigoAcceso = localStorage.getItem('codigoAcceso');
    this.notesInput = `Notas:\nSesión: ${codigoAcceso}`;
    
    this.montosXEquipo.forEach(
      (team) => {
        let totalIsOk = (Number(team.totalLeekels || 0) == (Number(team.inversion || 0) + Number(team.gasto || 0) + Number(team.ahorro || 0)));
        team.totalIsOk = totalIsOk;
        if(team?.name && this.haveUsersInTeam(team.team)){
          this.notesInput +=  `\n-- ${team.name}\n`;
          if(team?.roulette_sam_1){
            this.notesInput +=  `- Semana 3:\n`;
            this.notesInput +=  team.roulette_sam_1 ? `Condicion: ${team.roulette_sam_1}\n` : '';
          }
          if(team?.roulette_sam_2 || team?.cards_of_life_1){
            this.notesInput +=  `- Semana 4:\n`;
            this.notesInput +=  team.cards_of_life_1 ? `Tarjeta de la vida: ${team.cards_of_life_1}\n` : '';
            this.notesInput +=  team.roulette_sam_2 ? `Condicion: ${team.roulette_sam_2}\n` : '';
          }
          if(team?.roulette_sam_3 || team?.cards_of_life_2){
            this.notesInput +=  `- Semana 5:\n`;
            this.notesInput +=  team.cards_of_life_2 ? `Tarjeta de la vida: ${team.cards_of_life_2}\n` : '';
            this.notesInput +=  team.roulette_sam_3 ? `Condicion: ${team.roulette_sam_3}\n` : '';
          }
        }
      }
    );
  }

  getSumIGA(_investment:number,_spend:number,_saving:number){
    return Number(_investment) + Number(_spend) + Number(_saving);
  }

  getColorByTeams(team){
    if(team){
      let color = this.colors.find((c)=>c.team == team);
      return color.color;
    }else{
      return '#fff';
    }
  }
}
