import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DialogEditUserComponent } from 'app/views/dialogs/dialog-edit-user/dialog-edit-user.component';
import { BaseService, IResponse } from 'app/views/services/base.service';
import { Observable } from 'rxjs';
import { RoleService } from 'app/views/services/role.service';
import { DialogCouponComponent } from 'app/views/dialogs/dialog-coupon/dialog-coupon.component';
import { DialogPromotionCodesComponent } from 'app/views/dialogs/dialog-promotion-codes/dialog-promotion-codes.component';

@Component({
  selector: 'app-sales-codes',
  templateUrl: './sales-codes.component.html',
  styleUrls: ['./sales-codes.component.css']
})
export class SalesCodesComponent extends BaseService implements OnInit,AfterViewInit {

  userInfo:any;
  pageSize:number = 10;
  actualPage:number;
  licensees:any = [];
  selectedFilter = 0;

  coupons:any = [];
  displayedColumnsL: string[];
  dataSourceL = new MatTableDataSource<any>();
  @ViewChild('paginatorL') paginatorL: MatPaginator;
  totalRowsL:number = 0;
  paginatorIndexL:number = 0;

  constructor(
    injector:Injector,
    public role: RoleService
  ) { 
    super(injector);
    if(role.isAdmin()){
      this.displayedColumnsL = ["id","name","duration","licensee","timesRedeemed","createdAt","active","actions"];
    }else{
      this.displayedColumnsL = ["id","name","duration","timesRedeemed","createdAt","active","actions"];
    }    
  }

  ngOnInit(): void {     
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.getLicensees();
    this.getCodes();
  }

  ngAfterViewInit() {
    this.validatePages();
  }

  validatePages(){
    if(this.paginatorL != undefined){
      this.paginatorL._intl = this.paginatorService.translatePaginator(this.paginatorL._intl);
      this.dataSourceL.paginator = this.paginatorL;
    }
  }

  getLicensees(){
    this.service.getLicensees().then(
      (data:any) => {
        let resp = data.body;        
        if(!resp.success){
          this.service.openDialog('Error al obtener los datos de los cursos');
          return;
        }
        this.licensees = resp.data;     
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.service.openDialog("Error en el Servicio");
      }
    );
  }

  onFilterChange(){
    this.getCodes();
  }

  onChangePaginatorL(event:any){
    this.getCodes(event.pageIndex,event.pageSize);
  }

  getCodes(page:number=0,pageSize:number=10): void {
    this.loader.open();
    this.coupons = [];
    this.actualPage = page;
    let data = {
      page:page,
      pageSize:pageSize,
      licenseeId: this.role.isAdmin() ? this.selectedFilter : this.userInfo.id
    };
    this.clearTables();

    this.service.getCoupons(data).then(
      (response) => {
        this.loader.close();

        let body:IResponse = response.body;
        if (!body.success) {
          this.service.openDialog(`No se encontraron datos.`);
          return;
        }

        body.data.data.forEach(p => {
          let data = {
            id: p.couponId,
            stripeId: p.stripeData?.id,
            name: p.stripeData?.name,
            duration: p.stripeData?.duration == 'forever' ? 'Permanente' : p.stripeData?.duration == 'once' ? 'Solo una vez' : `${p.stripeData?.duration_in_months} mes(es)`,
            licensee: p.licensee,
            licenseeId: p.licenseeId,
            timesRedeemed: p.stripeData?.times_redeemed,
            active: p.active,
            createdAt: p.createdAt
          };
          this.coupons.push(data);         
        });        

        this.dataSourceL.data = this.coupons;
        this.totalRowsL = body.data.totalRows;
        this.paginatorIndexL = page;
        this.dataSourceL.paginator = this.paginatorL;
      }
    ).catch(
      (error:HttpErrorResponse) => {
        this.loader.close();
        console.log(error);
        //this.service.processHttpResponse(error);
      }
    );
  }

  openDialogCode(){
    this.dialog.open(DialogCouponComponent, {
      data: {
        couponId:null,
        stripeId:null,
        roleId:this.userInfo.roleId,
        actionId: 1
      },
      autoFocus: false,
      disableClose: true,
      width:'720px',
      height:'480px'
    }).afterClosed().subscribe(
      (response) => {
        if(response){
          this.getCodes();
        }
      }
    );
  }

  openDialogCodeDetails(row){
    this.dialog.open(DialogCouponComponent, {
      data: {
        couponId:row.id,
        stripeId:row.stripeId,
        roleId:this.userInfo.roleId,
        actionId: 2
      },
      autoFocus: false,
      disableClose: true,
      width:'720px',
      height:'480px'
    }).afterClosed().subscribe(
      (response) => {
        if(response){
          this.getCodes();
        }
      }
    );
  }

  openDialogPromotionCodes(row){
    this.dialog.open(DialogPromotionCodesComponent, {
      data: {
        couponId:row.id,
        stripeId:row.stripeId
      },
      autoFocus: false,
      disableClose: true,
      width:'720px',
      height:'480px'
    }).afterClosed().subscribe(
      (response) => {
        if(response){
          this.getCodes();
        }
      }
    );
  }

  activate(coupon:any,checkbox): void {
    let active = coupon.active == 1 ? 0 : 1;
    checkbox._checked = (checkbox._checked)?true:false;
    let msg = active == 1 ? 'activar' : 'desactivar';
    let msg2 = active == 1 ? 'activarán' : 'desactivarán';
    this.openConfirm(`¿Seguro(a) que desea ${msg} el cupón '${coupon?.name}'? Se ${msg2} todos los códigos relacionados con este cupón.`).subscribe(
      (response) => {
        if (response) {
          this.loader.open();
          let data = {
            couponId:coupon.id,
            couponStripeId:coupon.stripeId,
            active:active,
            name:coupon.name,
            licensee:coupon.licenseeId
          };
          this.service.activeCoupon(data).then(
            (response) => {
              this.loader.close();
              let body:IResponse = response.body;
              this.service.openDialog(body.message).subscribe(()=>{
                this.getCodes(this.actualPage);
              });
            }
          ).catch(
            (error:HttpErrorResponse) => {
              this.loader.close();
              this.service.processHttpResponse(error);
            }
          );
        }else{
          checkbox._checked = (checkbox._checked)?false:true;
        }
      }
    );
  }


  clearTables(){
    this.dataSourceL.data = [];
    this.totalRowsL = 0;
    this.paginatorIndexL = 0;
    this.dataSourceL.paginator = this.paginatorL;
  }
}
