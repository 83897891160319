import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit,EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { DialogConfirmComponent } from 'app/views/dialogs/dialog-confirm/dialog-confirm.component';
import { AdminService } from 'app/views/services/admin.service';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { DialogCourseComponent } from '../dialog-course/dialog-course.component';


@Component({
  selector: 'app-dialog-topic',
  templateUrl: './dialog-topic.component.html',
  styleUrls: ['./dialog-topic.component.css']
})
export class DialogTopicComponent implements OnInit {
  
  public form: FormGroup;
  title:string;
  arrayObjetFile:any[]=[];
  indice:number;
  URL = 'http://localhost:3000/fileupload/';
  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(public dialogo: MatDialogRef<DialogCourseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private loader:AppLoaderService,
    private service: AdminService,
    private dialog:MatDialog,
  ) { 
  }

  public uploader: FileUploader = new FileUploader({
    url: this.URL,
    disableMultipart : false,
    autoUpload: false,
    method: 'post',
    itemAlias: 'attachment'
    });

  public onFileSelected(event: EventEmitter<File[]>) {
    var formulario = this.form.value;
    
    if(formulario.titulo==""){
      this.fileInput.nativeElement.value = "";
      this.service.openDialog("Falta capturar titulo");
      return false;
    }
    const file: File = event[0];
    if(file != null){
      const fileSizeInKB = Math.round(file.size / 1024);
      if(fileSizeInKB <= (50 * 1024) ){
        let objet = {
          id:this.indice,
          idTemp:-1,
          titulo:formulario.titulo,
          fileName : file.name,
          fileSize : file.size,
          file:file,
        };
        this.arrayObjetFile.push(objet);
        this.indice ++;
        this.fileInput.nativeElement.value = "";
        this.form.controls.titulo.setValue("");
      }else{
        this.service.openDialog("El tamaño del archivo no debe ser mayor a 50 MB");
        this.fileInput.nativeElement.value = "";
        return false;
      }
    }

  }

  public deleteFile(objet) {
    if(this.data.id!=0){
      objet.topicId = this.data.id;
      this.loader.open('Eliminado Documento ...');
      this.service.deleteResourseTopic(objet).subscribe(
        (dataResult:any) => {
          this.arrayObjetFile = dataResult.data as [];
          if(this.arrayObjetFile.length>0){
            this.arrayObjetFile.sort(function (x, y) {
              return x.id - y.id;
            });
            this.indice =this.arrayObjetFile[0].id;
            const index = this.data.Resource.indexOf(objet.id);
            if (index > -1) {
              this.data.Resource.splice(index, 1);
            }
            const arrayTemp:[] = this.data.Resource.filter(item => item.idTemp=-1);           
            this.data.Resource = [dataResult.data,arrayTemp];
          }
          this.loader.close();
          
        },
        (http: HttpErrorResponse) => {
          this.loader.close();
          this.service.openDialog("Error en el Servicio1204");
        }
      );
    }else{
      for( var i = 0; i < this.arrayObjetFile.length; i++){ 
        if ( this.arrayObjetFile[i].id === objet.id) { 
          this.arrayObjetFile.splice(i, 1); 
        }
      }
    }
    
  }

  ngOnInit(): void {
    this.indice =0;
      
    if(this.data.id==0){
      this.title = "Alta Tema"
    }else{
      this.title="Editar Tema";
    }
    this.form = new FormGroup({
      topic: new FormControl('', [
        Validators.required,
        Validators.maxLength(500),
      ]),
      description: new FormControl('', [
        Validators.required
      ]),
      titulo: new FormControl(''),
      IsCheckedActivo: new FormControl('', [
        Validators.required
      ]),
      simulator: new FormControl('', [
        Validators.required
      ])
    });
    this.form.controls.topic.setValue(this.data.topic);
    this.form.controls.titulo.setValue("");
    this.form.controls.description.setValue(this.data.description);
    this.form.controls.IsCheckedActivo.setValue((this.data.active==0?false:true));
    this.form.controls.simulator.setValue((!this.data.simulator?false:true));
    if (this.data.id && this.data.id > 0) {
      this.getResourseTopicAll();
    }
  }

  getResourseTopicAll(){
    this.loader.open();
    const loader = this.loader.getDialog();
    this.service.getResourseTopicAll(this.data.id).subscribe(
      (dataResult) => {
       //console.log("dataResult",dataResult.body.data);
       
        switch(dataResult.status){
          case 200:
           this.arrayObjetFile = dataResult.body.data as [];
            if(this.arrayObjetFile.length>0){
              this.arrayObjetFile.sort(function (x, y) {
                return x.id - y.id;
              });
              this.indice =this.arrayObjetFile[0].id;
            }
            this.loader.close();
            break;
        }
          
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        switch (http.status) {
          case 401:
            this.service.openDialog('Operación no permitida');
            this.service.clearUserInfo();
            this.service.navigateTo('/signin');
            break;
          default:
            this.service.openDialog('Error con el servicio.');
        }
      }
    );
  }

  cerrarDialogo(): void {
    this.dialogo.close(null);
  }

  confirmado(): void {  
    var formulario = this.form.value;
    let dataSave ={
      id:this.data.id,
      moduloId :this.data.moduloId,
      topic : formulario.topic,
      description : formulario.description,
      active : formulario.IsCheckedActivo?1:0,
      files:this.arrayObjetFile,
      simulator:formulario.simulator?1:0
    }
    //
    let message:string =  (this.data.id)?`actualizar el tema`:`crear el tema`; 
    this.service.openConfirm(`¿Seguro(a) que desea ${message}?`)
    .then(
      (response) => {
        if (response) {
          let data = {
            module: {
              moduleId:this.data.moduleId,
              topicId:(!this.data.topicId)?0:1
            }
          }
          this.getSimulatorCountByModule(data,dataSave);
        }
      }
    )
    .catch(
      (error) => {
        this.service.openDialog(`Error al: ${message}`)
      }
    );
  }

  getFiles(): FileLikeObject[] {
    return this.uploader.queue.map((fileItem) => {
      return fileItem.file;
    });
  }

  getSimulatorCountByModule(module:any,dataSave:any){
    this.loader.open();
    const loader = this.loader.getDialog();
    this.service.getSimulatorCountByModule(module).subscribe(
      (response:any) => {
        this.loader.close();
        let content:any = response.content;
        dataSave.updateSimulator = false;
        if (content.simulatorCount > 0 && content.topicId != this.data.id && dataSave.simulator == 1) {
          this.dialog.open(DialogConfirmComponent,{
            data:{
              message:`El tema: "${content.topic}" ya está asignado como modelo de simulación. ¿Seguro(a) que desea ${(this.data?.id == 0)?`crear`:`actualizar`} el tema?`,
              confirm:true
            }
          }).afterClosed().subscribe(
            (response) => {
              if (response) {
                dataSave.simulatorDeletedId = content.topicId;
                dataSave.updateSimulator    = true;
                this.dialogo.close(dataSave);
              }
            }
          )
        } else {
          this.dialogo.close(dataSave);
        }
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        this.service.processHttpResponse(http);
      }
    );
  }

}
