<div fxLayout="row" fxLayoutAlign="space-between center" >
  <p mat-dialog-title class="text-purple text-bold" style="margin: 0px;">{{actionId == 1 ? 'Crear' : 'Actualizar'}} cuenta:</p>
  <button mat-icon-button aria-label="close icon" [mat-dialog-close]="false">
      <mat-icon>close</mat-icon>
  </button>
</div>
<mat-divider style="margin: 10px 0 10px 0;" [inset]="true"></mat-divider>
<mat-dialog-content class="dialog-content">
  <form [formGroup]="form">
    <div fxFlex="100" fxLayout="column" fxLayoutAlign="end center" fxLayoutGap="10px">
      <div [fxFlex]="actionId == fActions.CREATE ? 33 : 50" fxLayoutAlign="center center" fxLayoutGap="10px">
        <div fxFlex="50" fxLayoutAlign="center center">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Nombre:</mat-label>
            <mat-icon matPrefix>person</mat-icon>
            <input (input)="name.markAsTouched()" matInput formControlName="name" required=""/>
            <mat-hint>
              <ng-container *ngFor="let error of errorMessages.name">
                <ng-container *ngIf="baseService.formControlHasError(name,error)">
                  <small class="form-error">{{error.message}}</small>
                </ng-container>
              </ng-container>
            </mat-hint>
          </mat-form-field>
        </div>
        <div fxFlex="50" fxLayoutAlign="center center">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Apellidos:</mat-label>
            <mat-icon matPrefix>person</mat-icon>
            <input (input)="lastname.markAsTouched()" matInput formControlName="lastname" required=""/>
            <mat-hint>
              <ng-container *ngFor="let error of errorMessages.lastname">
                <ng-container *ngIf="baseService.formControlHasError(lastname,error)">
                  <small class="form-error">{{error.message}}</small>
                </ng-container>
              </ng-container>
            </mat-hint>
          </mat-form-field>        
        </div>
      </div>
      <div [fxFlex]="actionId == fActions.CREATE ? 33 : 50" fxLayoutAlign="center center" fxLayoutGap="10px">
        <div fxFlex="50" fxLayoutAlign="center center">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Correo electrónico:</mat-label>
            <mat-icon matPrefix>email</mat-icon>
            <input (input)="email.markAsTouched()" matInput formControlName="email" required=""/>
            <mat-hint>
              <ng-container *ngFor="let error of errorMessages.email">
                <ng-container *ngIf="baseService.formControlHasError(email,error)">
                  <small class="form-error">{{error.message}}</small>
                </ng-container>
              </ng-container>
            </mat-hint>
          </mat-form-field>        
        </div>
        <div *ngIf="actionId == fActions.UPDATE" fxFlex="50" fxLayoutAlign="center center">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Fecha de creación:</mat-label>
            <mat-icon matPrefix>calendar</mat-icon>
            <input (input)="createdAt.markAsTouched()" matInput formControlName="createdAt" required=""/>
          </mat-form-field>        
        </div>
        <div *ngIf="actionId == fActions.CREATE" fxFlex="50" fxLayoutAlign="center center">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Confirmar correo electrónico:</mat-label>
            <mat-icon matPrefix>email</mat-icon>
            <input (input)="confirmEmail.markAsTouched()" matInput formControlName="confirmEmail" required=""/>
            <mat-hint>
              <ng-container *ngFor="let error of errorMessages.confirmEmail">
                <ng-container *ngIf="baseService.formControlHasError(confirmEmail,error)">
                  <small class="form-error">{{error.message}}</small>
                </ng-container>
              </ng-container>
            </mat-hint>
          </mat-form-field>        
        </div>
      </div>
      <div fxFlex="33" fxLayoutAlign="start center" fxLayoutGap="10px">
        <div fxFlex="50" fxLayoutAlign="center center">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Tipo de usuario(a):</mat-label>
            <mat-icon matPrefix>person</mat-icon>
            <mat-select disabled formControlName="roleId" (selectionChange)="onFilterChange({id:$event.value,filterType:filterTypes.roleId})">
              <mat-option *ngFor="let f of filters?.roles" [value]="f.id">{{f.filter}}</mat-option>
            </mat-select>
            <mat-hint>
              <ng-container *ngFor="let error of errorMessages.roleId">
                <ng-container *ngIf="baseService.formControlHasError(roleId,error)">
                  <small class="form-error">{{error.message}}</small>
                </ng-container>
              </ng-container>
            </mat-hint>
          </mat-form-field>
        </div>
        <div *ngIf="actionId == fActions.CREATE"  fxFlex="50" fxLayoutAlign="center center">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Nueva contraseña:</mat-label>
            <mat-icon matPrefix matTooltip="Generar contraseña" class="cursor-pointer" (click)="password.setValue(baseService.generatePassword())">lock_reset</mat-icon>
            <input (input)="password.markAsTouched()" matInput formControlName="password" required=""/>
            <mat-icon matSuffix matTooltip="Copiar contraseña" class="cursor-pointer" (click)="baseService.onClickCopy(password.value,'Contraseña')">attach_file</mat-icon>
            <mat-hint>
              <ng-container *ngFor="let error of errorMessages.password">
                <ng-container *ngIf="baseService.formControlHasError(password,error)">
                  <small class="form-error">{{error.message}}</small>
                </ng-container>
              </ng-container>
            </mat-hint>
          </mat-form-field>        
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-divider style="margin: 10px 0 10px 0;" [inset]="true"></mat-divider>
<mat-dialog-actions align="center">
  <button mat-button [mat-dialog-close]="false" class="btn-purple">
    <mat-label class="m-1">Cancelar</mat-label>
    <mat-icon aria-hidden="false">cancel_circle</mat-icon>
  </button>
  <button mat-button class="btn-green" [disabled]="form.invalid" (click)="saveUser()">
    <mat-label class="m-1">{{actionId == fActions.CREATE ? 'Crear' : 'Actualizar'}}</mat-label>
    <mat-icon aria-hidden="false">check_circle</mat-icon>
  </button>
</mat-dialog-actions>