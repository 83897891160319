<app-header-top-sessions></app-header-top-sessions>
<!-- <app-navigation-bar></app-navigation-bar> -->
<div fxFlex="100" fxLayout="column" fxLayoutAlign="center center" class="wh-100" style="height: 80vh;">
  <div fxFlex="50" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="2%" class="wh-100 centrado">
    <div>
      <h2 class="text-purple text-center">¡Gracias por su compra!<br/>Ya tienes acceso a nuevo contenido.</h2>
    </div>
    <div>
      <span class="text-bold text-purple">Regresa al app o expora los cursos desde la web</span>
    </div>
    <button [routerLink]="[COURSES_AUTH_ROUTE]" mat-flat-button class="btn-purple w-280px">Explorar cursos</button>
  </div>
  <footer fxFlex="30" fxLayout="column" style="left: 0%;
  bottom: 0%;
  position: fixed;
  width: 100%;">
    <app-footer></app-footer>
  </footer>
</div>